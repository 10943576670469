<template>
  <div class="col" v-loading="$store.getters['groups/load']">
    <div class="">
      <div class="card">
        <!-- Start Tabs -->
        <div class="tabs_wrapper shadow-sm">
          <button
            @click="activeExam(1)"
            :class="{ active: isActive == 1 }"
            class="tab_button"
          >
            {{ $t("admin_dashboard_type.pre") }} 1
          </button>
          <button
            @click="getTaskTwoDataDefault()"
            :class="{ active: isActive == 2 }"
            class="tab_button"
          >
            {{ $t("admin_dashboard_type.pre") }} 2
          </button>
        </div>
        <!-- End Tabs -->
        <div class="pagination">
          <button
    :disabled="currentPage === 1"
    @click="changePage(currentPage - 1)"
    class="page-btn"
  >
    {{ $t("Previous") }}
          </button>
          <span>{{ currentPage }}</span>
          <button
            :disabled="currentPage === totalPages"
            @click="changePage(currentPage + 1)"
            class="page-btn"
            >
              {{ $t("Next") }}
          </button>
        </div>

        <!-- Start Nemoi One  -->
        <div v-if="isActive == 1" class="main_for_part_one">
          <div class="wrapper_filter mb-4 mt-4 mx-3">
            <div class="row mb-5">
              <div class="col-3">
                <multiselect
                  :placeholder="$t('admin_dashboard_type.country')"
                  :searchable="true"
                  :show-labels="true"
                  :close-on-select="true"
                  v-model="country"
                  :multiple="false"
                  :options="getReportOneCountries"
                  track-by="id"
                >
              </multiselect>
              </div>
              <!-- @select="getNewTaskOneData" -->
              <div class="col-3">
                <multiselect
                  :placeholder="$t('gender')"
                  :searchable="true"
                  :show-labels="true"
                  :close-on-select="true"
                  v-model="gender"
                  :multiple="false"
                  :options="genders"
                  label="name"
                  track-by="id"
                ></multiselect>
              </div>
              <!-- @select="getNewTaskOneData" -->
              <div class="col-3">
                <multiselect
                  :placeholder="$t('admin_dashboard_type.age')"
                  :searchable="true"
                  :show-labels="true"
                  :close-on-select="true"
                  v-model="age_group"
                  :multiple="false"
                  :options="formattedExamOneAgeGroup"
                  label="title"
                  track-by="id"
                ></multiselect>
              </div>
              <!-- @select="getNewTaskOneData" -->
              <div class="col-3">
                <div
                  class="d-flex align-items-center gap_btn justify-content-center"
                >
                  <button
                    class="reset_button correction"
                    @click="getNewTaskOneData"
                  >
                    {{ $t("Go") }}
                  </button>
                  <button class="reset_button" @click="resetOneTask">
                    {{ $t("Reset") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="pre_one_wrapper">
            <!-- Images -->
            <div class="images_wrapper">
              <div
                class="every_img_wrapper"
                v-for="item in getExamOneReportAnswers"
                :key="item.id"
              >
                <div class="main_img">
                  <img
                    v-for="(img, i) in item.object"
                    :key="i"
                    :style="{ transform: `rotate(${-img.rotation}deg)` }"
                    class="item"
                    :src="img.img_path"
                    alt="draw image"
                    :class="`custom_img_en_class_${i + 1}`"
                    draggable="false"
                    loading="lazy"
                  />
                </div>
                <div class="img_name shadow-sm">
                  {{ item.answer }}
                </div>
              </div>
            </div>
            <!-- Repeat -->
            <div class="answers_repeat">
              <h5 class="title">{{ $t("Answers Repeat") }}</h5>
              <div
                class="repeat_item shadow-sm"
                v-for="(item, i) in getExamOneReportStatus"
                :key="i"
              >
                <span class="name">{{ item.answer }}</span>
                <span class="answer_count">{{ item.answer_count }}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- End Nemoi One  -->
        <!-- Start Nemoi Two  -->
        <div class="pre_two" v-if="isActive == 2">
          <div class="row mb-5">
            <!-- <div class="tasks_filter_dropdown col-3 mb-4">
              <multiselect
                placeholder="Tasks"
                :searchable="true"
                :show-labels="true"
                :close-on-select="true"
                v-model="task_id"
                :multiple="false"
                :options="tasks"
                label="name"
                track-by="id"
              ></multiselect>
            </div> -->
            <!-- Country -->
            <div class="col-3">
              <multiselect
                :placeholder="$t('admin_dashboard_type.country')"
                :searchable="true"
                :show-labels="true"
                :close-on-select="true"
                v-model="country2"
                :multiple="false"
                :options="getReportOneCountries"
                track-by="id"
              ></multiselect>
            </div>
            <!-- Gender -->
            <div class="col-3">
              <multiselect
                :placeholder="$t('gender')"
                :searchable="true"
                :show-labels="true"
                :close-on-select="true"
                v-model="gender2"
                :multiple="false"
                :options="genders"
                label="name"
                track-by="id"
              ></multiselect>
            </div>
            <!-- Age -->
            <div class="col-3">
              <multiselect
                :placeholder="$t('admin_dashboard_type.age')"
                :searchable="true"
                :show-labels="true"
                :close-on-select="true"
                v-model="age_group2"
                :multiple="false"
                :options="formattedExamTwoAgeGroup"
                label="title"
                track-by="id"
              ></multiselect>
            </div>
            <!-- Reset & Go -->
            <div class="col-3">
              <div
                class="d-flex align-items-center gap_btn justify-content-center"
              >
                <button class="reset_button correction" @click="getNewTaskData">
                  {{ $t("Go") }}
                </button>
                <button class="reset_button" @click="getTaskTwoDataDefault">
                  {{ $t("Reset") }}
                </button>
              </div>
            </div>
          </div>
          <div class="pre_one_wrapper">
            <!-- Images -->
            <div class="images_wrapper">
              <div
                class="every_img_wrapper"
                v-for="item in getExamTwoReportAnswers"
                :key="item.id"
              >
                <div class="main_img">
                  <img
                    v-for="(img, i) in item.object"
                    :key="i"
                    :style="{ transform: `rotate(${-img.rotation}deg)` }"
                    class="item"
                    :src="img.img_path"
                    alt="draw image"
                    :class="`custom_img_en_class_${i + 1}`"
                    draggable="false"
                  />
                </div>
                <div class="img_name shadow-sm">
                  {{ item.answer }}
                </div>
              </div>
            </div>
            <!-- Repeat -->
            <div class="answers_repeat">
              <h5 class="title">{{ $t("Answers Repeat") }}</h5>
              <div
                class="repeat_item shadow-sm"
                v-for="(item, i) in getExamTwoReportStatus"
                :key="i"
              >
                <span class="name">{{ item.answer }}</span>
                <span class="answer_count">{{ item.answer_count }}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- End Nemoi Two  -->
      </div>
    </div>


  </div>
</template>

<script>
// import Swal from "sweetalert2";

export default {
  data() {
    return {
      isActive: 1,
      tasks: [
        {
          name: this.$t("Task 1"),
          id: 1,
        },
        {
          name: this.$t("Task 2"),
          id: 2,
        },
        {
          name: this.$t("Task 3"),
          id: 3,
        },
        {
          name: this.$t("Task 4"),
          id: 4,
        },
        {
          name: this.$t("Task 5"),
          id: 5,
        },
        {
          name: this.$t("Task 6"),
          id: 6,
        },
        {
          name: this.$t("Task 7"),
          id: 7,
        },
        {
          name: this.$t("Task 8"),
          id: 8,
        },
        {
          name: this.$t("Task 9"),
          id: 9,
        },
        {
          name: this.$t("Task 10"),
          id: 10,
        },
      ],
      task_id: {
        name: this.$t("Task 1"),
        id: 1,
      },
      genders: [
        {
          id: 1,
          name: this.$t("admin_dashboard_type.male"),
        },
        {
          id: 2,
          name: this.$t("admin_dashboard_type.female"),
        },
      ],
      age_groups: [
        { id: 0, title: this.$t("less_than_6") },
        { id: 1, title: 6 },
        { id: 2, title: 7 },
        { id: 3, title: 8 },
        { id: 4, title: 9 },
        { id: 5, title: 10 },
        { id: 6, title: 11 },
        { id: 7, title: 12 },
        { id: 8, title: this.$t("more_than_12") },
      ],
      // pagination
      currentPage: 1,
      perPage: 10,
      totalImages: 0,
      // Task One
      country: null,
      gender: null,
      age_group: null,
      // Task Two
      country2: null,
      gender2: null,
      age_group2: null,
      task_id_filter: null,
    };
  },
  computed: {
    // Exam One
    getExamOneReportAnswers() {
      return this.$store.getters["groups/getExamOneReportAnswers"];
    },
    getExamOneReportStatus() {
      return this.$store.getters["groups/getExamOneReportStatus"];
    },
    getReportOneCountries() {
      return this.$store.getters["groups/getReportOneCountries"];
    },
    // Exam Two
    getExamTwoReportAnswers() {
      return this.$store.getters["groups/getExamTwoReportAnswers"];
    },
    formattedExamOneAgeGroup(){
      return this.getExamOneReportAgeGroup().map((age,index) => {
        return {
        id: index,
        title: (age < 6 ? this.$t("less_than_6") : (age > 16 ? this.$t("more_than_16") : age) )
        };
      })
    },

    formattedExamTwoAgeGroup(){
      return this.getExamTwoReportAgeGroup().map((age,index) => {
        return {
        id: index,
        title: (age < 6 ? this.$t("less_than_6") : (age > 16 ? this.$t("more_than_16") : age) )
        };
      })
    },
    getExamTwoReportStatus() {
      return this.$store.getters["groups/getExamTwoReportStatus"];
    },
    totalPages() {
    return Math.ceil(this.totalImages / this.perPage);
  },
  },
  mounted() {
    this.refreshData();
  },
  methods: {
    refreshData() {
      // Exam One
      this.resetOneTask();
      // Exam Two
      // this.getTaskTwoDataDefault();
    },
    activeExam(examNumber) {
      this.isActive = examNumber;
    },
    getExamTwoReportAgeGroup() {
      return this.$store.getters["groups/getExamTwoReportAgeGroup"];
    },
    getExamOneReportAgeGroup(){
      return this.$store.getters["groups/getExamOneReportAgeGroup"]
    },
    // ----------- Start
    // Get Tab Two - Task Id - Data & Reset
    getTaskTwoDataDefault() {
      this.isActive = 2;
      this.task_id_filter = null;
      this.$store.dispatch("groups/handlerGetExamTwoReports", {
        task_id: 1,
      });
    },
    // this for tab two and task id two
    getNewTaskData() {
      // let ageGroupValue = null;
      // if (this.age_group2) {
      //   if (this.age_group2.title === this.$t("less_than_6")) {
      //     ageGroupValue = 0;
      //   } else if (this.age_group2.title === this.$t("more_than_16")) {
      //     ageGroupValue = 17;
      //   } else {
      //     ageGroupValue = this.age_group2.title;
      //   }
      // }
      // console.log("zzz",ageGroupValue);
      // let filterObj = {
      //   task_id: this.task_id?.id || null,
      //   country: this.country2 || null,
      //   gender: this.gender2?.id || null,
      //   age_group: ageGroupValue,
      // };
      // Object.keys(filterObj).forEach(
      //   key => {
      //     if(filterObj[key] === null){
      //       delete filterObj[key];
      //     }
      //   }
      // );
      // console.log("filter",filterObj);
      // this.$store.dispatch("groups/handlerGetExamTwoReports", filterObj);
      let ageGroupValue = null;
  if (this.age_group) {
    if (this.age_group.title === this.$t("less_than_6")) {
      ageGroupValue = 0;
    } else if (this.age_group.title === this.$t("more_than_16")) {
      ageGroupValue = 17;
    } else {
      ageGroupValue = this.age_group.title;
    }
  }
  let filterObj = {
    country: this.country || null,
    gender: this.gender?.id || null,
    age_group: ageGroupValue,
    page: this.currentPage, // Add pagination
    per_page: this.perPage, // Add items per page
  };

  Object.keys(filterObj).forEach((key) => {
    if (filterObj[key] === null) {
      delete filterObj[key];
    }
  });

  this.$store
    .dispatch("groups/handlerGetExamTwoReports", filterObj)
    .then((res) => {
      this.totalImages = res.total; // Update total images
    });
    },
    // ------------- End
    // The default call for api to get data
    // resetOneTask() {
    //   this.$store.dispatch("groups/handlerGetExamOneReports");
    //   this.country = null;
    //   this.gender = null;
    //   this.age_group = null;
    // },
    resetOneTask() {
  this.currentPage = 1; // Reset to the first page
  this.$store
    .dispatch("groups/handlerGetExamOneReports", {
      page: this.currentPage,
      per_page: this.perPage,
    })
    .then((res) => {
      this.totalImages = res.total; // Update total images
    });
  this.country = null;
  this.gender = null;
  this.age_group = null;
},

    // For Filter In Tab One
    // getNewTaskOneData() {
    //   let ageGroupValue = null;
    //   if (this.age_group) {
    //     if (this.age_group.title === this.$t("less_than_6")) {
    //       ageGroupValue = 0;
    //     } else if (this.age_group.title === this.$t("more_than_16")) {
    //       ageGroupValue = 17;
    //     } else {
    //       ageGroupValue = this.age_group.title;
    //     }
    //   }
    //   let filterObj = {
    //     country: this.country || null,
    //     gender: this.gender?.id || null,
    //     age_group: ageGroupValue,
    //   };


    //   Object.keys(filterObj).forEach(
    //     key => {
    //       if(filterObj[key] === null){
    //         delete filterObj[key];
    //       }
    //     }
    //   );

    //   this.$store
    //     .dispatch("groups/handlerGetExamOneReports", filterObj)
    //     .then((res) => {
    //       // console.log(res);
    //     });
    // },
    getNewTaskOneData() {
  let ageGroupValue = null;
  if (this.age_group) {
    if (this.age_group.title === this.$t("less_than_6")) {
      ageGroupValue = 0;
    } else if (this.age_group.title === this.$t("more_than_16")) {
      ageGroupValue = 17;
    } else {
      ageGroupValue = this.age_group.title;
    }
  }
  let filterObj = {
    country: this.country || null,
    gender: this.gender?.id || null,
    age_group: ageGroupValue,
    page: this.currentPage, // Add pagination
    per_page: this.perPage, // Add items per page
  };

  Object.keys(filterObj).forEach((key) => {
    if (filterObj[key] === null) {
      delete filterObj[key];
    }
  });

  this.$store
    .dispatch("groups/handlerGetExamOneReports", filterObj)
    .then((res) => {
      this.totalImages = res.total; // Update total images
    });
},
changePage(newPage) {
  this.currentPage = newPage;
  if (this.isActive === 1) {
    this.getNewTaskOneData();
  } else if (this.isActive === 2) {
    this.getNewTaskData();
  }
}


  },
};
</script>

<style lang="scss" scoped>
.main_actions_buttons_wrapper {
  margin: 30px 0;
  .pre_buttons {
    display: flex;
    gap: 20px;
    justify-content: center;
    flex-wrap: wrap;
    .custom_btn {
      background-color: #23ccef;
      border: 1px solid #23ccef;
      color: #fff;
      padding: 8px 20px;
      min-width: 120px;
      border-radius: 8px;
      &.is_disabled {
        cursor: no-drop;
        opacity: 0.5;
      }
      &.examination {
        background-color: #fb404b;
        border-color: #fb404b;
      }
      &.correction {
        background-color: #87cb16;
        border-color: #87cb16;
      }
      &.delete {
        background-color: #ffa534;
        border-color: #ffa534;
      }
    }
  }
}
.gap_btn {
  gap: 10px;
}
// Tabs Wrapper
.tabs_wrapper {
  display: flex;
  gap: 20px;
  padding: 20px;
  margin: 10px;
  border-radius: 5px;

  .tab_button {
    background-color: gray;
    border: 1px solid gray;
    color: #fff;
    padding: 8px 20px;
    min-width: 120px;
    border-radius: 8px;
    transition: 0.3s;
    &.active {
      background-color: #23ccef;
      border-color: #23ccef;
    }
    &:hover {
      background-color: #23ccef;
      border-color: #23ccef;
      opacity: 0.7;
    }
  }
}
// Exams Wrapper
// reset_button
.reset_button {
  background-color: #ffa534;
  border: 1px solid #ffa534;
  color: #fff;
  padding: 8px 20px;
  min-width: 100px;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
  &.correction {
    background-color: #87cb16;
    border-color: #87cb16;
  }
  &:hover {
    // background-color: #23ccef;
    // border-color: #23ccef;
    opacity: 0.7;
  }
}
// Exam One
.main_for_part_one {
  margin: 20px;
}
.pre_one_wrapper {
  margin: 10px 10px 20px;
  border-radius: 5px;
  display: flex;
  gap: 20px;
  // images Style
  .images_wrapper {
    flex-grow: 1;
    background: #fbfbfb;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    padding: 20px 0;
    .every_img_wrapper {
      margin-bottom: 20px;
      .main_img {
        width: 200px;
        height: 200px;
        background: #fff;
        position: relative;
        display: flex;
        flex-wrap: wrap;

        .item {
          display: block;
          width: 40px;
          height: 40px;
        }
      }
      .img_name {
        padding: 8px;
        text-align: center;
        text-transform: capitalize;
        background: #fff;
      }
    }
  }
  // answers_repeat
  .answers_repeat {
    min-width: 250px;
    background: #fbfbfb;
    padding: 20px 8px;
    .title {
      font-size: 18px;
      margin-bottom: 20px;
      color: #555;
    }
    .repeat_item {
      display: flex;
      justify-content: space-between;
      background: #fff;
      margin-top: 10px;
      padding: 10px;
      border-radius: 5px;
      .name {
        color: #777;
        text-transform: capitalize;
      }
      .answer_count {
        background-color: #87cb16;
        color: #fff;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

// Exam Two
.pre_two {
  margin: 10px 10px 20px;
}
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.page-btn {
  margin: 0 10px;
  padding: 5px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}
.page-btn:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

</style>
