<template>
  <div class="col">
    <card>
      <div class="main_container_for_correction">
        <div
          class="every_card shadow-sm"
          v-for="item in getMarkedNeomiData"
          :key="item.id"
        >
          <span class="name">{{ item.name }}</span>
          <div class="pre_or_post_container">
            <button
              @click="handlerPreTest(item)"
              class="test_type"
              :class="{ no_test: item.neomi_pre == 0 }"
            >
              Pre Test
            </button>
            <button
              @click="handlerPostTest(item)"
              class="test_type"
              :disabled="item.neomi_after == 0"
              :class="{ no_test: item.neomi_after == 0 }"
            >
              Post Test
            </button>
          </div>
        </div>
        <!-- Start Empty -->
        <div class="text-center" v-if="usersLength == 0">
          There are no corrected tests
        </div>
      </div>
    </card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      usersLength: 0,
    };
  },
  mounted() {
    this.$store.dispatch("groups/handlerGetMarkedNeomiData").then((res) => {
      this.usersLength = res.users.length;
    });
  },
  computed: {
    getMarkedNeomiData() {
      return this.$store.getters["groups/getMarkedNeomiData"];
    },
  },
  methods: {
    handlerPreTest(item) {
      this.$router.push(
        `/mark-neomi-test/${item.id}/${item.type}/${item.neomi_pre}/5`
      );
    },
    handlerPostTest(item) {
      this.$router.push(
        `/mark-neomi-test/${item.id}/${item.type}/${item.neomi_after}/6`
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.main_container_for_correction {
  .every_card {
    padding: 10px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .name {
      color: #777;
      text-transform: capitalize;
    }
  }
  .pre_or_post_container {
    display: flex;
    gap: 10px;
    .test_type {
      background: #87cb16;
      padding: 8px 20px;
      border-radius: 5px;
      color: #fff;
      border: 1px solid #87cb16;
      &.no_test {
        opacity: 0.5;
        cursor: no-drop;
      }
    }
  }
}
</style>
