<template>
  <div class="container">
    <div class="card main_results_wrapper">
      <div class="row">
        <div
          class="col-md-4 mb-4"
          v-for="(item, index) in ExamsData"
          :key="index"
        >
          <div class="img_wrapper el-card is-always-shadow">
            <img
              v-if="item.img_path != null"
              :src="item.img_path"
              :alt="item.answer"
              class="w-100"
              draggable="false"
            />
            <!-- <img
              v-if="item.img_path == null"
              src="../../assets/images/banner.jpg"
              alt=""
              class="w-100"
            /> -->

            <p class="text-right">
              <el-input v-model="item.answer" style="width: 100%"></el-input>
            </p>
            <div class="printing_three_d">
              <router-link
                class="links"
                target="_blank"
                :to="`/image/${item.id}`"
                >3D Printing</router-link
              >
            </div>
          </div>
        </div>
      </div>
      <div class="mt-5 text-right" v-if="challengeNumber == 2">
        <el-button class="btn btn-primary" @click="handlerExamActionPrevious">
          {{ $t("prev") }}</el-button
        >
      </div>
      <div class="mt-5" v-if="challengeNumber == 1">
        <el-button class="btn btn-primary" @click="handlerExamActionNext">
          {{ $t("next") }}
        </el-button>
      </div>

      <!-- <div class="d-flex justify-content-center">
        <el-button @click="handlerEditStudentExamData" class="btn btn-primary"
          >إعتماد</el-button
        >
      </div> -->
      <!-- Start Form  -->
      <div class="mt-5">
        <div class="container">
          <div class="patterns_wrapper">
            <div class="every_pattern with_minutes el-card is-always-shadow">
              <span class="name"> {{ $t("Remaining time in minutes") }} :</span>
              <span>{{ ExamRemainingTime }} {{ $t("minute") }}</span>
            </div>

            <div class="every_pattern el-card is-always-shadow">
              <span class="name"> {{ $t("originality") }} :</span>
              <span>{{ examScore.total }}</span>
            </div>
            <div class="every_pattern el-card is-always-shadow">
              <span class="name"> {{ $t("fluency") }} :</span>
              <span>{{ examScore.fluency }}</span>
            </div>
            <div class="every_pattern el-card is-always-shadow">
              <span class="name"> {{ $t("flexibility") }} :</span>
              <span>{{ examScore.category_count }}</span>
            </div>
          </div>

          <el-form>
            <div class="row">
              <el-form-item
                v-if="challengeNumber == 1"
                :label="$t('details')"
                style="width: 100%; text-align: initial"
                class="mb-5 col-md-6"
              >
                <el-input
                  v-model="detailsValue"
                  :placeholder="$t('details')"
                  style="width: 100%"
                >
                </el-input>
              </el-form-item>
              <el-form-item
                v-if="challengeNumber == 2"
                :label="$t('details')"
                style="width: 100%; text-align: initial"
                class="mb-5 col-md-6"
              >
                <el-input
                  v-model="detailsValue"
                  :placeholder="$t('details')"
                  style="width: 100%"
                >
                </el-input>
              </el-form-item>
            </div>
          </el-form>
          <div class="d-flex justify-content-center">
            <el-button
              class="btn btn-primary"
              @click="handlerEditStudentExamData"
              >{{ $t("confirm") }}</el-button
            >
          </div>
        </div>
      </div>
      <!-- End Form  -->
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
export default {
  data() {
    return {
      challengeNumber: 1,
      detailsValue: null,
      challengeAtFirstTime: false,
    };
  },
  computed: {
    ExamsData() {
      return this.$store.getters["groups/neomiexamResult"];
    },
    ExamRemainingTime() {
      return this.$store.getters["groups/remainingTime"];
    },
    examScore() {
      return this.$store.getters["groups/getExamScore"];
    },
    examDetailsInput() {
      return this.$store.getters["groups/getExamDetailsInput"];
    },
  },
  //
  mounted() {
    //   .then(() => {
    //     this.challengeNumber = 1;
    //   });
    this.handlerExamActionPrevious();
    this.getExamResultScore();
  },
  methods: {
    handlerExamActionPrevious() {
      if (this.challengeAtFirstTime == false) {
        this.ExamsData.forEach((element) => {
          element.img_path =
            "https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif?20151024034921";
        });
        this.challengeAtFirstTime == true;
      }
      this.$store
        .dispatch("groups/getNeomiExamResult", {
          student_id: this.$route.params.id,
          exam_type: this.$route.params.exam_type,
          challenge_no: this.$route.params.challenge_no,
        })
        .then(() => {
          this.challengeNumber = 1;
          this.$store
            .dispatch("groups/getExamDetailsNeomi", {
              student_id: this.$route.params.id,
              exam_type: this.$route.params.exam_type,
              challenge_no: this.challengeNumber,
            })
            .then((res) => {
              this.detailsValue = res.user_details;
            });
        });
    },
    handlerExamActionNext() {
      if (this.challengeAtFirstTime == false) {
        this.ExamsData.forEach((element) => {
          element.img_path =
            "https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif?20151024034921";
        });
        this.challengeAtFirstTime == true;
      }
      this.$store
        .dispatch("groups/getNeomiExamResult", {
          student_id: this.$route.params.id,
          exam_type: this.$route.params.exam_type,
          challenge_no: 2,
        })
        .then(() => {
          this.challengeNumber = 2;
          this.$store
            .dispatch("groups/getExamDetailsNeomi", {
              student_id: this.$route.params.id,
              exam_type: this.$route.params.exam_type,
              challenge_no: this.challengeNumber,
            })
            .then((res) => {
              this.detailsValue = res.user_details;
            });
        });
    },
    // getExamResultScore
    getExamResultScore() {
      this.$store.dispatch("groups/getExamResultScore", {
        student_id: this.$route.params.id,
        exam_type: this.$route.params.exam_type,
      });
    },

    handlerEditStudentExamData() {
      let finalResultAfterEdit = [];
      this.ExamsData.forEach((element) => {
        finalResultAfterEdit.push({
          id: element.id,
          answer: element.answer,
        });
      });

      let checkChallengeNumber;
      if (this.challengeNumber == 1) {
        checkChallengeNumber = 1;
      } else {
        checkChallengeNumber = 2;
      }
      this.$store
        .dispatch("groups/updateExamResultScore", {
          answers: finalResultAfterEdit,
          details: this.detailsValue,
          student_id: this.$route.params.id,
          exam_type: this.$route.params.exam_type,
          challenge_no: checkChallengeNumber,
        })
        .then(() => {
          Swal.fire({
            text: `${this.$t("success_deleted")}`,
            icon: "success",
            confirmButtonClass: "btn btn-success btn-fill",
            buttonsStyling: false,
          });
          if (this.challengeNumber == 1) {
            this.handlerExamActionPrevious();
            this.detailsValue = null;
          } else {
            this.handlerExamActionNext();
          }
          this.getExamResultScore();
          this.detailsValue = null;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.patterns_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  .every_pattern {
    min-width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    gap: 8px;
    margin-bottom: 20px;
    &.with_minutes {
      min-width: 100%;
      margin-bottom: 30px;
    }
  }
}
.main_results_wrapper {
  padding: 1rem 0.5rem;
  background: #fff;
  .img_wrapper {
    // border: 1px solid #ccc;
    img {
      min-height: 300px;
      display: block;
    }
    p {
      color: #777;
      margin: 0;
      padding: 01rem 0.5rem 1rem 0.5rem;
    }
  }
}
.printing_three_d {
  padding: 10px;
  .links {
    color: #23ccef;
  }
}
</style>
<style lang="scss">
.main_results_wrapper {
  .el-form-item__label {
    width: 100%;
    text-align: initial;
  }
}
</style>
