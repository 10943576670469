<template>
  <div class="col">
    <card>
      <div class="main_container_for_correction" v-if="usersLength > 0">
        <div class="like_table">
          <span class="d-block is_name">Name</span>
          <div class="wrap_test">
            <span class="is_test_name for_pre">Pre 1</span>
            <span class="is_test_name">Pre 2</span>
            <span class="is_test_name">Post 1</span>
            <span class="is_test_name">Post 2</span>
          </div>
        </div>
        <div
          class="every_card shadow-sm"
          v-for="item in preNeomiData"
          :key="item.id"
        >
          <span class="name">{{ item.name }}</span>
          <div class="correct_wrapper_buttons">
            <!-- Pre Test 1 -->
            <div class="parts">
              <el-button
                class="correct_button"
                :class="{ marked: item.pre_marked == 1 }"
                :disabled="item.pre_marked == 1"
                @click="correctPreExam(item)"
              >
                {{ item.pre_marked == 1 ? "Corrected" : "Correct" }}
              </el-button>
            </div>
            <!-- Pre Test 2 -->
            <div class="parts">
              <el-button
                class="correct_button"
                :class="{ marked: item.pre_two_marked == 1 }"
                :disabled="item.pre_two_marked == 1"
                @click="correctPreExam(item)"
              >
                {{ item.pre_two_marked == 1 ? "Corrected" : "Correct" }}
              </el-button>
            </div>
            <!-- Post Test 1 -->
            <div class="parts">
              <el-button
                class="correct_button"
                :class="{ marked: item.after_marked == 1 }"
                :disabled="item.after_marked == 1"
                @click="correctPreExam(item)"
              >
                {{ item.after_marked == 1 ? "Corrected" : "Correct" }}
              </el-button>
            </div>
            <!-- Post Test 2 -->
            <div class="parts">
              <el-button
                class="correct_button"
                :class="{ marked: item.after_two_marked == 1 }"
                :disabled="item.after_two_marked == 1"
                @click="correctPostExam(item)"
              >
                {{ item.after_two_marked == 1 ? "Corrected" : "Correct" }}
              </el-button>
            </div>
          </div>
        </div>
      </div>
      <!-- Start Empty -->
      <div class="text-center" v-if="usersLength == 0">No students to mark</div>
    </card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      usersLength: 0,
    };
  },
  mounted() {
    this.$store.dispatch("groups/handlerGetPreNeomiData").then((res) => {
      this.usersLength = res.users.length;
    });
  },
  computed: {
    preNeomiData() {
      return this.$store.getters["groups/getNeomiData"];
    },
  },
  methods: {
    correctPreExam(item) {
      this.$router.push(
        `/mark-neomi-test/${item.id}/${item.type}/${item.neomi_after}/5`
      );
    },
    correctPostExam(item) {
      this.$router.push(
        `/mark-neomi-test/${item.id}/${item.type}/${item.neomi_pre}/6`
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.main_container_for_correction {
  .like_table {
    display: flex;
    margin-bottom: 10px;
    padding: 10px;
    border-bottom: 1px solid #ccc;
    .is_name {
      flex-grow: 1;
      padding: 0 20px;
    }
    .wrap_test {
      min-width: 400px;
      display: flex;
      .is_test_name {
        width: 200px;
        display: flex;
        justify-content: center;
        &.for_pre {
          // background: #eee;
        }
      }
    }
  }
  .every_card {
    padding: 10px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .name {
      color: #777;
      text-transform: capitalize;
      flex-grow: 1;
    }
  }
  .correct_wrapper_buttons {
    min-width: 400px;
    display: flex;

    .parts {
      width: 200px;
      display: flex;
      justify-content: center;

      .correct_button {
        background-color: #87cb16;
        color: #fff;
        border: 1px solid #87cb16;
        min-width: 100px;
        &.marked {
          opacity: 0.5;
        }
      }
    }
  }
}
</style>
