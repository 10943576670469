<template>
  <div>
    <section class="photos_gallery_section">
      <div class="container-fluid">
        <!-- <div class="all_images_wrapper">
          <div
            v-for="img in galleryPhotos"
            :key="img.id"
            :class="{ 'd-none': img.path == 'not found' }"
            class="main_loop_on_img"
          >
            <div class="every_img">
              <img class="img" :src="img.path" alt="" />
            </div>

            <div class="name_and_date">
              <span class="d-block">{{ img.student_name }}</span>
              <span class="d-block">{{ toLocalDatetime(img.created_at) }}</span>
            </div>
          </div>
        </div> -->
        <div class="row mt-5">
          <div
            v-for="img in galleryPhotos"
            :key="img.id"
            :class="{ 'd-none': img.path == 'not found' }"
            class="main_loop_on_img col-md-4 mb-3"
          >
            <div
              class="el-card is-always-shadow p-2"
              @click="getImageSrc(img.path)"
            >
              <div class="every_img">
                <img
                  loading="lazy"
                  class="img d-block w-100"
                  :src="img.path"
                  alt=""
                  draggable="false"
                />
              </div>

              <div class="name_and_date">
                <span class="d-block">{{ img.student_name }}</span>
                <span class="d-block">{{
                  toLocalDatetime(img.created_at)
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Show Preview Image Dialog -->
    <el-dialog
      :visible.sync="showImagePreview"
      transition="dialog-bottom-transition"
      width="80%"
      top="4vh"
      class="text-right"
    >
      <div class="preview_image">
        <img
          draggable="false"
          class="img_for_preview"
          v-if="imgSrc"
          :src="imgSrc"
          alt="preview"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgSrc: null,
      showImagePreview: false,
    };
  },
  mounted() {
    this.$store.dispatch("courses/getMobileGallery");
  },
  computed: {
    galleryPhotos() {
      return this.$store.getters["courses/galleryPhotos"];
    },
  },
  methods: {
    getImageSrc(src) {
      console.log(src);
      this.imgSrc = src;
      this.showImagePreview = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.photos_gallery_section {
  padding: 2rem 0;
  .every_img {
    cursor: pointer;
    min-height: 200px;
    max-height: 200px;
  }

  .img {
    min-height: 200px;
    max-height: 200px;
    border-radius: 4px;
  }
}

.preview_image {
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .img_for_preview {
    display: block;
    max-width: 100%;
  }
}
.all_images_wrapper {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  justify-content: center;
  // .main_loop_on_img{
  //   width: 32%;
  // }
  .every_img {
    max-width: 400px;
    // height: 350px;
    min-height: 200px;
    max-height: 200px;
  }
  .img {
    display: block;
    max-width: 100%;
    min-height: 200px;
    max-height: 200px;
    // height: 100%;
  }
}
.navbar {
  max-height: 100px;
}
.navbar-brand {
  padding: 0px;
  margin: 0px;
}
a {
  color: #ecb0d5;
}
.login-button {
  background: #f1cadd;
  border: none;
}
#footer {
  background-color: #212529;
  color: #f7f7f7;
}
.list li {
  display: flex;
}

ul {
  list-style: none;
}

a {
  color: #ecb0d5;
}
.like {
  font-size: 40px;
}

.name_and_date {
  // text-align: center;
  padding: 10px 0;
  color: #777;
  display: flex;
  justify-content: space-between;
}
</style>
