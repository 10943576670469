<template>
  <div></div>
</template>

<script>
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { STLExporter } from "three/examples/jsm/exporters/STLExporter";
import { GUI } from "three/examples/jsm/libs/lil-gui.module.min.js";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader.js";
import * as BufferGeometryUtils from "three/examples/jsm/utils/BufferGeometryUtils.js";

let scene, camera, renderer, exporter, mesh, mergedMesh, link;
const shapesFiles = new Map([
  [0, "000.stl"],
  [1, "001.stl"],
  [2, "002.stl"],
  [3, "003.stl"],
  [4, "004.stl"],
  [6, "006.stl"],
  [7, "007.stl"],
  [8, "008.stl"],
  [9, "009.stl"],
  [10, "010.stl"],
  [11, "011.stl"],
  [182, "182.stl"],
  [183, "183.stl"],
  [184, "184.stl"],
  [185, "185.stl"],
  [186, "186.stl"],
  [187, "187.stl"],
  [188, "188.stl"],
  [189, "189.stl"],
  [190, "190.stl"],
  [194, "194.stl"],
  [195, "195.stl"],
  [196, "196.stl"],
  [197, "197.stl"],
  [198, "198.stl"],
  [199, "199.stl"],
  [200, "200.stl"],
  [201, "201.stl"],
  [202, "202.stl"],
  [244, "244.stl"],
  [245, "245.stl"],
  [246, "246.stl"],
  [247, "247.stl"],
  [248, "248.stl"],
  [249, "249.stl"],
]);
// This will store the loaded objects
var shapeObjectsList = [];
// This will store the key and index
var shapeObjectsMap = new Map();
var spawnedObjects = [];
var spawnedScale = 1.0;

// This flag to check if the type exists
var isThereAType = 1;
//
export default {
  data() {
    return {
      // testArray: [
      //   51, 51, 51, 51, 51, 51, 0, 3, 0, 51, 51, 2, 51, 2, 51, 51, 4, 2, 4, 51,
      //   51, 4, 51, 4, 51,
      // ],
      // rotationArray: [
      //   90.0, 90.0, 180.0, 90.0, 90.0, 270.0, 90.0, 90.0, 0.0, 270.0, 90.0, 0.0,
      //   180.0, 270.0, 270.0, 0.0, 180.0, 0.0, 180.0, 0.0, 180.0, 0.0, 90.0, 0.0,
      //   90.0,
      // ],
      testArray: [],
      rotationArray: [],
      shapes: [],
      rotations: [],
      type: "",
    };
  },
  mounted() {
    this.$store
      .dispatch("groups/handlerThreeData", {
        answer_id: this.$route.params.img_id,
      })
      .then((res) => {
        let jsonObject = res.answer_json;
        try {
          jsonObject.forEach((element) => {
            let testData = element.object.data;
            this.type = element.object.type;
            testData.forEach((element) => {
              element.ids.forEach((element) => {
                this.testArray.push(element.id);
                this.rotationArray.push(element.rotation);
              });

              this.shapes.push(this.testArray);
              this.rotations.push(this.rotationArray);

              this.testArray = [];
              this.rotationArray = [];
            });
          });
          isThereAType = 1;
        } catch (err) {
          isThereAType = 0;
        }

        if (!isThereAType) {
          this.type = "Undef";
          jsonObject.forEach((element) => {
            let testData = element.object.ids;
            testData.forEach((element) => {
              this.testArray.push(element.id);
              this.rotationArray.push(element.rotation);
              console.log(element.rotation);
            });

            this.shapes.push(this.testArray);
            this.rotations.push(this.rotationArray);

            this.testArray = [];
            this.rotationArray = [];
          });
        }

        console.log("type:", this.type);
        console.log("shapes", this.shapes);
        console.log("rotation", this.rotations);
      })
      .finally(() => {
        this.init();
        this.loadShapes();
        this.animate();
      });
  },
  methods: {
    init() {
      // Initializing the scene/camera/lights/ground/controls

      camera = new THREE.PerspectiveCamera(
        65,
        window.innerWidth / window.innerHeight,
        5,
        1000
      );
      camera.position.set(0, 180, 380);

      scene = new THREE.Scene();
      scene.background = new THREE.Color(0xa0a0a0);
      // scene.fog = new THREE.Fog( 0xa0a0a0, 4, 20 );

      exporter = new STLExporter();

      // lights

      const hemiLight = new THREE.HemisphereLight(0xffffff, 0x444444, 3);
      hemiLight.position.set(0, 20, 0);
      scene.add(hemiLight);

      const directionalLight = new THREE.DirectionalLight(0xffffff, 3);
      directionalLight.position.set(0, 20, 10);
      directionalLight.castShadow = true;
      directionalLight.shadow.camera.top = 2;
      directionalLight.shadow.camera.bottom = -2;
      directionalLight.shadow.camera.left = -2;
      directionalLight.shadow.camera.right = 2;
      scene.add(directionalLight);

      // ground

      const ground = new THREE.Mesh(
        new THREE.PlaneGeometry(500, 500),
        new THREE.MeshPhongMaterial({ color: 0xbbbbbb, depthWrite: false })
      );
      ground.rotation.x = -Math.PI / 2;
      ground.receiveShadow = true;
      scene.add(ground);

      const grid = new THREE.GridHelper(500, 50, 0x000000, 0x000000);
      grid.material.opacity = 0.2;
      grid.material.transparent = true;
      scene.add(grid);

      //

      renderer = new THREE.WebGLRenderer({ antialias: true });
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(window.innerWidth, window.innerHeight);
      renderer.shadowMap.enabled = true;
      document.body.appendChild(renderer.domElement);

      //

      const controls = new OrbitControls(camera, renderer.domElement);
      controls.target.set(0, 0.5, 0);
      controls.update();

      //

      window.addEventListener("resize", this.onWindowResize);

      let params = {
        exportASCII: this.exportASCII,
        exportBinary: this.exportBinary,
        modifyScale: 1.0,
        removeObjects: this.removeObjects,
        spawnObjects: this.spawnObjects,
      };

      const gui = new GUI({ width: 310 });

      gui.add(params, "exportASCII").name("Export STL (ASCII)");
      gui.add(params, "exportBinary").name("Export STL (Binary)");
      gui
        .add(params, "modifyScale", 0.01, 3, 0.01)
        .listen()
        .onChange((weight) => this.changeScale(weight))
        .name("Modify scale");
      gui.add(params, "spawnObjects").name("Spawn Objects");
      gui.add(params, "removeObjects").name("Remove Objects");
      gui.open();

      link = document.createElement("a");
      link.style.display = "none";
      document.body.appendChild(link);
    },

    loadShapes() {
      // Load each shape into shapeObjects
      const stlLoader = new STLLoader();
      var index = 0;
      shapesFiles.forEach((value, key) => {
        stlLoader.load("/models-3d/" + value, (stlScene) => {
          shapeObjectsMap.set(key, index);
          shapeObjectsList.push(stlScene);
          // After loading each shape, spawn them
          if (index == shapesFiles.size - 1) {
            this.spawnObjects();
          }
          index += 1;
        });
      });
    },

    spawnObjects() {
      const material = new THREE.MeshPhongMaterial({ color: 0x777777 });
      this.shapes.forEach((row, row_index) => {
        row.forEach((element, index) => {
          // Original shapes
          if (shapeObjectsMap.has(element)) {
            var tempObject =
              shapeObjectsList[shapeObjectsMap.get(element)].clone();

            var factor = 0.1;
            tempObject.scale(
              spawnedScale * factor + 0.001,
              spawnedScale * factor + 0.001,
              spawnedScale * factor + 0.001
            );

            tempObject.rotateZ(
              (this.rotations[row_index][index] / 180) * (22 / 7)
            );

            // Place each shape at its location
            if (this.type == "advanced_2" || this.type == "advanced_4") {
              tempObject.translate(
                100 * (index % 5) + row_index * 500,
                -100 * parseInt(index / 5),
                0
              );
            } else if (this.type == "advanced_3") {
              if (row_index < 2) {
                // row_index % 2 to get 0 and 1 at the first two shapes
                tempObject.translate(
                  100 * (index % 5) + (row_index % 2) * 500,
                  -100 * parseInt(index / 5),
                  0
                );
              } else {
                // ((row_index - 2) % 2) to get 0 and 1 at the second two shapes
                tempObject.translate(0, -500, 0);
                tempObject.translate(
                  100 * (index % 5) + ((row_index - 2) % 2) * 500,
                  -100 * parseInt(index / 5),
                  0
                );
              }
            } else {
              tempObject.translate(
                100 * (index % 5),
                -100 * parseInt(index / 5),
                row_index * 100
              );
            }

            spawnedObjects.push(tempObject);
          }
        });
      });

      var merged = BufferGeometryUtils.mergeGeometries(spawnedObjects);

      // Here we handle each mode and save it to mergedMesh
      if (this.type == "xMode") {
        // Handle the cloning in xMode
        // Here we have another BufferGeometry called mergedX which is rotated 90 (11/7 in radian)
        // and then both are merged to form the X shape
        merged.translate(-200, 0, -200);

        var mergedX = BufferGeometryUtils.mergeGeometries(spawnedObjects);
        mergedX.translate(0, 0, 0);
        mergedX.rotateY(11 / 7);

        mergedMesh = new THREE.Mesh(
          BufferGeometryUtils.mergeGeometries([mergedX, merged]),
          material
        );

        mergedMesh.position.set(0, 135, 60);
      } else if (this.type == "cubeMode") {
        // Handle the cloning in cubeMode
        // Here we have another four BufferGeometrys called merged1..4 which is rotated 90 (11/7 in radian)
        // each time and then they are merged to form the cube shape
        merged.translate(-200, 0, -200);

        var merged2 = BufferGeometryUtils.mergeGeometries(spawnedObjects);
        merged2.translate(-200, 0, -200);
        merged2.rotateY(11 / 7);

        var merged3 = BufferGeometryUtils.mergeGeometries(spawnedObjects);
        merged3.translate(-200, 0, -200);
        merged3.rotateY(22 / 7);

        var merged4 = BufferGeometryUtils.mergeGeometries(spawnedObjects);
        merged4.translate(-200, 0, -200);
        merged4.rotateY(33 / 7);

        mergedMesh = new THREE.Mesh(
          BufferGeometryUtils.mergeGeometries([
            merged,
            merged2,
            merged3,
            merged4,
          ]),
          material
        );

        mergedMesh.position.set(0, 135, 0);
      } else if (this.type == "CreativeHorizontalMode") {
        // Handle the CreativeHorizontalMode
        // Here we rotate the whole shape to match the made one

        mergedMesh = new THREE.Mesh(merged, material);
        mergedMesh.rotation.set(-11 / 7, 0, 11 / 7);
        mergedMesh.position.set(-60, 15, 60);
      } else if (this.type.includes("verticalMode")) {
        // Handle the verticalMode
        // Here we count the number of layers
        // Add them to slices array then merge them into
        // one BufferGeometry

        var count = parseInt(this.type.slice(-1));
        var slices = [];
        for (let index = 0; index < count; index++) {
          merged = new BufferGeometryUtils.mergeGeometries(spawnedObjects);
          merged.translate(0, 0, -60 * (index + 1));
          slices.push(merged);
        }
        mergedMesh = new THREE.Mesh(
          BufferGeometryUtils.mergeGeometries(slices),
          material
        );
        mergedMesh.position.set(-60, 135, 10 * count);
      } else if (this.type.includes("horizontalMode")) {
        // Handle the horizontalMode
        // Here we count the number of layers
        // Add them to slices array then merge them into
        // one BufferGeometry

        var count = parseInt(this.type.slice(-1));
        var slices = [];
        for (let index = 0; index < count; index++) {
          merged = new BufferGeometryUtils.mergeGeometries(spawnedObjects);
          merged.translate(0, 0, 60 * (index + 1));
          slices.push(merged);
        }
        mergedMesh = new THREE.Mesh(
          BufferGeometryUtils.mergeGeometries(slices),
          material
        );
        mergedMesh.position.set(-60, 0, -60);
        mergedMesh.rotation.set(-11 / 7, 0, 0);
      } else if (this.type == "advanced_2") {
        mergedMesh = new THREE.Mesh(merged, material);
        mergedMesh.position.set(-120, 135, 0);
      } else if (this.type == "advanced_3") {
        mergedMesh = new THREE.Mesh(merged, material);
        mergedMesh.position.set(-120, 285, 0);
      } else if (this.type == "advanced_4") {
        mergedMesh = new THREE.Mesh(merged, material);
        mergedMesh.position.set(-585, 135, 0);
      } else {
        // Handle the default/creativeVerticalMode

        mergedMesh = new THREE.Mesh(merged, material);
        mergedMesh.position.set(-60, 135, 0);
      }

      mergedMesh.scale.set(0.3, 0.3, 0.3);

      scene.add(mergedMesh);

      // This is the merged shapes that can be exported to STL
      mesh = mergedMesh;
    },

    removeObjects() {
      scene.remove(mergedMesh);
      spawnedObjects = [];
    },

    changeScale(weight) {
      this.removeObjects();
      spawnedScale = weight;
      this.spawnObjects();
    },

    onWindowResize() {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();

      renderer.setSize(window.innerWidth, window.innerHeight);
    },

    animate() {
      requestAnimationFrame(this.animate);
      renderer.render(scene, camera);
    },

    exportASCII() {
      const result = exporter.parse(mesh);
      this.saveString(result, "NEOMI.stl");
    },

    exportBinary() {
      const result = exporter.parse(mesh, { binary: true });
      this.saveArrayBuffer(result, "NEOMI.stl");
    },

    save(blob, filename) {
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    },

    saveString(text, filename) {
      this.save(new Blob([text], { type: "text/plain" }), filename);
    },

    saveArrayBuffer(buffer, filename) {
      this.save(
        new Blob([buffer], { type: "application/octet-stream" }),
        filename
      );
    },
  },
  computed: {
    getData() {
      return this.$store.getters["groups/userDataForPrint"];
    },
  },
};
</script>

<style lang="scss" scoped></style>
