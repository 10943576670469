import School from "../../api/schools";

const school = new School();
const FileDownload = require("js-file-download");
const schoolModule = {
  namespaced: true,
  state: {
    load: false,
    groupStudents: [],
    seatsList: [],
    seatsCount: null,
    regionAdmins: [],
    regionAdminsCount: null,
    // schoolAdminReport
    schoolAdminReport: [],
    schoolAdminReportCount: null,
    schoolAdminReportLevelsChart: [],
    schoolAdminReportGendersChart: [],
    regionAdminSchools:[],
    regionAdminSchoolsCount:[],
    // regionAdminReport
    regionAdminReport: [],
    regionAdminReportCount: null,
    regionAdminReportLevelsChart: [],
    regionAdminReportGendersChart: [],
    regionAdminReportCardsData:{},
    classDetails:{},
    schoolsAdminList:[],
    schoolsAdminListCount:[],
    // students and teachers
    schoolStudents: [],
    schoolStudentsCount: null,
    schoolStudentsDropdown: [],
    schoolTeachers: [],
    schoolTeachersCount: null,
    schoolTeachersDropdown: [],
      // regionAdminReport
      mainAdminReport: [],
      mainAdminReportCount: null,
      mainAdminReportLevelsChart: [],
      mainAdminReportGendersChart: [],
      mainAdminReportCardsData:{},
      // start list history
      adminRequestsHistory:[],
      adminRequestsHistoryCount:null,
      //// For admin when u go to region admin details then
      //// u want to see the school dashboard
      schoolAdminDetailsReport: [],
      schoolAdminDetailsReportCount: null,
      schoolAdminDetailsReportLevelsChart: [],
      schoolAdminDetailsReportGendersChart: [],
  },
  getters: {
    load: (state) => state.load,
    groupStudents: (state) => state.groupStudents,
    seatsList: (state) => state.seatsList,
    seatsListCount: (state) => state.seatsCount,
    regionAdmins: (state) => state.regionAdmins,
    regionAdminsCount: (state) => state.regionAdminsCount,
    schoolAdminReport: (state) => state.schoolAdminReport,
    schoolAdminReportCount: (state) => state.schoolAdminReportCount,
    schoolAdminReportLevelsChart: (state) => state.schoolAdminReportLevelsChart,
    schoolAdminReportGendersChart: (state) => state.schoolAdminReportGendersChart,
    regionAdminReport: (state) => state.regionAdminReport,
    regionAdminReportCount: (state) => state.regionAdminReportCount,
    regionAdminReportLevelsChart: (state) => state.regionAdminReportLevelsChart,
    regionAdminReportGendersChart: (state) => state.regionAdminReportGendersChart,
    regionAdminReportCardsData: (state) => state.regionAdminReportCardsData,
    classDetails: (state) => state.classDetails,
    // students and teachers
    schoolStudents: (state) => state.schoolStudents,
    schoolStudentsCount: (state) => state.schoolStudentsCount,
    schoolStudentsDropdown: (state) => state.schoolStudentsDropdown,
    schoolTeachers: (state) => state.schoolTeachers,
    schoolTeachersCount: (state) => state.schoolTeachersCount,
    schoolTeachersDropdown: (state) => state.schoolTeachersDropdown,
    schoolsAdminList: (state) => state.schoolsAdminList,
    schoolsAdminListCount : (state) => state.schoolsAdminListCount ,
    regionAdminSchools : (state) => state.regionAdminSchools ,
    regionAdminSchoolsCount : (state) => state.regionAdminSchoolsCount ,
    // Main Admin
    mainAdminReport: (state) => state.mainAdminReport,
    mainAdminReportCount: (state) => state.mainAdminReportCount,
    mainAdminReportLevelsChart: (state) => state.mainAdminReportLevelsChart,
    mainAdminReportGendersChart: (state) => state.mainAdminReportGendersChart,
    mainAdminReportCardsData: (state) => state.mainAdminReportCardsData,
    // start list history
    adminRequestsHistory: (state) => state.adminRequestsHistory,
    adminRequestsHistoryCount: (state) => state.adminRequestsHistoryCount,
     //// For admin when u go to region admin details then
      //// u want to see the school dashboard
      schoolAdminDetailsReport: (state) => state.schoolAdminDetailsReport,
      schoolAdminDetailsReportCount: (state) => state.schoolAdminDetailsReportCount,
      schoolAdminDetailsReportLevelsChart: (state) => state.schoolAdminDetailsReportLevelsChart,
      schoolAdminDetailsReportGendersChart: (state) => state.schoolAdminDetailsReportGendersChart,
  },
  mutations: {

    SET_LOAD(state, load) {
      state.load = load;
    },
    SET_GROUP_STUDENTS(state, groupStudents) {
      state.groupStudents = groupStudents;
    },
    SET_SEATS_LIST(state, seats) {
      state.seatsList = seats;
    },
    SET_SEATS_LIST_COUNT(state, count) {
      state.seatsCount = count;
    },
    SET_REGIONS_ADMIN_LIST(state, region) {
      state.regionAdmins = region;
    },
    SET_REGIONS_ADMIN_LIST_COUNT(state, count) {
      state.regionAdminsCount = count;
    },
    SET_SCHOOL_ADMIN_REPORT(state, report) {
      state.schoolAdminReport = report;
    },
    SET_SCHOOL_ADMIN_REPORT_COUNT(state, count) {
      state.schoolAdminReportCount = count;
    },
    SET_SCHOOL_ADMIN_REPORT_LEVELS(state, level) {
      state.schoolAdminReportLevelsChart = level;
    },
    SET_SCHOOL_ADMIN_REPORT_GENDERS(state, gender) {
      state.schoolAdminReportGendersChart = gender;
    },
    SET_REGION_ADMIN_REPORT(state, report) {
      state.regionAdminReport = report;
    },
    SET_REGION_ADMIN_REPORT_COUNT(state, count) {
      state.regionAdminReportCount = count;
    },
    SET_REGION_ADMIN_REPORT_LEVELS(state, level) {
      state.regionAdminReportLevelsChart = level;
    },
    SET_REGION_ADMIN_REPORT_GENDERS(state, gender) {
      state.regionAdminReportGendersChart = gender;
    },
    SET_CLASS_DETAILS(state, det) {
      state.classDetails = det;
    },
    // students and teachers
    SET_SCHOOL_STUDENTS(state, items) {
      state.schoolStudents = items;
    },
    SET_SCHOOL_STUDENTS_COUNT(state, items) {
      state.schoolStudentsCount = items;
    },
    SET_SCHOOL_STUDENTS_DROPDOWN(state, items) {
      state.schoolStudentsDropdown = items;
    },
    SET_SCHOOL_TEACHERS(state, items) {
      state.schoolTeachers = items;
    },
    SET_SCHOOL_TEACHERS_COUNT(state, items) {
      state.schoolTeachersCount = items;
    },
    SET_SCHOOL_TEACHERS_DROPDOWN(state, items) {
      state.schoolTeachersDropdown = items;
    },
    SET_SCHOOLS_ADMIN(state, items) {
      state.schoolsAdminList = items;
    },
    SET_SCHOOLS_ADMIN_COUNT(state, items) {
      state.schoolsAdminListCount = items;
    },
    SET_REGION_ADMIN_SCHOOLS(state, items) {
      state.regionAdminSchools = items;
    },
    SET_REGION_ADMIN_SCHOOLS_COUNT(state, items) {
      state.regionAdminSchoolsCount = items;
    },
    // Main Admin
    SET_Main_ADMIN_REPORT(state, report) {
      state.mainAdminReport = report;
    },
    SET_Main_ADMIN_REPORT_COUNT(state, count) {
      state.mainAdminReportCount = count;
    },
    SET_Main_ADMIN_REPORT_LEVELS(state, level) {
      state.mainAdminReportLevelsChart = level;
    },
    SET_Main_ADMIN_REPORT_GENDERS(state, gender) {
      state.mainAdminReportGendersChart = gender;
    },
     // start list history
     SET_ADMIN_REQUESTS_HISTORY(state, item) {
      state.adminRequestsHistory = item;
    },
     SET_ADMIN_REQUESTS_HISTORY_COUNT(state, item) {
      state.adminRequestsHistoryCount = item;
    },
     //// For admin when u go to region admin details then
      //// u want to see the school dashboard
    SET_SCHOOL_ADMIN_DETAILS_REPORT(state, report) {
      state.schoolAdminDetailsReport = report;
    },
    SET_SCHOOL_ADMIN_DETAILS_REPORT_COUNT(state, count) {
      state.schoolAdminDetailsReportCount = count;
    },
    SET_SCHOOL_ADMIN_DETAILS_REPORT_LEVELS(state, level) {
      state.schoolAdminDetailsReportLevelsChart = level;
    },
    SET_SCHOOL_ADMIN_DETAILS_REPORT_GENDERS(state, gender) {
      state.schoolAdminDetailsReportGendersChart = gender;
    },
  },
  actions: {
    // get group students
    getGroupStudents({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_LOAD", true);
        school
          .get_group_students(payload.id, payload.query)
          .then((response) => {

            commit("SET_GROUP_STUDENTS", response.groups);
            commit("SET_STUDENT_FEEDBACK", response.feedback);
            commit("SET_COUNT", response.count);
            commit("SET_LOAD", false);
            resolve(response);

          })
          .catch((error) => {
            reject(error);
            commit("SET_LOAD", false);
          });
      });
    },
    // get seats list
    getListOfSeats({ commit }, payload ) {
      return new Promise((resolve, reject) => {
        commit("SET_LOAD", true);
        school
          .listOfSeats(payload.query)
          .then((response) => {
            commit("SET_SEATS_LIST", response.requests);
            commit("SET_SEATS_LIST_COUNT", response.count);
            commit("SET_LOAD", false);
            resolve(response);

          })
          .catch((error) => {
            reject(error);
            commit("SET_LOAD", false);
          });
      });
    },
    // get Region Admin
    getListOfRegionAdmins({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_LOAD", true);
        school
          .listOfRegionAdmins( payload.query)
          .then((response) => {
            commit("SET_REGIONS_ADMIN_LIST", response.users);
            commit("SET_REGIONS_ADMIN_LIST_COUNT", response.count
            );
            commit("SET_LOAD", false);

            resolve(response);
          })
          .catch((error) => {
            reject(error);
            commit("SET_LOAD", false);
          });
      });
    },
    // add Region Admin
    handlerAddRegionAdmin({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_LOAD", true);
        school
          .addRegionAdmin( payload)
          .then((response) => {
            commit("SET_LOAD", false);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
            commit("SET_LOAD", false);
          });
      });
    },

    // add Seats
    handlerAddSeats({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_LOAD", true);
        school
          .addSeats( payload)
          .then((response) => {
            commit("SET_LOAD", false);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
            commit("SET_LOAD", false);
          });
      });
    },
     // get School Admin Report
    getSchoolAdminReport({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_LOAD", true);
        school
          .schoolAdminReport( payload.query)
          .then((response) => {
            commit("SET_SCHOOL_ADMIN_REPORT", response.groups
            );
            commit("SET_SCHOOL_ADMIN_REPORT_COUNT", response.count
            );
            commit("SET_SCHOOL_ADMIN_REPORT_LEVELS", response.users_levels_chart

            );

            commit("SET_SCHOOL_ADMIN_REPORT_GENDERS", response.users_genders_chart

            );
            commit("SET_LOAD", false);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
            commit("SET_LOAD", false);
          });
      });
    },
     // get  Region Admin Report
    getRegionAdminReport({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_LOAD", true);
        school
          .regionAdminReport( payload.query)
          .then((response) => {
            let cardData = {
              schools_count: null,
              count_of_active_group: null,
              count_of_active_students: null,
              count_of_active_teachers: null,
            }
            commit("SET_REGION_ADMIN_REPORT", response.schools
            );
            commit("SET_REGION_ADMIN_REPORT_COUNT", response.count
            );
            commit("SET_REGION_ADMIN_REPORT_LEVELS", response.users_levels_chart
            );
            commit("SET_REGION_ADMIN_REPORT_GENDERS", response.users_genders_chart
            );
            cardData.schools_count = response.schools_count
            cardData.count_of_active_group = response.count_of_active_group
            cardData.count_of_active_students = response.count_of_active_students
            cardData.count_of_active_teachers = response.count_of_active_teachers
            state.regionAdminReportCardsData = cardData

            commit("SET_LOAD", false);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
            commit("SET_LOAD", false);
          });
      });
    },
    //
    getClassDetails({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit("SET_LOAD", true);
        school
          .classDetails( id)
          .then((response) => {
            commit("SET_CLASS_DETAILS", response.group

            );

            commit("SET_LOAD", false);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
            commit("SET_LOAD", false);
          });
      });
    },

     // add School Admin
    handlerAddSchoolAdmin({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_LOAD", true);
        school
          .addSchoolAdmin( payload)
          .then((response) => {
            commit("SET_LOAD", false);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
            commit("SET_LOAD", false);
          });
      });
    },
  // Region Approve Seats
  handlerRegionApproveSeats({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit("SET_LOAD", true);
      school
        .regionApproveSeats( id)
        .then((response) => {
          commit("SET_LOAD", false);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
          commit("SET_LOAD", false);
        });
    });
  },
  // add School Class
  handlerAddSchoolClass({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_LOAD", true);
      school
        .addSchoolClass( payload)
        .then((response) => {
          commit("SET_LOAD", false);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
          commit("SET_LOAD", false);
        });
    });
  },
  // add Student
  handlerAddStudent({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_LOAD", true);
      school
        .addStudent( payload)
        .then((response) => {
          commit("SET_LOAD", false);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
          commit("SET_LOAD", false);
        });
    });
  },
  getListOfSchoolStudents({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_LOAD", true);
      school
        .getSchoolStudents( payload.query)
        .then((response) => {
          commit("SET_SCHOOL_STUDENTS", response.students
          );
          commit("SET_SCHOOL_STUDENTS_COUNT", response.count
          );
          commit("SET_LOAD", false);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
          commit("SET_LOAD", false);
        });
    });
  },
  getListOfSchoolStudentsForDropdown({ commit }) {
    return new Promise((resolve, reject) => {
      commit("SET_LOAD", true);
      school
        .getSchoolStudentsNotInGroupDropdown( )
        .then((response) => {
          commit("SET_SCHOOL_STUDENTS_DROPDOWN", response.students
          );
          commit("SET_LOAD", false);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
          commit("SET_LOAD", false);
        });
    });
  },
  // add Teacher
  handlerAddTeacher({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_LOAD", true);
      school
        .addTeacher( payload)
        .then((response) => {
          commit("SET_LOAD", false);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
          commit("SET_LOAD", false);
        });
    });
  },
  getListOfSchoolTeachers({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_LOAD", true);
      school
        .getSchoolTeachers( payload.query)
        .then((response) => {
          commit("SET_SCHOOL_TEACHERS", response.teachers
          );
          commit("SET_SCHOOL_TEACHERS_COUNT", response.count
          );
          commit("SET_LOAD", false);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
          commit("SET_LOAD", false);
        });
    });
  },
  getListOfSchoolTeachersForDropdown({ commit }) {
    return new Promise((resolve, reject) => {
      commit("SET_LOAD", true);
      school
        .getSchoolTeachersDropdown( )
        .then((response) => {
          commit("SET_SCHOOL_TEACHERS_DROPDOWN", response.teachers
          );
          commit("SET_LOAD", false);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
          commit("SET_LOAD", false);
        });
    });
  },

  // Student
  handlerAssignStudentsToGroup({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_LOAD", true);
      school
        .assignStudentsToGroup( payload)
        .then((response) => {
          commit("SET_LOAD", false);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
          commit("SET_LOAD", false);
        });
    });
  },
  // Teacher
  handlerAssignTeacherToGroup({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_LOAD", true);
      school
        .assignTeacherToGroup( payload)
        .then((response) => {
          commit("SET_LOAD", false);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
          commit("SET_LOAD", false);
        });
    });
  },

  // get Schools Admin [ Region Admin Page ]
  getListOfSchoolsAdmin({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_LOAD", true);
      school
        .getAllSchoolsAdmin(payload.query)
        .then((response) => {
        commit("SET_SCHOOLS_ADMIN", response.schools);
        commit("SET_SCHOOLS_ADMIN_COUNT", response.count);
          commit("SET_LOAD", false);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
          commit("SET_LOAD", false);
        });
    });
  },

  // Admin Approve Seats
  handlerAdminApproveSeats({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit("SET_LOAD", true);
      school
        .adminApproveSeats( id)
        .then((response) => {
          commit("SET_LOAD", false);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
          commit("SET_LOAD", false);
        });
    });
  },
  // get  Main Admin Report
  handlerMainAdminReport({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_LOAD", true);
      school
        .mainAdminReport( payload.query)
        .then((response) => {

          let cardData = {
            schools_count: null,
            count_of_active_group: null,
            count_of_active_students: null,
            count_of_active_teachers: null,
          }
          commit("SET_Main_ADMIN_REPORT", response.schools
          );
          commit("SET_Main_ADMIN_REPORT_COUNT", response.schools_count
          );
          commit("SET_Main_ADMIN_REPORT_LEVELS", response.users_levels_chart
          );
          commit("SET_Main_ADMIN_REPORT_GENDERS", response.users_genders_chart
          );
          cardData.schools_count = response.schools_count
          cardData.count_of_active_group = response.count_of_active_group
          cardData.count_of_active_students = response.count_of_active_students
          cardData.count_of_active_teachers = response.count_of_active_teachers
          state.mainAdminReportCardsData = cardData

          commit("SET_LOAD", false);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
          commit("SET_LOAD", false);
        });
    });
  },
  handlerGetRegionAdminSchools({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_LOAD", true);
      school
        .getRegionAdminSchools( payload.region_admin_id, payload.query)
        .then((response) => {

          commit("SET_REGION_ADMIN_SCHOOLS", response.schools
          );
          commit("SET_REGION_ADMIN_SCHOOLS_COUNT", response.count
          );
          commit("SET_LOAD", false);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
          commit("SET_LOAD", false);
        });
    });
  },

   // get seats list
   getListHistoryOfSeats({ commit }, payload ) {
    return new Promise((resolve, reject) => {
      commit("SET_LOAD", true);
      school
        .listHistoryOfSeats(payload.query)
        .then((response) => {
          console.log(response);
          commit("SET_ADMIN_REQUESTS_HISTORY", response.requests);
          commit("SET_ADMIN_REQUESTS_HISTORY_COUNT", response.count
          );
          commit("SET_LOAD", false);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
          commit("SET_LOAD", false);
        });
    });
  },

  // delete teacher and students
  deleteSchoolTeacher({ commit }, id) {
    commit("SET_LOAD", true);
    return new Promise((resolve, reject) => {
      school
        .deleteTeacher(id)
        .then((response) => {
          commit("SET_LOAD", false);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
          commit("SET_LOAD", false);
        });
    });
  },
  deleteSchoolStudent({ commit }, id) {
    commit("SET_LOAD", true);
    return new Promise((resolve, reject) => {
      school
        .deleteStudent(id)
        .then((response) => {
          commit("SET_LOAD", false);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
          commit("SET_LOAD", false);
        });
    });
  },

  handlerGetRegionAdminSchoolsReport({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_LOAD", true);
      school
        .getRegionAdminSchoolsReport(payload.id, payload.query)
        .then((response) => {

          commit("SET_SCHOOL_ADMIN_DETAILS_REPORT", response.groups
            );
            commit("SET_SCHOOL_ADMIN_DETAILS_REPORT_COUNT", response.count
            );
            commit("SET_SCHOOL_ADMIN_DETAILS_REPORT_LEVELS", response.users_levels_chart

            );

            commit("SET_SCHOOL_ADMIN_DETAILS_REPORT_GENDERS", response.users_genders_chart

            );
          commit("SET_LOAD", false);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
          commit("SET_LOAD", false);
        });
    });
  },

  deleteSchoolFromRegionAdmin({ commit }, id) {
    commit("SET_LOAD", true);
    return new Promise((resolve, reject) => {
      school
        .deleteSchool(id)
        .then((response) => {
          commit("SET_LOAD", false);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
          commit("SET_LOAD", false);
        });
    });
  },

  handlerUpdateRegionAdminSchool({ commit },payload) {
    commit("SET_LOAD", true);
    return new Promise((resolve, reject) => {
      school
        .updateRegionAdminSchool( payload.query, payload.id)
        .then(response => {
          commit("SET_LOAD", false);
          resolve(response);
        }).catch((err)=>{
          commit("SET_LOAD", false);
          reject(err)
        })
    });
  },
  // export template
  handlerExportTemplate({ commit }) {
    commit("SET_LOAD", true);
    return new Promise((resolve, reject) => {
      school
        .exportTemplate()
        .then(response => {
          commit("SET_LOAD", false);
          FileDownload(response, "template.xlsx");
          resolve(response);
        }).catch((err)=>{
          commit("SET_LOAD", false);
          reject(err)
        })
    });
  },
  // export  Teachers template
  handlerExportTeachersTemplate({ commit }) {
    commit("SET_LOAD", true);
    return new Promise((resolve, reject) => {
      school
        .exportTeachersTemplate()
        .then(response => {
          commit("SET_LOAD", false);
          FileDownload(response, "template.xlsx");
          resolve(response);
        }).catch((err)=>{
          commit("SET_LOAD", false);
          reject(err)
        })
    });
  },


  }
};

export default schoolModule;





