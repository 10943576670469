<template>
  <div class="col">
    <div class="card">
      <!-- Start Accordion  -->
      <el-collapse accordion>
        <el-collapse-item
          v-for="team in usersGroupsData"
          :key="team.id"
          :title="team.name"
          :name="team.id"
        >
          <!-- Start Header  -->
          <template slot="title">
            <div class="wrapper_collapse_for_header">
              <div class="head_part_one">
                <span class="d-block user-name text-capitalize">
                  {{ team.name }}
                </span>
              </div>
              <div class="print_group_wrapper">
                <button
                  class="open_print_group"
                  @click="PrintExamForGroup(team.id)"
                >
                {{ $t("admin_dashboard_type.Print") }}
                </button>
              </div>
            </div>
          </template>
          <!-- End Header  -->
          <div>
            <el-table :data="team.users">
              <!-- Name Column -->
              <el-table-column
                :label="$t('admin_dashboard_type.name')"
                prop="name"
              />

              <!-- Pre 1 -->
              <el-table-column label="Pre 1">
                <template v-slot="scope">
                  <StatusImage
                    :status="
                      getStatus(scope.row.neomi_pre, scope.row.pre_marked)
                    "
                  />
                </template>
              </el-table-column>

              <!-- Pre 2 -->
              <el-table-column label="Pre 2">
                <template v-slot="scope">
                  <StatusImage
                    :status="
                      getStatus(
                        scope.row.neomi_pre_two,
                        scope.row.pre_two_marked
                      )
                    "
                  />
                </template>
              </el-table-column>

              <el-table-column label="Pre 3">
                <template v-slot="scope">
                  <StatusImage
                    :status="
                      getStatus(
                        scope.row.neomi_pre_three,
                        scope.row.pre_three_marked
                      )
                    "
                  />
                </template>
              </el-table-column>

              <!-- Pre Exam Actions -->
              <el-table-column :label="$t('admin_dashboard_type.pre_exam')">
                <template v-slot="scope">
                  <el-button
                    class="btn btn-primary mr-2 ml-2"
                    @click="openPreExam(scope.row)"
                  >
                    Actions
                  </el-button>
                </template>
              </el-table-column>

              <!-- Post 1 -->
              <el-table-column label="Post 1">
                <template v-slot="scope">
                  <StatusImage
                    :status="
                      getStatus(scope.row.neomi_after, scope.row.after_marked)
                    "
                  />
                </template>
              </el-table-column>

              <!-- Post 2 -->
              <el-table-column label="Post 2">
                <template v-slot="scope">
                  <StatusImage
                    :status="
                      getStatus(
                        scope.row.neomi_after_two,
                        scope.row.after_two_marked
                      )
                    "
                  />
                </template>
              </el-table-column>

              <el-table-column label="Post 3">
                <template v-slot="scope">
                  <StatusImage
                    :status="
                      getStatus(
                        scope.row.neomi_after_three,
                        scope.row.after_three_marked
                      )
                    "
                  />
                </template>
              </el-table-column>

              <!-- Post Exam Actions -->
              <el-table-column
                prop="student_id"
                :label="$t('admin_dashboard_type.post_exam')"
                align="right"
              >
                <template v-slot="scope">
                  <el-button
                    class="btn btn-primary mr-2 ml-2"
                    @click="openPostExam(scope.row)"
                  >
                    Actions
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-collapse-item>
      </el-collapse>
      <!-- End Accordion  -->
    </div>

    <!-- Start Pre Exam Dialog   -->
    <el-dialog
      :title="$t('admin_dashboard_type.pre_exam')"
      width="40%"
      :visible.sync="open_pre_dialog"
      top="4vh"
      class="text-capitalize"
      :show-close="false"
    >
      <!-- Start Radio Inputs For Switch Between -->
      <div class="custom_radios">
        <el-radio v-model="switchBetweenPreTest" :label="1"
          >{{ $t("admin_dashboard_type.pre_exam") }} 1</el-radio
        >
        <el-radio v-model="switchBetweenPreTest" :label="2"
          >{{ $t("admin_dashboard_type.pre_exam") }} 2</el-radio
        >
        <el-radio v-model="switchBetweenPreTest" :label="3"
          >{{ $t("admin_dashboard_type.pre_exam") }} 3</el-radio
        >
      </div>
      <!-- End Radio Inputs For Switch Between -->
      <!-- Start Buttons -->
      <div class="main_actions_buttons_wrapper">
        <div class="pre_buttons" v-if="switchBetweenPreTest == 1">
          <button
            class="custom_btn reset"
            :disabled="preTestData.neomi_pre == 0"
            @click="resetPreExamTimer(0)"
            :class="{ is_disabled: preTestData.neomi_pre == 0 }"
          >
            {{ $t("Reset time") }}
          </button>
          <button
            :disabled="preTestData.neomi_pre == 0"
            :class="{ is_disabled: preTestData.neomi_pre == 0 }"
            class="custom_btn reset examination"
            @click="redoPreExam(0)"
          >
            {{ $t("Re-examination") }}
          </button>
          <button
            :disabled="preTestData.neomi_pre == 0"
            :class="{ is_disabled: preTestData.neomi_pre == 0 }"
            class="custom_btn reset correction"
            @click="correctPreExam(0)"
          >
            {{ $t("exam_correction") }}
          </button>
          <button
            :disabled="preTestData.neomi_pre == 0"
            :class="{ is_disabled: preTestData.neomi_pre == 0 }"
            class="custom_btn reset delete"
            @click="printPreExam(0)"
          >
            {{ $t("Print") }}
          </button>
        </div>
        <div class="pre_buttons post_buttons" v-if="switchBetweenPreTest == 2">
          <button
            class="custom_btn reset"
            :disabled="preTestData.neomi_pre_two == 0"
            :class="{ is_disabled: preTestData.neomi_pre_two == 0 }"
            @click="resetPreExamTimer(1)"
          >
            {{ $t("Reset time") }}
          </button>
          <button
            class="custom_btn reset examination"
            :disabled="preTestData.neomi_pre_two == 0"
            :class="{ is_disabled: preTestData.neomi_pre_two == 0 }"
            @click="redoPreExam(1)"
          >
            {{ $t("Re-examination") }}
          </button>
          <button
            class="custom_btn reset correction"
            :disabled="preTestData.neomi_pre_two == 0"
            :class="{ is_disabled: preTestData.neomi_pre_two == 0 }"
            @click="correctPreExam(1)"
          >
            {{ $t("exam_correction") }}
          </button>
          <button
            class="custom_btn reset delete"
            :disabled="preTestData.neomi_pre_two == 0"
            :class="{ is_disabled: preTestData.neomi_pre_two == 0 }"
            @click="printPreExam(1)"
          >
            {{ $t("Print") }}
          </button>
        </div>
        <div class="pre_buttons post_buttons" v-if="switchBetweenPreTest == 3">
          <button
            class="custom_btn reset"
            :disabled="preTestData.neomi_pre_three == 0"
            :class="{ is_disabled: preTestData.neomi_pre_three == 0 }"
            @click="resetPreExamTimer(2)"
          >
            {{ $t("Reset time") }}
          </button>
          <button
            class="custom_btn reset examination"
            :disabled="preTestData.neomi_pre_three == 0"
            :class="{ is_disabled: preTestData.neomi_pre_three == 0 }"
            @click="redoPreExam(2)"
          >
            {{ $t("Re-examination") }}
          </button>
          <button
            class="custom_btn reset correction"
            :disabled="preTestData.neomi_pre_three == 0"
            :class="{ is_disabled: preTestData.neomi_pre_three == 0 }"
            @click="correctPreExam(2)"
          >
            {{ $t("exam_correction") }}
          </button>
          <button
            class="custom_btn reset delete"
            :disabled="preTestData.neomi_pre_three == 0"
            :class="{ is_disabled: preTestData.neomi_pre_three == 0 }"
            @click="printPreExam(2)"
          >
            {{ $t("Print") }}
          </button>
        </div>
      </div>
      <!-- End Buttons -->
      <div slot="footer" class="dialog_footers">
        <el-button class="text-center my-1" @click="open_pre_dialog = false"
          >{{ $t("cancel") }}
        </el-button>
      </div>
    </el-dialog>
    <!-- End Pre Exam Dialog   -->

    <!-- Start Post Exam Dialog   -->
    <el-dialog
      :title="$t('admin_dashboard_type.post_exam')"
      width="40%"
      :visible.sync="open_post_dialog"
      top="4vh"
      class="text-capitalize"
      :show-close="false"
    >
      <!-- Start Radio Inputs For Switch Between -->
      <div class="custom_radios">
        <el-radio v-model="switchBetweenPostTest" :label="1"
          >{{ $t("admin_dashboard_type.post_exam") }} 1</el-radio
        >
        <el-radio v-model="switchBetweenPostTest" :label="2"
          >{{ $t("admin_dashboard_type.post_exam") }} 2</el-radio
        >
        <el-radio v-model="switchBetweenPostTest" :label="3"
          >{{ $t("admin_dashboard_type.post_exam") }} 3</el-radio
        >
      </div>
      <!-- End Radio Inputs For Switch Between -->
      <!-- Start Buttons -->
      <div class="main_actions_buttons_wrapper">
        <div class="pre_buttons" v-if="switchBetweenPostTest == 1">
          <button
            class="custom_btn reset"
            :disabled="postTestData.neomi_after == 0"
            :class="{ is_disabled: postTestData.neomi_after == 0 }"
            @click="resetPostExamTimer(0)"
          >
            {{ $t("Reset time") }}
          </button>
          <button
            class="custom_btn reset examination"
            :disabled="postTestData.neomi_after == 0"
            :class="{ is_disabled: postTestData.neomi_after == 0 }"
            @click="redoPostExam(0)"
          >
            {{ $t("Re-examination") }}
          </button>
          <button
            class="custom_btn reset correction"
            :disabled="postTestData.neomi_after == 0"
            :class="{ is_disabled: postTestData.neomi_after == 0 }"
            @click="correctPostExam(0)"
          >
            {{ $t("exam_correction") }}
          </button>
          <button
            class="custom_btn reset delete"
            :disabled="postTestData.neomi_after == 0"
            :class="{ is_disabled: postTestData.neomi_after == 0 }"
            @click="printPostExam(0)"
          >
            {{ $t("Print") }}
          </button>
        </div>
        <div class="pre_buttons post_buttons" v-if="switchBetweenPostTest == 2">
          <button
            class="custom_btn reset"
            :disabled="postTestData.neomi_after_two == 0"
            :class="{ is_disabled: postTestData.neomi_after_two == 0 }"
            @click="resetPostExamTimer(1)"
          >
            {{ $t("Reset time") }}
          </button>
          <button
            class="custom_btn reset examination"
            :disabled="postTestData.neomi_after_two == 0"
            :class="{ is_disabled: postTestData.neomi_after_two == 0 }"
            @click="redoPostExam(1)"
          >
            {{ $t("Re-examination") }}
          </button>
          <button
            class="custom_btn reset correction"
            :disabled="postTestData.neomi_after_two == 0"
            :class="{ is_disabled: postTestData.neomi_after_two == 0 }"
            @click="correctPostExam(1)"
          >
            {{ $t("exam_correction") }}
          </button>
          <button
            class="custom_btn reset delete"
            :disabled="postTestData.neomi_after_two == 0"
            :class="{ is_disabled: postTestData.neomi_after_two == 0 }"
            @click="printPostExam(1)"
          >
            {{ $t("Print") }}
          </button>
        </div>
        <div class="pre_buttons post_buttons" v-if="switchBetweenPostTest == 3">
          <button
            class="custom_btn reset"
            :disabled="postTestData.neomi_after_three == 0"
            :class="{ is_disabled: postTestData.neomi_after_three == 0 }"
            @click="resetPostExamTimer(2)"
          >
            {{ $t("Reset time") }}
          </button>
          <button
            class="custom_btn reset examination"
            :disabled="postTestData.neomi_after_three == 0"
            :class="{ is_disabled: postTestData.neomi_after_three == 0 }"
            @click="redoPostExam(2)"
          >
            {{ $t("Re-examination") }}
          </button>
          <button
            class="custom_btn reset correction"
            :disabled="postTestData.neomi_after_three == 0"
            :class="{ is_disabled: postTestData.neomi_after_three == 0 }"
            @click="correctPostExam(2)"
          >
            {{ $t("exam_correction") }}
          </button>
          <button
            class="custom_btn reset delete"
            :disabled="postTestData.neomi_after_three == 0"
            :class="{ is_disabled: postTestData.neomi_after_three == 0 }"
            @click="printPostExam(2)"
          >
            {{ $t("Print") }}
          </button>
        </div>
      </div>
      <!-- End Buttons -->
      <div slot="footer" class="dialog_footers">
        <el-button class="text-center my-1" @click="open_post_dialog = false"
          >{{ $t("cancel") }}
        </el-button>
      </div>
    </el-dialog>
    <!-- End Post Exam Dialog   -->

    <!-- Start Open Print Modal For Group -->
    <el-dialog
      :title="$t('admin_dashboard_type.print_group')"
      width="40%"
      :visible.sync="open_print_dialog"
      top="4vh"
      class="text-capitalize"
      :show-close="false"
    >
      <!-- Start Radio Inputs For Switch Between -->
      <div class="custom_radios">
        <el-radio v-model="switchBetweenPreTestForPrint" :label="1"
          >{{ $t("admin_dashboard_type.pre_exam") }}
        </el-radio>
        <el-radio v-model="switchBetweenPreTestForPrint" :label="2"
          >{{ $t("admin_dashboard_type.post_exam") }}
        </el-radio>
      </div>
      <!-- End Radio Inputs For Switch Between -->
      <div class="main_actions_buttons_wrapper">
        <div class="pre_buttons" v-if="switchBetweenPreTestForPrint == 1">
          <button @click="goToPrintGroupPage(0, 0)" class="custom_btn">
            {{ $t("admin_dashboard_type.test_one") }}
          </button>
          <button @click="goToPrintGroupPage(0, 1)" class="custom_btn">
            {{ $t("admin_dashboard_type.test_two") }}

          </button>
          <button @click="goToPrintGroupPage(0, 2)" class="custom_btn">
            {{ $t("admin_dashboard_type.test_three") }}
          </button>
          <button @click="goToPrintGroupPageAll(0)"  class="custom_btn">
            Print All
          </button>
        </div>
        <div class="pre_buttons" v-if="switchBetweenPreTestForPrint == 2">
          <button @click="goToPrintGroupPage(1, 0)" class="custom_btn">
            {{ $t("admin_dashboard_type.test_one") }}
          </button>
          <button @click="goToPrintGroupPage(1, 1)" class="custom_btn">
            {{ $t("admin_dashboard_type.test_two") }}
          </button>
          <button @click="goToPrintGroupPage(1, 2)" class="custom_btn">
            {{ $t("admin_dashboard_type.test_three") }}
          </button>
          <!-- <button @click="goToPrintGroupPage(1, 2) , goToPrintGroupPage(1, 1) , goToPrintGroupPage(1, 0) "  class="custom_btn">
            Print All
          </button> -->
        </div>
      </div>
      <div slot="footer" class="dialog_footers">
        <el-button class="text-center my-1" @click="open_print_dialog = false"
          >{{ $t("cancel") }}
        </el-button>
      </div>
    </el-dialog>
    <!-- End Open Print Modal For Group -->
  </div>
</template>

<script>
import Swal from "sweetalert2";
import StatusImage from "@/components/NeomiTest/StatusImage.vue";

export default {
  components: {
    StatusImage,
  },
  data() {
    return {
      switchTitle: "",
      switchBetweenPreTest: 1,
      switchBetweenPostTest: 1,
      nemoiData: {
        student_id: null,
        challenge_no: null,
        exam_type: null,
      },
      open_pre_dialog: false,
      open_post_dialog: false,
      preTestData: {},
      postTestData: {},
      switchBetweenPreTestForPrint: 1,
      // switchBetweenPostTestForPrint: 1,
      open_print_dialog: false,
      printGroupId: null,
    }
  },
  computed: {
    usersGroupsData() {
      return this.$store.getters["groups/getNewNeomiTwoExamsData"];
    },
  },
  mounted() {
    this.refreshData();
  },
  methods: {
    refreshData() {
      this.$store.dispatch("groups/getNewNeomiExamsData");
    },
    // Start Handler Pre Exam Functions
    openPreExam(item) {
      this.preTestData = item;
      this.open_pre_dialog = true;
      this.nemoiData.student_id = item.id;
    },
    getStatus(neomi, marked) {
      if (neomi === 0 && marked === 0) {
        return 0;
      } else if (neomi === 1 && marked === 0) {
        return 1;
      } else if (neomi === 1 && marked === 1) {
        return 2;
      }
      return 0; // Default case if none match, this can be adjusted as needed
    },
    resetPreExamTimer(challenge_no) {
      this.switchBetweenPreTest == 1
        ? (this.nemoiData.exam_type = 0)
        : (this.nemoiData.exam_type = 0);
        this.nemoiData.challenge_no = challenge_no;

      Swal.fire({
        text: `${this.$t("are you sure neomi reset")}`,
        icon: "error",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        buttonsStyling: false,
      }).then((date) => {
        if (date.isConfirmed) {
          this.$store
            .dispatch("groups/resetNemoiTimer", this.nemoiData)
            .then(() => {
              this.open_pre_dialog = false;
              Swal.fire({
                text: `${this.$t("The time has been reset")}`,
                icon: "success",
                showCancelButton: false,
                confirmButtonClass: "btn btn-success btn-fill",
                confirmButtonText: this.$t("confirm"),
                buttonsStyling: false,
              }).then((date) => {
                if (date.isConfirmed) {
                  this.refreshData();
                }
              });
            });
        }
      });
    },
    redoPreExam(challenge_no) {
      this.switchBetweenPreTest == 1
        ? (this.nemoiData.exam_type = 0)
        : (this.nemoiData.exam_type = 0);
        this.nemoiData.challenge_no = challenge_no;
      Swal.fire({
        text: `${this.$t("are you sure neomi redo")}`,
        icon: "error",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        buttonsStyling: false,
      }).then((date) => {
        if (date.isConfirmed) {
          this.$store
            .dispatch("groups/redoNemoiTest", this.nemoiData)
            .then(() => {
              this.open_pre_dialog = false;
              Swal.fire({
                text: `${this.$t("The exam was repeated")}`,
                icon: "success",
                showCancelButton: false,
                confirmButtonClass: "btn btn-success btn-fill",
                confirmButtonText: this.$t("confirm"),
                buttonsStyling: false,
              }).then((date) => {
                if (date.isConfirmed) {
                  this.refreshData();
                }
              });
            });
        }
      });
    },
    correctPreExam(challenge_no) {
      this.switchBetweenPreTest == 1
        ? (this.nemoiData.exam_type = 0)
        : (this.nemoiData.exam_type = 0);
        this.nemoiData.challenge_no = challenge_no;


      this.$router.push(
        `/correct-neomi-exam/${this.nemoiData.student_id}/${this.nemoiData.challenge_no}/${this.nemoiData.exam_type}`
      );
      this.open_pre_dialog = false;
    },
    printPreExam(challenge_no) {
      this.switchBetweenPreTest == 1
        ? (this.nemoiData.exam_type = 0)
        : (this.nemoiData.exam_type = 0);
        this.nemoiData.challenge_no = challenge_no;

      this.$router.push(
        `/print-neomi-exam/${this.nemoiData.student_id}/${this.nemoiData.challenge_no}/${this.nemoiData.exam_type}`
      );
      this.open_pre_dialog = false;
    },
    // End Handler Pre Exam Functions

    // Start Handler Post Exam Functions
    openPostExam(item) {
      this.postTestData = item;
      this.open_post_dialog = true;
      this.nemoiData.student_id = item.id;
    },
    resetPostExamTimer(challenge_no) {
      this.switchBetweenPostTest == 1
        ? (this.nemoiData.exam_type = 1)
        : (this.nemoiData.exam_type = 1);
        this.nemoiData.challenge_no = challenge_no;

      Swal.fire({
        text: `${this.$t("are you sure neomi reset")}`,
        icon: "error",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        buttonsStyling: false,
      }).then((date) => {
        if (date.isConfirmed) {
          this.$store
            .dispatch("groups/resetNemoiTimer", this.nemoiData)
            .then(() => {
              this.open_post_dialog = false;
              Swal.fire({
                text: `${this.$t("The time has been reset")}`,
                icon: "success",
                showCancelButton: false,
                confirmButtonClass: "btn btn-success btn-fill",
                confirmButtonText: this.$t("confirm"),
                buttonsStyling: false,
              }).then((date) => {
                if (date.isConfirmed) {
                  this.refreshData();
                }
              });
            });
        }
      });
    },
    redoPostExam(challenge_no) {
      this.switchBetweenPostTest == 1
        ? (this.nemoiData.exam_type = 1)
        : (this.nemoiData.exam_type = 1);
        this.nemoiData.challenge_no = challenge_no;

      Swal.fire({
        text: `${this.$t("are you sure neomi redo")}`,
        icon: "error",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        buttonsStyling: false,
      }).then((date) => {
        if (date.isConfirmed) {
          this.$store
            .dispatch("groups/redoNemoiTest", this.nemoiData)
            .then(() => {
              this.open_post_dialog = false;
              Swal.fire({
                text: `${this.$t("The exam was repeated")}`,
                icon: "success",
                showCancelButton: false,
                confirmButtonClass: "btn btn-success btn-fill",
                confirmButtonText: this.$t("confirm"),
                buttonsStyling: false,
              }).then((date) => {
                if (date.isConfirmed) {
                  this.refreshData();
                }
              });
            });
        }
      });
    },
    correctPostExam(challenge_no) {
      this.switchBetweenPostTest == 1
        ? (this.nemoiData.exam_type = 1)
        : (this.nemoiData.exam_type = 1);
        this.nemoiData.challenge_no = challenge_no;

      this.$router.push(
        `/correct-neomi-exam/${this.nemoiData.student_id}/${this.nemoiData.challenge_no}/${this.nemoiData.exam_type}`
      );
      this.open_post_dialog = false;
    },
    printPostExam(challenge_no) {
      this.switchBetweenPostTest == 1
        ? (this.nemoiData.exam_type = 1)
        : (this.nemoiData.exam_type = 1);
        this.nemoiData.challenge_no = challenge_no;

      this.$router.push(
        `/print-neomi-exam/${this.nemoiData.student_id}/${this.nemoiData.challenge_no}/${this.nemoiData.exam_type}`
      );
      this.open_post_dialog = false;
    },
    // End Handler Post Exam Functions
    // Print
    PrintExamForGroup(id) {
      this.open_print_dialog = true;
      this.printGroupId = id;
    },
    goToPrintGroupPage(exam_type,challenge_no) {
      this.$router.push(
        `/print-neomi-exam-group/${exam_type}/${challenge_no}/${this.printGroupId}`
      );
    },
    goToPrintGroupPageAll(exam_type) {
      this.$router.push(
        `/print-neomi-exam-group-all/${exam_type}/${this.printGroupId}`
      );
    },
  }
};
</script>

<style lang="scss" scoped>
.wrapper_collapse_for_header {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  width: 90%;
  align-items: center;
  .print_group_wrapper {
    .open_print_group {
      min-width: 80px;
      padding: 8px;
      height: 37px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ffa534;
      border: 1px solid #ffa534;
      color: #fff;
      border-radius: 8px;
      transition: 0.3s;
    }
  }
}
.dialog_footers {
  display: flex;
  justify-content: center;
}
::v-deep .cell {
  text-align: center;
  word-break: break-word;
}
::v-deep .el-dialog__header {
  text-align: center;
}
::v-deep .el-dialog__body {
  padding: 0;
}
::v-deep .el-radio__label {
  padding: 0 8px;
}
::v-deep .el-collapse-item__header {
  text-align: initial;
}
.custom_radios {
  text-align: center;
  margin-top: 30px;
}
.img_status {
  width: 30px;
  height: 30px;
}
.main_actions_buttons_wrapper {
  margin: 30px 0;
  .pre_buttons {
    display: flex;
    gap: 20px;
    justify-content: center;
    flex-wrap: wrap;
    .custom_btn {
      background-color: #23ccef;
      border: 1px solid #23ccef;
      color: #fff;
      padding: 8px 20px;
      min-width: 120px;
      border-radius: 8px;
      &.is_disabled {
        cursor: no-drop;
        opacity: 0.5;
      }
      &.examination {
        background-color: #fb404b;
        border-color: #fb404b;
      }
      &.correction {
        background-color: #87cb16;
        border-color: #87cb16;
      }
      &.delete {
        background-color: #ffa534;
        border-color: #ffa534;
      }
    }
  }
}
</style>
