<template>
  <div></div>
</template>

<script>
export default {
  name: "course-groups",
  methods: {
    Refresh() {
      this.$store.dispatch("user/user", this.auth().id);
      this.$store.dispatch("groups/courseGroups", this.$route.params.id);

    },
  },
  mounted() {
    this.Refresh();
  },
};
</script>

<style  scoped>
</style>
