<template>
  <div class="col quota_page_wrapper" v-loading="loadingValue">
    <card>
      <div class="some_details">
        <div class="every_part shadow-sm">
          <span class="d-block w-100 title">{{
            $t("admin_dashboard_type.active_seats")
          }}</span>
          <span
            class="w-100 d-flex justify-content-center used_seats"
            :class="{ direction_reverse: whichLanguage != 'ar' }"
          >
            <span>{{ max_seats }}</span>
            <span>/</span>
            <span>{{ seats_count }}</span>
          </span>
        </div>
      </div>
    </card>
    <div class="every_table_action_button">
      <button class="our_btn" @click="showAddSeatsDialog = true">
        <el-icon class="el-icon-plus"></el-icon>
        {{ $t("admin_dashboard_type.request_license") }}
      </button>
    </div>
    <!-- Start All Seats  -->
    <server-table
      :DataItems="seatsList"
      :count="seatsListCount"
      @Refresh="Refresh"
      :useFilter="false"
      :enable_export="false"
      :action="false"
    >
      <template #columns>
        <el-table-column
          prop="name"
          :label="$t('admin_dashboard_type.school_name')"
        >
        </el-table-column>
        <el-table-column
          prop="seats_number"
          :label="$t('admin_dashboard_type.seats_number')"
          min-width="100"
        >
        </el-table-column>
        <el-table-column
          prop="status"
          :label="$t('admin_dashboard_type.request_date')"
        >
          <template slot-scope="scope">
            {{
              scope.row.created_at
                ? toLocalDatetime(scope.row.created_at, "en")
                : "___"
            }}
          </template>
        </el-table-column>
        <el-table-column
          prop="status"
          :label="$t('admin_dashboard_type.status')"
        >
          <template slot-scope="scope">
            <div class="d-flex justify-content-center">
              <span
                class="d-block status_value pending"
                v-if="scope.row.status == 3 || scope.row.status == 2"
              >
                {{ $t("admin_dashboard_type.Pending") }}
              </span>
              <span
                class="d-block status_value approved"
                v-if="scope.row.status == 1"
              >
                {{ $t("admin_dashboard_type.Approved") }}
              </span>
              <span
                class="d-block status_value declined"
                v-if="scope.row.status == 0"
              >
                {{ $t("admin_dashboard_type.Declined") }}
              </span>
            </div>
          </template>
        </el-table-column>
      </template>
    </server-table>
    <!-- End All Seats  -->

    <!-- Start Add Student -->
    <el-dialog
      :visible.sync="showAddSeatsDialog"
      :title="$t('admin_dashboard_type.add_seats')"
      transition="dialog-bottom-transition"
      width="50%"
      top="4vh"
      class="text-right"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <el-form class="demo-ruleForm" label-position="top">
        <div class="row">
          <div class="col-md-6">
            <el-form-item
              :label="$t('admin_dashboard_type.seats_number_request')"
              prop="seats_number"
            >
              <el-input
                v-model.number="seats_number"
                :min="1"
                :label="$t('admin_dashboard_type.seats_number_request')"
                class="w-100"
                type="number"
              ></el-input>
            </el-form-item>
          </div>
        </div>
      </el-form>
      <div class="creative_app_footer_dialog">
        <el-button
          :disabled="seats_number == null || seats_number < 1"
          @click="addSeats"
          class="when_submit_form save_from"
        >
          {{ $t("admin_dashboard_type.save") }}</el-button
        >
        <el-button
          class="when_submit_form cancel_from"
          @click="showAddSeatsDialog = false"
        >
          {{ $t("cancel") }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ServerTable from "../../components/DataTablePagination.vue";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      showAddSeatsDialog: false,
      seats_number: null,
      max_seats: "",
      seats_count: "",
    };
  },
  components: { ServerTable },
  mounted() {
    this.Refresh();
  },
  computed: {
    ...mapGetters({
      loadingValue: ["schools/load"],
      seatsList: ["schools/seatsList"],
      seatsListCount: ["schools/seatsListCount"],
    }),
    whichLanguage() {
      return this.$i18n.locale;
    },
    offset() {
      if (this.$route.query.currentPage && this.$route.query.perPage) {
        return (this.$route.query.currentPage - 1) * this.$route.query.perPage;
      } else {
        return 0;
      }
    },

    limit() {
      if (this.$route.query.perPage) {
        return this.$route.query.perPage - 1;
      } else {
        return 4;
      }
    },
  },
  methods: {
    Refresh(
      query = { offset: this.offset, limit: this.limit, order_by: "id" }
    ) {
      this.$store
        .dispatch("schools/getListOfSeats", { query: query })
        .then((res) => {
          this.max_seats = res.max_seats;
          this.seats_count = res.seats_count;
        });
    },
    addSeats() {
      this.$store
        .dispatch("schools/handlerAddSeats", {
          seats_number: this.seats_number,
        })
        .then(() => {
          this.showAddSeatsDialog = false;
          Swal.fire({
            text: `${this.$t("success_added")}`,
            icon: "success",
            showCancelButton: false,
            confirmButtonClass: "btn btn-success btn-fill",
            confirmButtonText: `${this.$t("confirm")}`,
            buttonsStyling: false,
          });
          this.Refresh();
          this.seats_number = null;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.used_seats {
  &.direction_reverse {
    flex-direction: row-reverse;
  }
}

.every_table_action_button {
  padding: 10px 0;
  .our_btn {
    background-color: #409eff;
    color: #fff;
    padding: 8px 16px;
    border-radius: 5px;
    transition: 0.5;
    border: 1px solid #409eff;
    &:hover {
      opacity: 0.8;
    }
  }
}
::v-deep .cell {
  text-align: center;
  word-break: break-word;
}
::v-deep .el-form--label-top .el-form-item__label {
  width: 100%;
  text-align: right;
}
::v-deep .el-form-item__error {
  right: 0;
  left: unset;
}
::v-deep .el-form-item {
  text-align: right;
}
.status_value {
  padding: 5px 17px;
  width: fit-content;
  border-radius: 20px;
  &.pending {
    background-color: #11bfe3;
    color: #ffffff;
  }
  &.approved {
    background-color: #87cb16;
    color: #ffffff;
  }
  &.declined {
    background-color: #f56c6c;
    color: #ffffff;
  }
}
.quota_page_wrapper {
  .creative_app_footer_dialog {
    display: flex;
    margin-top: 15px;
    justify-content: end;
    padding: 10px;
    gap: 10px;
    .when_submit_form {
      min-width: 150px;
      text-align: center;
      transition: 0.3s;
      &.save_from {
        border: 1px solid #409eff;
        background-color: #409eff;
        color: #fff;
      }
      &.cancel_from {
        border: 1px solid #fb404b;
        background-color: #fb404b;
        color: #fff;
      }
      &:hover {
        opacity: 0.7;
      }
    }
  }
  .some_details {
    display: flex;
    gap: 1%;
    flex-wrap: wrap;
    padding: 15px;
    .every_part {
      width: calc(96% / 4);
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 10px;
      min-height: 150px;
      transition: 0.5s;
      border-radius: 8px;
      &:hover {
        background-color: #eee;
      }
      .title {
        font-weight: bold;
        color: #555;
        font-size: 17px;
      }
    }
  }
}
</style>

<style lang="scss">
.quota_page_wrapper {
  .el-dialog__header {
    text-align: initial !important;
  }
  .el-form-item__label {
    width: 100%;
    text-align: initial !important;
  }
  .el-form-item__error {
    width: 100%;
    text-align: initial;
  }
}
</style>
