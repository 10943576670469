<template>
  <div class="col school_teachers_page_wrapper">
    <!-- Start Teachers Data  -->
    <div class="every_table_action_button">
      <button class="our_btn text-capitalize" @click="openAddDialog">
        <el-icon class="el-icon-plus"></el-icon>
        {{ $t("admin_dashboard_type.add_teacher") }}
      </button>
    </div>
    <server-table
      :count="schoolTeachersCount"
      :DataItems="schoolTeachers"
      :enable_export="false"
      :useFilter="false"
      :action="false"
      @Refresh="Refresh"
      v-loading="loadingValue"
    >
      <template #columns>
        <el-table-column
          prop="name"
          :label="$t('admin_dashboard_type.teacher_name')"
        >
          <template slot-scope="scope">
            <span>
              {{ scope.row.name }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="email"
          :label="$t('admin_dashboard_type.email')"
        ></el-table-column>
        <el-table-column
          prop="country"
          :label="$t('admin_dashboard_type.country')"
        >
          <template slot-scope="scope">
            <span>
              {{ scope.row.country ? scope.row.country : "__" }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="city" :label="$t('admin_dashboard_type.city')">
          <template slot-scope="scope">
            <span>
              {{ scope.row.city ? scope.row.city : "__" }}
            </span>
          </template>
        </el-table-column>

        <el-table-column
          prop="specialization"
          :label="$t('admin_dashboard_type.specialization')"
        >
          <template slot-scope="scope">
            <span>
              {{ whichSpecialization(scope.row.specialization) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="_">
          <template slot-scope="scope">
            <div class="icons_table_wrapper">
              <i
                @click="removeTeacher(scope.row.id)"
                class="fa fa-trash shadow-sm icon remove"
                v-tooltip.top-center="$t('admin_dashboard_type.delete_teacher')"
              ></i>
              <i
                class="fa fa-edit shadow-sm icon edit"
                @click="openEditDialog(scope.row)"
                v-tooltip.top-center="$t('admin_dashboard_type.edit_teacher')"
              ></i>
            </div>
          </template>
        </el-table-column>
      </template>
    </server-table>
    <!-- Start Teachers Data  -->

    <!-- Start Add Teacher  -->
    <el-dialog
      :visible.sync="showAddTeacherDialog"
      :title="
        isEdit == false
          ? $t('admin_dashboard_type.add_teacher')
          : $t('admin_dashboard_type.edit_teacher')
      "
      transition="dialog-bottom-transition"
      width="70%"
      top="4vh"
      class="text-right"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <!-- Switch Tabs  -->
      <div class="tabs_heading mb-3">
        <button
          :class="{ is_active: activeTabTeacher == 1 }"
          class="our_buttons"
          @click="whichTabIsActive(1)"
        >
          {{
            isEdit == false
              ? $t("admin_dashboard_type.add_new_teacher")
              : $t("admin_dashboard_type.edit_teacher")
          }}
        </button>
        <button
          :class="{ is_active: activeTabTeacher == 2 }"
          class="our_buttons"
          @click="whichTabIsActive(2)"
          v-if="isEdit == false"
        >
          {{ $t("admin_dashboard_type.add_more_teacher") }}
        </button>
        <button
          :class="{ is_active: activeTabTeacher == 3 }"
          class="our_buttons"
          @click="whichTabIsActive(3)"
          v-if="isEdit == true"
        >
          {{ $t("admin_dashboard_type.change_password") }}
        </button>
        <button
          :class="{ is_active: activeTabTeacher == 4 }"
          class="our_buttons"
          @click="whichTabIsActive(4)"
          v-if="isEdit == false"
        >
          {{ $t("admin_dashboard_type.download_teacher_template") }}
        </button>
      </div>
      <!-- Start Add One Teacher  -->
      <div v-if="activeTabTeacher == 1">
        <el-form
          :model="teacherData"
          :rules="isEdit == false ? addTeacherRules : addTeacherRulesWhenEdit"
          ref="addTeacherForm"
          class="demo-ruleForm"
          label-position="top"
        >
          <div class="row">
            <div class="col-md-6">
              <el-form-item
                :label="$t('admin_dashboard_type.teacher_name')"
                prop="name"
                :rules="[
                  {
                    required: true,
                    message: `${this.$t('field_required')}`,
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input
                  :label="$t('admin_dashboard_type.teacher_name')"
                  v-model="teacherData.name"
                  class="w-100"
                ></el-input>
              </el-form-item>
            </div>
            <div class="col-md-6">
              <el-form-item
                :label="$t('admin_dashboard_type.email')"
                prop="email"
                :rules="[
                  {
                    required: true,
                    type: 'email',
                    message: `${this.$t('correct_email_required')}`,
                    trigger: ['blur', 'change'],
                  },
                ]"
              >
                <el-input
                  :label="$t('admin_dashboard_type.email')"
                  v-model="teacherData.email"
                  class="w-100"
                ></el-input>
              </el-form-item>
            </div>
            <div v-if="isEdit == false" class="col-md-6">
              <el-form-item
                :label="$t('admin_dashboard_type.password')"
                prop="password"
                :rules="[
                  {
                    required: true,
                    message: `${this.$t('field_required')}`,
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input
                  :label="$t('admin_dashboard_type.password')"
                  v-model="teacherData.password"
                  class="w-100"
                ></el-input>
              </el-form-item>
            </div>

            <div class="col-md-6">
              <el-form-item
                :label="$t('admin_dashboard_type.specialization')"
                prop="specialization"
                :rules="[
                  {
                    required: true,
                    message: `${this.$t('field_required')}`,
                    trigger: 'blur',
                  },
                ]"
              >
                <multiselect
                  :placeholder="$t('admin_dashboard_type.specialization')"
                  :searchable="true"
                  :show-labels="true"
                  :close-on-select="true"
                  v-model="teacherData.specialization"
                  :multiple="false"
                  :options="specializations"
                  label="label"
                  track-by="val"
                ></multiselect>
              </el-form-item>
            </div>
            <div class="col-md-6">
              <el-form-item
                :label="$t('admin_dashboard_type.gender')"
                prop="gender"
                :rules="[
                  {
                    required: true,
                    message: `${this.$t('field_required')}`,
                    trigger: 'blur',
                  },
                ]"
              >
                <el-radio-group v-model="teacherData.gender">
                  <el-radio :label="1" @click="teacherData.gender = 1">
                    {{ $t("admin_dashboard_type.male") }}</el-radio
                  >
                  <el-radio :label="2" @click="teacherData.gender = 2">
                    {{ $t("admin_dashboard_type.female") }}</el-radio
                  >
                </el-radio-group>
              </el-form-item>
            </div>
          </div>
        </el-form>
        <div class="creative_app_footer_dialog">
          <el-button
            @click="handlerFunction"
            class="when_submit_form save_from"
          >
            {{ $t("admin_dashboard_type.save") }}</el-button
          >
          <el-button
            class="when_submit_form cancel_from"
            @click="handlerCloseFunction"
          >
            {{ $t("cancel") }}
          </el-button>
        </div>
      </div>
      <!-- End Add One Teacher  -->
      <!-- Start Add More Teachers  -->
      <div v-if="activeTabTeacher == 2 && isEdit == false">
        <div class="upload_excel_wrapper">
          <el-upload
            class="el-upload_btn"
            :show-file-list="false"
            accept=".xlsx"
            :on-success="successImport"
            :action="import_src"
            :on-error="handleErrorUpload"
            :headers="{
              Authorization: 'Bearer ' + testTst,
            }"
          >
            <el-icon class="el-icon-upload"></el-icon>

            {{ $t("admin_dashboard_type.add_excel_file") }}
          </el-upload>
        </div>
        <div class="creative_app_footer_dialog">
          <el-button class="when_submit_form save_from">
            {{ $t("admin_dashboard_type.save") }}</el-button
          >
          <el-button
            class="when_submit_form cancel_from"
            @click="closeAddDialog"
          >
            {{ $t("cancel") }}
          </el-button>
        </div>
      </div>
      <!-- End Add More Teachers  -->
      <!-- Start Change Student password  -->
      <div v-if="activeTabTeacher == 3">
        <el-form
          :model="passData"
          ref="editedPasswordRef"
          :rules="editedPasswordRules"
          label-position="top"
        >
          <div class="row">
            <div class="col-md-6">
              <el-form-item
                :label="$t('admin_dashboard_type.password')"
                prop="password"
                :rules="[
                  {
                    required: true,
                    message: `${this.$t('field_required')}`,
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input
                  v-model="passData.password"
                  style="width: 100%"
                  :placeholder="$t('admin_dashboard_type.password')"
                ></el-input>
              </el-form-item>
            </div>
          </div>
        </el-form>
        <div class="creative_app_footer_dialog">
          <el-button @click="savePassword" class="when_submit_form save_from">
            {{ $t("admin_dashboard_type.save") }}</el-button
          >
          <el-button
            class="when_submit_form cancel_from"
            @click="handlerCloseFunction"
          >
            {{ $t("cancel") }}
          </el-button>
        </div>
      </div>
      <!-- Start Change Student password  -->
      <!-- Start Export Template  -->
      <div v-if="activeTabTeacher == 4 && isEdit == false">
        <div class="download_template_wrapper">
          <el-button @click="exportTemplate" class="download_template">
            {{ $t("admin_dashboard_type.download") }}</el-button
          >
        </div>
        <div class="creative_app_footer_dialog">
          <el-button class="when_submit_form save_from">
            {{ $t("admin_dashboard_type.save") }}</el-button
          >
          <el-button
            class="when_submit_form cancel_from"
            @click="closeAddDialog"
          >
            {{ $t("cancel") }}
          </el-button>
        </div>
      </div>
      <!-- End Export Template -->
    </el-dialog>
    <!-- End Add Teacher -->
  </div>
</template>

<script>
import { getToken } from "../../utils/auth";
import ServerTable from "@/components/DataTablePagination.vue";
import Swal from "sweetalert2";
export default {
  components: { ServerTable },
  data() {
    return {
      activeTabTeacher: 1,
      showAddTeacherDialog: false,
      teacherData: {
        name: "",
        email: "",
        password: "",
        gender: "",
        specialization: "",
        confirm_password: "",
      },
      addTeacherRules: {
        name: [
          {
            required: true,
            message: `${this.$t("field_required")}`,
            trigger: ["blur", "change"],
          },
        ],
        // 'يجب ادخال البريد الالكتروني بشكل صحيح'
        email: [
          {
            required: true,
            message: `${this.$t("correct_email_required")}`,
            trigger: ["blur", "change"],
          },
        ],
        password: [
          {
            required: true,
            message: `${this.$t("field_required")}`,
            trigger: "blur",
          },
        ],
        gender: [
          {
            required: true,
            message: `${this.$t("field_required")}`,
            trigger: "blur",
          },
        ],
        profession: [
          {
            required: true,
            message: `${this.$t("field_required")}`,
            trigger: "blur",
          },
        ],
        specialization: [
          {
            required: true,
            message: `${this.$t("field_required")}`,
            trigger: "blur",
          },
        ],
      },
      addTeacherRulesWhenEdit: {
        name: [
          {
            required: true,
            message: `${this.$t("field_required")}`,
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: `${this.$t("correct_email_required")}`,
            trigger: "blur",
          },
        ],

        gender: [
          {
            required: true,
            message: `${this.$t("field_required")}`,
            trigger: "blur",
          },
        ],
        profession: [
          {
            required: true,
            message: `${this.$t("field_required")}`,
            trigger: "blur",
          },
        ],
        specialization: [
          {
            required: true,
            message: `${this.$t("field_required")}`,
            trigger: "blur",
          },
        ],
      },
      specializations: [
        {
          val: "0",
          label: `${this.$t("Art Specialization")}`,
          // label: "Art",
        },
        {
          val: "1",
          label: `${this.$t("Computer Specialization")}`,
          // "Computer"
        },
        {
          val: "2",
          label: `${this.$t("Psychology Specialization")}`,
          // label: "Psychology",
        },
        {
          val: "3",
          label: `${this.$t("Skills development Specialization")}`,
          // label: "Skills development",
        },
        {
          val: "4",
          label: `${this.$t("Other Specialization")}`,
          // label: "Math",
        },
        {
          val: "5",
          label: `${this.$t("Math Specialization")}`,
          // label: "Other",
        },
      ],
      isEdit: false,
      passData: {
        password: null,
        confirm_password: null,
      },
      editedPasswordRules: {
        password: [
          {
            required: true,
            message: `${this.$t("field_required")}`,
            trigger: "blur",
          },
        ],
        confirm_password: [
          {
            required: true,
            message: `${this.$t("field_required")}`,
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    this.Refresh();
  },
  computed: {
    testTst() {
      return getToken();
    },
    loadingValue() {
      return this.$store.getters["schools/load"];
    },
    schoolTeachers() {
      return this.$store.getters["schools/schoolTeachers"];
    },
    schoolTeachersCount() {
      return this.$store.getters["schools/schoolTeachersCount"];
    },
    offset() {
      if (this.$route.query.currentPage && this.$route.query.perPage) {
        return (this.$route.query.currentPage - 1) * this.$route.query.perPage;
      } else {
        return 0;
      }
    },

    limit() {
      if (this.$route.query.perPage) {
        return this.$route.query.perPage - 1;
      } else {
        return 4;
      }
    },
    handlerFunction() {
      return this.isEdit == false ? this.addTeacher : this.editTeacher;
    },
    handlerCloseFunction() {
      return this.isEdit == false ? this.closeAddDialog : this.closeEditDialog;
    },
    import_src() {
      return `${process.env.VUE_APP_BASE_URL}schools/users/import/teachers`;
    },
  },
  methods: {
    Refresh(query = { offset: this.offset, limit: this.limit }) {
      this.$store.dispatch("schools/getListOfSchoolTeachers", { query: query });
    },
    openEditDialog(item) {
      this.showAddTeacherDialog = true;
      this.isEdit = true;
      this.teacherData = item;
      if (item.specialization != null) {
        this.teacherData.specialization = {
          val: item.specialization,
          label: this.whichSpecialization(item.specialization),
        };
      }
    },
    closeAddDialog() {
      this.showAddTeacherDialog = false;
      this.activeTabTeacher = 1;
    },
    closeEditDialog() {
      let beforeUpdateTeacherData = {
        name: "",
        email: "",
        password: "",
        gender: "",
        specialization: "",
        confirm_password: "",
      };

      this.showAddTeacherDialog = false;
      this.isEdit = false;
      this.activeTabTeacher = 1;
      this.teacherData = beforeUpdateTeacherData;
      this.Refresh();
    },
    openAddDialog() {
      this.activeTabTeacher = 1;
      let beforeUpdateTeacherData = {
        name: "",
        email: "",
        password: "",
        gender: "",
        specialization: "",
        confirm_password: "",
      };
      this.teacherData = beforeUpdateTeacherData;
      this.showAddTeacherDialog = true;
    },

    whichTabIsActive(num) {
      this.activeTabTeacher = num;
    },
    addTeacher() {
      this.$refs["addTeacherForm"].validate((valid) => {
        if (valid) {
          this.teacherData.confirm_password = this.teacherData.password;
          this.teacherData.specialization = this.teacherData.specialization.val;
          this.$store
            .dispatch("schools/handlerAddTeacher", this.teacherData)
            .then(() => {
              this.showAddTeacherDialog = false;
              this.Refresh();
              Swal.fire({
                text: `${this.$t("success_added")}`,
                icon: "success",
                showCancelButton: false,
                confirmButtonClass: "btn btn-success btn-fill",
                confirmButtonText: `${this.$t("confirm")}`,
                buttonsStyling: false,
              });
              this.teacherData.name = null;
              this.teacherData.email = null;
              this.teacherData.password = null;
              this.teacherData.confirm_password = null;
              this.teacherData.gender = null;
              this.teacherData.specialization = null;
            });
        } else {
          return false;
        }
      });
    },
    whichSpecialization(pcValue) {
      console.log(pcValue);
      if (pcValue != null) {
        let specialize = this.specializations.filter((item) => {
          if (item.val == pcValue) {
            return item;
          }
        });

        return specialize[0].label;
      } else {
        return "__";
      }
    },
    removeTeacher(id) {
      Swal.fire({
        text: `${this.$t("you_want_delete")}`,
        icon: "error",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: `${this.$t("confirm")}`,
        cancelButtonText: `${this.$t("cancel")}`,
        buttonsStyling: false,
      }).then((date) => {
        if (date.isConfirmed) {
          this.$store.dispatch("schools/deleteSchoolTeacher", id).then((_) => {
            this.Refresh();
            Swal.fire({
              text: `${this.$t("success_deleted")}`,
              icon: "success",
              confirmButtonClass: "btn btn-success btn-fill",
              buttonsStyling: false,
            });
          });
        }
      });
    },
    editTeacher() {
      this.$refs["addTeacherForm"].validate((valid) => {
        if (valid) {
          let afterUpdateTeacherData = {
            name: "",
            email: "",
            password: "",
            gender: "",
            specialization: "",
            confirm_password: "",
          };
          this.teacherData.specialization = this.teacherData.specialization.val;
          this.$store
            .dispatch("user/save", {
              query: this.teacherData,
            })
            .then(() => {
              this.showAddTeacherDialog = false;
              this.isEdit = false;
              this.teacherData = afterUpdateTeacherData;
              this.Refresh();
              Swal.fire({
                text: `${this.$t("success_edited")}`,
                icon: "success",
                showCancelButton: false,
                confirmButtonClass: "btn btn-success btn-fill",
                confirmButtonText: `${this.$t("confirm")}`,
                buttonsStyling: false,
              });
            });
        }
      });
    },
    savePassword() {
      this.$refs["editedPasswordRef"].validate((valid) => {
        if (valid) {
          this.passData.confirm_password = this.passData.password;

          this.$store
            .dispatch("user/handler_update_student_password", {
              id: this.teacherData.id,
              query: this.passData,
            })
            .then(() => {
              this.showAddTeacherDialog = false;
              this.isEdit = false;
              this.activeTabTeacher = 1;
              (this.passData.password = null),
                (this.passData.confirm_password = null),
                Swal.fire({
                  text: `${this.$t("success_edited")}`,
                  icon: "success",
                  confirmButtonClass: "btn btn-success btn-fill",
                  confirmButtonText: `${this.$t("confirm")}`,
                  buttonsStyling: false,
                });
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    exportTemplate() {
      this.$store.dispatch("schools/handlerExportTeachersTemplate").then(() => {
        this.showAddTeacherDialog = false;
        Swal.fire({
          text: `${this.$t("success_downloaded")}`,
          icon: "success",
          showCancelButton: false,
          confirmButtonClass: "btn btn-success btn-fill",
          confirmButtonText: `${this.$t("ok")}`,
          buttonsStyling: false,
        });
        this.activeTabTeacher = 1;
      });
    },
    handleErrorUpload(error, file, fileList) {
      const errorMessage = JSON.parse(error.message)["error"];
      Swal.fire({
        text: `${errorMessage}`,
        type: "error",
        confirmButtonClass: "btn btn-success",
        buttonsStyling: false,
        icon: "error",
      });
    },
    successImport() {
      this.showAddTeacherDialog = false;
      this.Refresh();
      Swal.fire({
        text: `${this.$t("success_added")}`,
        icon: "success",
        confirmButtonText: `${this.$t("confirm")}`,
        confirmButtonClass: "btn btn-success btn-fill",
        buttonsStyling: false,
      });
      this.activeTabTeacher = 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.download_template_wrapper {
  display: flex;
  justify-content: center;
  .download_template {
    background-color: #87cb16;
    padding: 20px;
    border-radius: 9px;
    transition: 0.3s;
    font-size: 17px;
    min-width: 150px;
    color: #fff;
    &:hover {
      opacity: 0.8;
    }
  }
}
.every_table_action_button {
  padding: 10px 0;
  .our_btn {
    background-color: #409eff;
    color: #fff;
    padding: 8px 16px;
    border-radius: 5px;
    transition: 0.5;
    border: 1px solid #409eff;
    &:hover {
      opacity: 0.8;
    }
  }
}
::v-deep .cell {
  text-align: center;
  word-break: break-word;
}
.school_teachers_page_wrapper {
  .icons_table_wrapper {
    display: flex;
    gap: 8px;
    justify-content: center;
    padding: 5px 0;
    .icon {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 13px;
      cursor: pointer;
      &.edit {
        background-color: #409eff;
        color: #fff;
      }
      &.remove {
        background-color: #fb404b;
        color: #fff;
      }
    }
  }
}
::v-deep .el-form--label-top .el-form-item__label {
  width: 100%;
  text-align: right;
}
::v-deep .el-form-item__error {
  right: 0;
  left: unset;
}
::v-deep .el-form-item {
  text-align: right;
}
.creative_app_footer_dialog {
  display: flex;
  margin-top: 15px;
  justify-content: end;
  padding: 10px;
  gap: 10px;
  .when_submit_form {
    min-width: 150px;
    text-align: center;
    transition: 0.3s;
    &.save_from {
      border: 1px solid #409eff;
      background-color: #409eff;
      color: #fff;
    }
    &.cancel_from {
      border: 1px solid #fb404b;
      background-color: #fb404b;
      color: #fff;
    }
    &:hover {
      opacity: 0.7;
    }
  }
}
.tabs_heading {
  display: flex;
  gap: 15px;
  padding: 10px 0;
  border-bottom: 3px solid #e4e4e4;
  .our_buttons {
    border: none;
    background: none;
    color: #777;
    position: relative;
    &::before {
      position: absolute;
      right: 0;
      content: "";
      background: #409eff;
      width: 100%;
      height: 3px;
      bottom: -12px;
      display: none;
    }
    &.is_active {
      &::before {
        display: block;
      }
    }
  }
}
.upload_excel_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0 20px;
}
::v-deep .el-upload {
  background-color: #87cb16;
  padding: 20px;
  border-radius: 9px;
  transition: 0.3s;
  font-size: 17px;
  color: #fff;
  &:hover {
    opacity: 0.8;
  }
}
</style>

<style lang="scss">
.school_teachers_page_wrapper {
  .el-dialog__header {
    text-align: initial !important;
    text-transform: capitalize;
  }
  .el-form-item__label {
    text-transform: capitalize;
    width: 100%;
    text-align: initial !important;
  }
  .el-form-item__error {
    width: 100%;
    text-align: initial;
  }
  .el-form-item__content {
    text-align: initial;
    text-transform: capitalize;
  }
}
</style>
