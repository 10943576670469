<template>
  <div class="container">
    <!-- Start If Student Not Complet  -->
    <div v-if="this.false_exam == true">
      <div class="card py-5 text-center">
        <p class="">الطالب لم يكمل هذا الاختبار بعد</p>
        <div>
          <router-link :to="`/exams/`" style="width: 100%">
            <el-button class="btn btn-primary">
              العودة الى الاختبارات
            </el-button>
          </router-link>
        </div>
      </div>
    </div>

    <div class="col" v-if="this.false_exam == false">
      <el-card v-loading="loading">
        <div class="container">
          <div class="text-right">
            <div class="py-4">
              <el-steps
                direction="horizontal"
                :active="active"
                finish-status="success"
              >
                <el-step
                  :title="exam"
                  v-for="(exam, index) in countarr"
                  :key="index"
                ></el-step>
              </el-steps>
            </div>

            <div class="card p-md-5">
              <!-- // Start First Exam  -->
              <el-form v-if="active === 0" :model="payload" ref="firstExam">
                <div class="image_upload d-flex mb-5">
                  <div
                    class="d-flex justify-content-between w-100 image-wrapper"
                  >
                    <!-- img heere -->

                    <img :src="image(firstExam.img)" class="w-50" />
                  </div>
                </div>

                <hr />
                <h5 class="font-weight-bold">
                  {{ firstExam.header }}
                </h5>

                <hr />
                <!-- :checked="answer1.includes(option)" -->
                <div class="question my-5">
                  <el-checkbox-group v-model="answer1">
                    <div class="row">
                      <div
                        class="col-md-4 check_box_wrapper"
                        id="check_id"
                        v-for="(option, index) in firstExam.options"
                        :key="index"
                      >
                        <!-- <p>{{answer1.includes(option)}}</p> -->
                        <!-- <input

                        type="radio"
                        id="radios"
                        :name="option"
                        :value="option"
                        autocomplete="off"
                        :checked="true ? answer1.includes(option) : false"
                      />
                      <label for="radios">{{ option }}</label> -->

                        <el-checkbox
                          class="check_box"
                          :label="option"
                          :id="`${index}`"
                          autocomplete="off"
                          :name="option"
                          :value="option"
                        ></el-checkbox>
                      </div>
                    </div>
                  </el-checkbox-group>
                  <hr />

                  <p>
                    أكتب هنا أي أشكال أخرى قام الطفل برسمها ولم تجدها في
                    المجموعات السابقة مع وضع (-) بين الكلمات
                  </p>

                  <div
                    v-for="(answer, index) in external_answers"
                    :key="index"
                    class="d-flex justify-content-between"
                  >
                    <div>
                      <el-input
                        v-model="external_answers[index]"
                        class="mb-3 mx-1"
                      />
                    </div>

                    <div>
                      <el-select
                        v-model="value2[index]"
                        @change="
                          firstExamScores(
                            value2[index],
                            external_answers[index]
                          )
                        "
                      >
                        <el-option
                          v-for="item in options2"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        />
                      </el-select>
                    </div>

                    <div v-for="(ans, index) in count1" :key="index">
                      <p
                        class="px-1"
                        v-if="
                          ans.answer == external_answers[index] &&
                          !firstExam.options.includes(external_answers[index])
                        "
                      >
                        <i class="fa fa-angle-left"></i>
                        عدد الاجابات:
                        {{ ans.count - 1 }} / {{ totalCount }} =
                        {{ (((ans.count - 1) * 100) / totalCount).toFixed(2) }}%
                      </p>
                    </div>
                  </div>
                </div>
                <div class="submit mt-5">
                  <el-button
                    type="primary"
                    style="margin-top: 12px"
                    @click="firstExamFun(2, id)"
                    :class="{
                      'is-disabled': value2.length < value.length,
                    }"
                    :disabled="
                      value2.length < value.length || handleDisabledBtnOne
                    "
                  >
                    <span v-if="!handleDisabledBtnOne">الاختبار التالي</span>
                    <span v-if="handleDisabledBtnOne">انتظر...</span>
                  </el-button>
                </div>
              </el-form>
              <!-- // End First Exam  -->

              <!-- //// Start Second Exam  -->
              <el-form v-if="active === 1" :model="payload" ref="secondExam">
                <div class="image_upload d-flex mb-5">
                  <div
                    class="d-flex justify-content-between w-100 image-wrapper"
                  >
                    <img :src="image(secondExam[0].img)" class="w-50" />
                  </div>
                </div>
                <div class="question my-5">
                  <div
                    class="card p-4 shadow"
                    v-for="(examData, index) in secondExam"
                    :key="index"
                  >
                    <div class="image mb-4 text-center">
                      <img
                        :src="
                          require('../../../public/static/img/graph_' +
                            (index + 1) +
                            '.jpg')
                        "
                        alt=""
                      />
                    </div>

                    <h5 class="font-weight-bold">{{ examData.header }}</h5>

                    <hr />

                    <el-radio-group v-model="payload.answer">
                      <div class="row">
                        <div
                          class="col-md-4 d-flex align-items-center"
                          v-for="(option, index) in examData.options"
                          :key="index"
                        >
                          <input
                            type="radio"
                            :id="index"
                            :name="examData.question_id"
                            :value="option"
                            :checked="examData.answer == option"
                            @change="
                              secondExamScores(
                                examData.question_id,
                                option,
                                index
                              )
                            "
                          />

                          <label class="mr-1" :for="option">{{ option }}</label>
                        </div>
                      </div>
                    </el-radio-group>

                    <div class="d-flex justify-content-around pt-4">
                      <div v-if="!examData.options.includes(othr[index])">
                        <el-input
                          placeholder="Your answer"
                          type="text"
                          v-model="othr[index]"
                        >
                          size="medium" ></el-input
                        >
                      </div>

                      <div v-if="examData.options.includes(othr[index])">
                        <el-input
                          placeholder="Your answer"
                          type="text"
                          v-model="othr_option[index]"
                        >
                          size="medium" ></el-input
                        >
                      </div>

                      <div>
                        <el-select
                          v-model="value2[index]"
                          @change="
                            printother(
                              othr[index],
                              othr_option[index],
                              examData.question_id,
                              value2[index]
                            )
                          "
                        >
                          <el-option
                            v-for="item in options2"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          />
                        </el-select>
                      </div>

                      <div>
                        <div class="" v-for="ans in count2">
                          <p
                            v-if="
                              ans.answer == othr[index] &&
                              ans.question_id == examData.question_id
                            "
                          >
                            <i class="fa fa-angle-left"></i>
                            عدد الاجابات<br />
                            المماثلة : {{ ans.count - 1 }} / {{ totalCount }} =

                            {{
                              (((ans.count - 1) * 100) / totalCount).toFixed(2)
                            }}%
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="submit d-flex justify-content-between">
                  <el-button
                    type="primary"
                    style="margin-top: 12px"
                    @click="secondExamFun()"
                    :class="{
                      'is-disabled': result.length < external_answers2.length,
                    }"
                    :disabled="
                      result.length < external_answers2.length ||
                      handleDisabledBtnTwo ||
                      value2[index]
                    "
                  >
                    <span v-if="!handleDisabledBtnTwo">الاختبار التالي</span>
                    <span v-if="handleDisabledBtnTwo">انتظر...</span>
                  </el-button>
                  <el-button @click="prev()">{{ $t("prev") }} </el-button>
                </div>
              </el-form>
              <!-- //// End Second Exam  -->

              <!-- //// Start Third Exam  -->
              <el-form
                v-if="active === 2"
                :model="payload"
                ref="thirdExam"
                class="container"
              >
                <div class="image_upload d-flex mb-5 p">
                  <div
                    class="d-flex justify-content-between w-100 image-wrapper"
                  >
                    <div>
                      <img :src="image(thirdExam[0].img)" class="w-50" />
                    </div>
                  </div>
                </div>
                <hr />
                <div class="question">
                  <div class="question my-5">
                    <div class="card p-4 shadow">
                      <h5 class="font-weight-bold">
                        {{ thirdExam[0].header }}
                      </h5>
                      <hr />

                      <el-radio-group v-model="payload.answer">
                        <div class="row">
                          <div
                            class="col-md-4"
                            v-for="(item, index) in thirdExam[0].options"
                            :key="index"
                          >
                            <input
                              type="radio"
                              :id="index"
                              :name="thirdExam[0].question_id"
                              :value="item"
                              :checked="thirdExam[0].answer == item"
                              @change="printid(item, thirdExam[0].question_id)"
                            />
                            <label class="mr-1" :for="item">{{ item }}</label>
                          </div>
                        </div>
                      </el-radio-group>
                      <hr />

                      <div class="row">
                        <div
                          class="col-md-10"
                          v-if="!thirdExam[0].options.includes(thirdExamOne)"
                        >
                          <p>اخرى</p>
                          <el-input
                            class="w-50"
                            placeholder="Your answer"
                            v-model="thirdExamOne"
                            size="medium"
                          ></el-input>
                        </div>

                        <div
                          class="col-md-10"
                          v-if="thirdExam[0].options.includes(thirdExamOne)"
                        >
                          <p>اخرى</p>
                          <el-input
                            class="w-50"
                            placeholder="Your answer"
                            v-model="othr_option2"
                            size="medium"
                          ></el-input>
                        </div>
                      </div>
                      <hr />
                      <div class="d-flex justify-content-between flex-wrap">
                        <div class="">
                          <el-select
                            v-model="value3"
                            @change="
                              printthirdExamTwo(
                                thirdExamOne,
                                thirdExam[0].question_id,
                                value3,
                                othr_option2
                              )
                            "
                          >
                            <el-option
                              v-for="item in options2"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            />
                          </el-select>
                        </div>
                        <div
                          class=""
                          v-for="(ans, index) in count3"
                          :key="index"
                        >
                          <p
                            v-if="
                              ans.answer == thirdExamOne &&
                              ans.question_id == thirdExam[0].question_id
                            "
                          >
                            <i class="fa fa-angle-left"></i>
                            عدد الاجابات المماثلة : {{ ans.count - 1 }} /
                            {{ totalCount }} =

                            {{
                              (((ans.count - 1) * 100) / totalCount).toFixed(2)
                            }}%
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="card p-4 shadow">
                      <h5 class="font-weight-bold">
                        {{ thirdExam[1].header }}
                      </h5>

                      <!--
/////answer////// -->
                      <hr />
                      <div class="row">
                        <div class="col-md-6">
                          <el-input
                            placeholder="Your answer"
                            v-model="thirdExamTwo"
                            size="medium"
                          ></el-input>
                        </div>
                      </div>

                      <hr />
                      <div
                        class="d-flex w-100 justify-content-between flex-wrap"
                      >
                        <div class="">
                          <el-select
                            class=""
                            v-model="value4"
                            @change="
                              printthirdExamTwo(
                                thirdExamTwo,
                                thirdExam[1].question_id,
                                value4
                              )
                            "
                          >
                            <el-option
                              v-for="item in options4"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            />
                          </el-select>
                        </div>
                        <div
                          class=""
                          v-for="(ans, index) in count3"
                          :key="index"
                        >
                          <p
                            v-if="
                              ans.answer == thirdExamTwo &&
                              ans.question_id == thirdExam[1].question_id
                            "
                          >
                            <i class="fa fa-angle-left"></i>
                            عدد الاجابات المماثلة: {{ ans.count - 1 }} /
                            {{ totalCount }} =
                            {{
                              (((ans.count - 1) * 100) / totalCount).toFixed(2)
                            }}%
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="submit d-flex justify-content-between">
                  <el-button
                    :class="{
                      'is-disabled': third <= 0,
                    }"
                    :disabled="handleDisabledBtnThree || third <= 0"
                    type="primary"
                    style="margin-top: 12px"
                    @click="thirdExamFun"
                  >
                    <span v-if="!handleDisabledBtnThree">{{ $t("send") }}</span>
                    <span v-if="handleDisabledBtnThree">انتظر...</span>
                  </el-button>

                  <el-button @click="prev()">{{ $t("prev") }} </el-button>
                </div>
              </el-form>
              <!-- //// End Third Exam  -->

              <!-- Start Final Section For Exam Result -->
              <el-form
                v-if="active === 3"
                :model="payload"
                ref="thirdExam"
                class="w-100 mx-5 px-5"
              >
                <table class="table">
                  <thead class="">
                    <tr>
                      <th scope="col">النتيجة</th>
                      <th scope="col">طلاقة الطالب</th>
                      <th scope="col">اصالة الطالب</th>
                      <th scope="col">مرونة الطالب</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>المجموع الكلى</td>
                      <td>{{ studentFluency }} / 26</td>
                      <td>{{ studentScore }} / 135</td>
                      <td>{{ category_count }} / 16</td>
                    </tr>
                  </tbody>
                </table>

                <div class="px-3 d-flex justify-content-between">
                  <router-link to="/overview" style="width: 100%">
                    <el-button
                      @click="confirmScore(id, exam_type, 1)"
                      class="mx-3 btn btn-primary"
                      :disabled="score_disable == true"
                    >
                      اعتماد النتيجة
                    </el-button>
                  </router-link>
                  <el-button class="mx-3 btn btn-secondary" @click="prev()"
                    >{{ $t("prev") }}
                  </el-button>
                </div>
              </el-form>
              <!-- End Final Section For Exam Result -->
            </div>
          </div>
        </div></el-card
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "exam",
  data() {
    return {
      selectvalue: "",
      newdata: [],
      options2: [
        {
          value: "0",
          label: "0",
        },
        {
          value: "1",
          label: "1",
        },
        {
          value: "2",
          label: "2",
        },
        {
          value: "3",
          label: "3",
        },
        {
          value: "4",
          label: "4",
        },
        {
          value: "5",
          label: "5",
        },
      ],
      options4: [
        {
          value: "0",
          label: "لا يوجد عنوان",
        },
        {
          value: "1",
          label: "عنوان لا يعبر عن الرسمة",
        },
        {
          value: "2",
          label: "عنوان وصفي",
        },
        {
          value: "3",
          label: "عنوان وصفي خيالي",
        },
        {
          value: "4",
          label: "عنوان يحكي قصة غريبة",
        },
      ],
      value2: [],
      value3: null,
      value4: null,

      count1: [],
      count2: [],
      count3: [],
      studentScore: null,
      studentFluency: null,
      options3: [],
      third: [],
      value: [],
      external_answers: [],
      external_answers2: [],

      score: null,
      data: localStorage.getItem("data"),
      data2: {},
      disabled: true,
      //option:null,
      thirdExamOne: null,
      thirdExamTwo: null,
      test: "عصفور",
      answers: {},
      other: null,
      answer1: null,
      firstExam: {},
      thirdExam: [],
      secondExam: [],
      scores: "",
      thirdExamOne: null,
      thirdExamTwo: null,
      othr: [],
      othr_option: [],
      othr_option2: null,

      exam_type: this.$route.params.exam_type,
      result: [],
      fluency: null,
      item: null,
      totalCount: null,
      imgs: null,
      score_disable: false,
      id: this.$route.params.id,
      options: [],
      new_answer_2: [],
      saveimg: null,
      saveimg2: null,
      studentData: {},
      secondExam: {},
      false_exam: false,
      active: 0,
      category_count: null,
      countarr: ["الاختبار 1", "الاختبار 2", "الاختبار 3", "النتيجة"],
      payload: {
        answer: [],
        exam_type: this.$route.params.exam_type,
        student_id: this.$route.params.id,
        course_id: 1,
        question_id: null,
        group_id: 1,
      },
      handleDisabledBtnOne: false,
      handleDisabledBtnTwo: false,
      handleDisabledBtnThree: false,
      loading: false,
    };
  },
  mounted() {
    //  this.$store
    //     .dispatch("groups/studentscore", this.$route.params.exam_type)
    //     .then(res => {
    //       let resss = res;

    //       for (let i = 0; i < resss.score.length; i++) {
    //         if (resss.score[i].student_id == this.$route.params.id) {
    //           this.studentScore = resss.score[i].total;
    //          this.studentFluency = resss.fluency[i].score;
    //           this.category_count = resss.categories[i].count;
    //       console.log( resss.fluency[i].score)

    //         }
    //       }

    //     });

    this.getData();
  },

  methods: {
    getData() {
      let data = {
        exam_type: this.exam_type,
        group_no: 1,
        id: this.id,
      };
      // console.log(data);
      // console.log(this.data.exam_type);
      // this.data.exam_type = this.exam_type;

      this.$store.dispatch("groups/getAnswers", data).then((response) => {
        // console.log(response);
        // console.log(this.firstExam);

        if (response.length == 0) {
          this.false_exam = true;
        } else {
          this.false_exam = false;
          (this.firstExam = response.firstExam),
            (this.secondExam = response.secondExam),
            (this.thirdExam = response.thirdExam);
        }
        ////////////////////////////////////// FIRST EXAM DATA/////////////////////////////////////

        this.answer1 = this.firstExam.answer;
        this.external_answers = this.firstExam.answer.filter(
          (val) => !this.firstExam.options.includes(val)
        );

        /////////////////////////////////////////SECOND EXAM DATA//////////////////////////////////
        // console.log(this.secondExam);
        if (this.secondExam.length > 0) {
          for (let i = 0; i < this.secondExam.length; i++) {
            if (
              !this.secondExam[i].options.includes(this.secondExam[i].answer)
            ) {
              this.external_answers2.push(this.secondExam[i].answer);
            }
          }
        }

        // console.log(this.external_answers2);

        let main_answer = [];
        let main_options = [];
        for (let index = 0; index < response.secondExam.length; index++) {
          const element = response.secondExam[index];
          main_answer.push(element.answer);
        }

        this.othr = main_answer;

        /////////////////////////////////////// THIRD EXAM DATA /////////////////////////////////////
        if (this.thirdExam.length > 0) {
          this.thirdExamTwo = this.thirdExam[1].answer;
          for (let i = 0; i < this.thirdExam[0].options.length; i++) {
            if (this.thirdExam[0].options[i] != this.thirdExam[0].answer) {
              this.thirdExamOne = this.thirdExam[0].answer;
            } else {
            }
          }
        }
      });

      //////////////////////////////////////ِANSWERS COUNTED WITH USERS/////////////////////////

      this.$store.dispatch("groups/totalAnswers").then((response) => {
        this.count1 = response.questions1;
        this.count2 = response.questions2;
        this.count3 = response.questions3;
        this.totalCount = response.users;
      });

      this.studentData = {
        student_id: this.$route.params.id,
        course_id: 1,
      };
      this.$store
        .dispatch("groups/getStudentScore", this.studentData)
        .then((response) => {
          this.studentScore = response.total;
          this.studentFluency = response.fluency;
          this.category_count = response.category_count;
        });
    },
    confirmScore(id, exam_type, total) {
      let report = {
        content: `https://justtolearn.com/report/${this.$route.params.id}/${exam_type}`,
        student_id: this.$route.params.id,
      };
      console.log(report);
      this.$store.dispatch("groups/sendReport", report).then((_) => {});

      let studentscore = {
        exam_type: parseInt(exam_type),
        student_id: parseInt(id),
        total: total,
      };
      this.$store.dispatch("groups/confirmScore", studentscore).then((_) => {
        this.score_disable = true;
      });
    },
    image(image) {
      this.saveimg = `${image}`;
      // let appUrl = process.env.VUE_APP_BASE_URL.replace("/api/", "/");

      return `${process.env.VUE_APP_BASE_URL}answers/cover/${image}`;
    }, //scrore , external answer2
    firstExamScores(value2, value1) {
      //sending answers in text fields with score
      if (value2 != null) {
        this.result.push({
          question_id: this.firstExam.question_id,
          exam_type: this.$route.params.exam_type,
          course_id: 1,
          group_id: 79,
          score: parseInt(value2),
          answer: value1,
          img: this.saveimg,
          student_id: this.$route.params.id,
        });
      }
    },

    firstExamFun(group_no, id) {
      //storing all answers to database and switch to second exam
      this.$refs["firstExam"].validate((valid) => {
        if (valid) {
          if (this.answer1.length !== 0) {
            console.log(this.answer1);
            for (let i = 0; i < this.answer1.length; i++) {
              for (let j = 0; j < this.external_answers.length; j++) {
                if (
                  this.answer1[i] !== this.external_answers[j] &&
                  this.firstExam.options.includes(this.answer1[i])
                ) {
                  this.result.push({
                    question_id: this.firstExam.question_id,
                    exam_type: this.$route.params.exam_type,
                    course_id: 1,
                    group_id: 79,
                    score: null,
                    answer: this.answer1[i],
                    student_id: this.$route.params.id,
                    img: this.saveimg,
                  });
                }
              }
            }
          }

          let obj = {
            Exam: this.result,
            student_id: this.$route.params.id,
            question_id: 1,
          };
          this.handleDisabledBtnOne = true;
          this.$store.dispatch("courses/answers2", obj).then((_) => {
            this.result = [];
            this.value2 = [];
            this.handleDisabledBtnOne = false;
            if (this.active++ > 2) return false;
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    secondExamScores(option, examData, index) {
      // console.log("option:" + option + "examdata" + examData + index);
      this.result.push({
        answer: examData,
        group_id: 1,
        exam_type: this.$route.params.exam_type,
        student_id: this.$route.params.id,
        question_id: option,
        img: this.saveimg,
        course_id: 1,
        score: null,
      });
      console.log(this.result);
    },

    printother(other, othr_option, question_id, score) {
      console.log(
        "other",
        other,
        "othroption:",
        othr_option,
        question_id,
        score
      );
      if (othr_option == null || othr_option == "") {
        this.result.push({
          score: parseInt(score),
          answer: other,
          group_id: 1,
          exam_type: this.$route.params.exam_type,
          student_id: this.$route.params.id,
          question_id: question_id,
          img: this.saveimg,
          course_id: 1,
        });
      } else {
        this.result.push({
          score: parseInt(score),
          answer: othr_option,
          group_id: 1,
          exam_type: this.$route.params.exam_type,
          student_id: this.$route.params.id,
          question_id: question_id,
          img: this.saveimg,
          course_id: 1,
        });
      }
      // console.log(this.value2);
      //   console.log(this.new_answer_2);
      console.log(this.result);
    },

    secondExamFun() {
      if (this.result.length > 0) {
        let res = {
          secondExam: this.result,
        };
        this.handleDisabledBtnTwo = true;
        this.$store.dispatch("courses/answers2", res).then((_) => {
          this.value2 = [];
          this.handleDisabledBtnTwo = false;
          if (this.active++ > 2) return false;
        });
      } else {
        this.handleDisabledBtnTwo = false;
        if (this.active++ > 2) return false;
      }
    },

    printid(item, question_id) {
      this.third.push({
        score: null,
        answer: item,
        group_id: this.group_id,
        exam_type: this.$route.params.exam_type,
        student_id: this.$route.params.id,
        question_id: question_id,
        course_id: 1,
        img: this.saveimg,
      });
      console.log(this.third);
    },

    printthirdExamOne(thirdExamOne, question_id, score, othr_option2) {
      if (othr_option2 == null || othr_option2 == "") {
        if (thirdExamOne) {
          this.third.push({
            score: score,
            answer: thirdExamOne,
            group_id: this.group_id,
            exam_type: this.$route.params.exam_type,
            student_id: this.$route.params.id,
            question_id: question_id,
            course_id: 1,
            img: this.saveimg,
          });
          console.log(this.third);
        }
      } else {
        this.third.push({
          score: score,
          answer: othr_option2,
          group_id: this.group_id,
          exam_type: this.$route.params.exam_type,
          student_id: this.$route.params.id,
          question_id: question_id,
          course_id: 1,
          img: this.saveimg,
        });
        console.log(this.third);
      }
    },

    printthirdExamTwo(thirdExamTwo, question_id, score, othr_option2) {
      if (othr_option2 == null || othr_option2 == "") {
        if (thirdExamTwo) {
          this.third.push({
            score: score,
            answer: thirdExamTwo,
            group_id: 1,
            exam_type: this.$route.params.exam_type,
            student_id: this.$route.params.id,
            question_id: question_id,
            course_id: 1,
            img: this.saveimg,
          });
          console.log(this.third);
        }
      } else {
        this.third.push({
          score: score,
          answer: othr_option2,
          group_id: 1,
          exam_type: this.$route.params.exam_type,
          student_id: this.$route.params.id,
          question_id: question_id,
          course_id: 1,
          img: this.saveimg,
        });
        console.log(this.third);
      }
    },

    thirdExamFun() {
      let res = {
        thirdExam: this.third,
      };
      this.handleDisabledBtnThree = true;
      this.loading = true;
      this.$store
        .dispatch("courses/answers2", res)
        .then((_) => {
          // this.handleDisabledBtnThree = true;
          this.$store
            .dispatch("groups/studentscore", this.$route.params.exam_type)
            .then((res) => {
              let resss = res;

              for (let i = 0; i < resss.score.length; i++) {
                if (resss.score[i].student_id == this.$route.params.id) {
                  this.studentScore = resss.score[i].total;
                  this.category_count = resss.categories[i].count;
                }
              }

              // if (this.active++ > 2) return false;
            });
          this.handleDisabledBtnThree = false;
          this.active = 3;
          this.loading = false;
        })
        .finally(() => {
          this.handleDisabledBtnThree = false;
          this.loading = false;
        });
    },
    prev() {
      if (this.active-- < 0) return false;
      this.getData();
    },
  },
};
</script>
<style scoped>
div .el-upload-list--picture-card .el-upload-list__item {
  width: 500px !important;
  height: auto !important;
}

div .el-step__main {
  text-align: right !important;
}

.question .card {
  border-top: #008080 5px solid;
}

div .el-checkbox__label {
  display: inline-block;
  padding-left: 10px;
  line-height: 19px;
  font-size: 12px;
  margin-right: 5px;
}

@media (max-width: 530px) {
  .image img {
    width: 100%;
  }

  .image-wrapper {
    display: flex;
    flex-wrap: wrap !important;
  }
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile + label {
  font-size: 1em;
  font-weight: 700;
  color: white;
  background-color: #008080;
  display: inline-block;
  border-radius: 5px;
  padding: 0px 20px;
}

.inputfile:focus + label,
.inputfile + label:hover {
  background-color: #409eff;
}

.inputfile + label {
  cursor: pointer;
  /* "hand" cursor */
}

input[type="file"] {
  display: none;
}
.custom-file-upload {
  cursor: pointer;

  background-color: #008080;
}
.custom-file-upload:hover {
  background-color: #03b1b1;
}
.multii {
  padding: 10px;
  margin: 10px;
  color: black !important;
}
::v-deep .el-step__main {
  white-space: normal;
  text-align: right !important;
}
.set-score {
  background-color: #2d8180;
  padding-right: 12px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: 1px solid gray;
  cursor: pointer;
  color: white;
}
</style>
