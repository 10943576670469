<template>
  <div class="col class_details_wrapper" v-loading="loadingValue">
    <card>
      <div class="all_parts">
        <div class="parts">
          <span class="part_a d-block">
            {{ $t("admin_dashboard_type.class_name") }}
          </span>
          <span class="part_b d-block">{{ classDetails.name }}</span>
        </div>
        <div class="parts">
          <span class="part_a d-block">
            {{ $t("admin_dashboard_type.level") }}
          </span>
          <span class="part_b d-block"
            >{{ $t("admin_dashboard_type.grade") }}
            {{ classDetails.grade }}</span
          >
        </div>
        <div class="parts">
          <span class="part_a d-block">
            {{ $t("admin_dashboard_type.teacher_name") }}
          </span>
          <span class="part_b d-block">{{ classDetails.teacher_name }}</span>
        </div>
        <div class="parts">
          <span class="part_a d-block">{{
            $t("admin_dashboard_type.email_for_teacher")
          }}</span>
          <span class="part_b d-block">{{ classDetails.email }}</span>
        </div>
        <div class="parts">
          <span class="part_a d-block">
            {{ $t("admin_dashboard_type.phone_for_teacher") }}
          </span>
          <span class="part_b d-block">{{ classDetails.mobile_no }}</span>
        </div>
        <div class="parts">
          <span class="part_a d-block">{{
            $t("admin_dashboard_type.city")
          }}</span>
          <span class="part_b d-block">{{ classDetails.city }}</span>
        </div>
        <div class="parts">
          <span class="part_a d-block">{{
            $t("admin_dashboard_type.days")
          }}</span>
          <span class="part_b days">
            <span class="day" v-for="(day, i) in classDetails.days" :key="i">{{
              day
            }}</span>
          </span>
        </div>
        <div class="parts">
          <span class="part_a d-block">
            {{ $t("admin_dashboard_type.count_of_students") }}</span
          >
          <span class="part_b d-block">{{ classDetails.students_count }}</span>
        </div>
        <div class="parts">
          <span class="part_a d-block">
            {{ $t("admin_dashboard_type.count_of_activities") }}</span
          >
          <span class="part_b d-block">{{ classDetails.activities }}</span>
        </div>
        <div class="parts">
          <span class="part_a d-block">{{
            $t("admin_dashboard_type.avg_completed_lessons")
          }}</span>
          <span class="part_b d-block">{{
            classDetails.avg_completed_lessons
          }}</span>
        </div>
        <div class="parts">
          <span class="part_a d-block">{{
            $t("admin_dashboard_type.activities_lesson_average")
          }}</span>
          <span class="part_b d-block">{{
            classDetails.avg_completed_activities
          }}</span>
        </div>
        <div class="parts">
          <i
            v-if="classDetails.pending_activities != 0"
            class="fa fa-exclamation icon not_active"
          ></i>
          <i
            v-if="classDetails.pending_activities == 0"
            class="fa fa-check icon active"
          ></i>
          <span class="part_a d-block">{{
            $t("admin_dashboard_type.pendding_activities")
          }}</span>
          <span class="part_b d-block">{{
            classDetails.pending_activities
          }}</span>
        </div>
        <div class="parts">
          <i
            v-if="classDetails.in_active_student != 0"
            class="fa fa-exclamation icon not_active"
          ></i>
          <i
            v-if="classDetails.in_active_student == 0"
            class="fa fa-check icon active"
          ></i>
          <span class="part_a d-block">{{
            $t("admin_dashboard_type.stu_not_active")
          }}</span>
          <span class="part_b d-block">{{
            classDetails.in_active_student
          }}</span>
        </div>
        <div class="parts">
          <span class="part_a d-block"
            >{{ $t("admin_dashboard_type.challenge_one") }} (
            {{ $t("admin_dashboard_type.pre") }} )</span
          >
          <span class="part_b d-block">
            {{ classDetails.count_of_pre_exam_one_students }} /
            {{ classDetails.students_count }}</span
          >
        </div>
        <div class="parts">
          <span class="part_a d-block"
            >{{ $t("admin_dashboard_type.challenge_two") }} (
            {{ $t("admin_dashboard_type.pre") }} )</span
          >
          <span class="part_b d-block">
            {{ classDetails.count_of_pre_exam_two_students }} /
            {{ classDetails.students_count }}</span
          >
        </div>
        <div class="parts">
          <span class="part_a d-block"
            >{{ $t("admin_dashboard_type.challenge_one") }} (
            {{ $t("admin_dashboard_type.post") }} )</span
          >
          <span class="part_b d-block">
            {{ classDetails.count_of_last_exam_one_students }} /
            {{ classDetails.students_count }}</span
          >
        </div>
        <div class="parts">
          <span class="part_a d-block"
            >{{ $t("admin_dashboard_type.challenge_two") }} (
            {{ $t("admin_dashboard_type.post") }} )</span
          >
          <span class="part_b d-block">
            {{ classDetails.count_of_last_exam_two_students }} /
            {{ classDetails.students_count }}</span
          >
        </div>
      </div>
    </card>
    <div @click="printPage" class="sharing_wrapper">
      <span class="">{{ $t("admin_dashboard_type.print_report") }}</span>
      <i class="fa fa-share-alt print_now"></i>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  mounted() {
    this.$store.dispatch("schools/getClassDetails", this.$route.params.id);
  },
  computed: {
    ...mapGetters({
      loadingValue: ["schools/load"],
      classDetails: ["schools/classDetails"],
    }),
  },
  methods: {
    printPage() {
      window.print();
    },
  },
};
</script>

<style lang="scss" scoped>
.sharing_wrapper {
  width: fit-content;
  cursor: pointer;
  color: #fff;
  margin: auto;
  display: flex;
  background: #409eff;
  padding: 8px 10px;
  border-radius: 8px;
  gap: 5px;
  transition: 0.3s;
  &:hover {
    opacity: 0.8;
  }
  .print_now {
    font-size: 22px;
  }
}
@media print {
  * {
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
    color-adjust: exact !important; /*Firefox*/
  }
}
.print_now {
  width: 30px;
}
@media print {
  .sharing_wrapper {
    display: none;
  }
}
.icon {
  color: #fff;
  border-radius: 50%;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  font-size: 14px;
  padding: 4px;
  margin: 0 5px;
  &.not_active {
    background-color: #fb404b;
  }
  &.active {
    background-color: #67c23a;
  }
}
.class_details_wrapper {
  .all_parts {
    .parts {
      text-align: right;
      display: flex;
      padding: 15px 8px;
      font-size: 17px;
      .part_a {
        min-width: 270px;
      }
      .part_b {
        // background-color: #fff;
        // padding: 8px;
        &.days {
          display: flex;
          gap: 10px;
          .day {
            background-color: #67c23a;
            color: #fff;
            padding: 4px 16px;
            border-radius: 10px;
          }
        }
      }
    }
    .parts:nth-child(odd) {
      background-color: #eee;
    }
    .parts:nth-child(even) {
      background-color: #fff;
    }
  }
}
</style>
