<template>
  <div class="intro">
    <!-- Navigation bar -->

    <nav class="navbar navbar-light bg-white">
      <a class="navbar-brand" href="#">
        <img
          src="justtolearn-logo.png"
          width="60"
          height="auto"
          class="d-inline-block align-top"
          alt=""
        />
      </a>
      <form class="form-inline d-flex justify-content-around" style="gap: 10px">
        <!-- <el-button
          @click="$router.push('exam-page')"
          type="info"
          class="px-4 py-2 m-auto d-block h5 login-button"
        >
          اختبار تورنس
        </el-button> -->
        <!-- <el-button
          type="info"
          class="px-4 mx-2 py-2 m-auto d-block h5 login-button"
        > -->
        <a
          class="text-white link_fo_google_and_apple"
          href="https://apps.apple.com/eg/app/neomi/id6445951500"
        >
          <img class="apps_img" src="@/assets/images/store-apple.png" alt="" />
        </a>
        <a
          class="text-white link_fo_google_and_apple"
          href="https://play.google.com/store/apps/details?id=com.vero.neomiAR&hl=en_in"
        >
          <img class="apps_img" src="@/assets/images/store-google.png" alt="" />
        </a>
        <!-- </el-button> -->
        <!-- <el-button
          type="info"
          class="px-4 mx-2 py-2 m-auto d-block h5 login-button"
        >
          <a
            class="text-white"
            href="https://apps.apple.com/eg/app/neomi/id6445951500"
            >Download on the app store</a
          >
        </el-button> -->

        <!-- <el-button
          type="info"
          class="px-4 mx-2 py-2 m-auto d-block h5 login-button"
        >
          <a
            class="text-white"
            href="https://play.google.com/store/apps/details?id=com.vero.neomiAR&hl=en_in"
            >Get it on Google Play</a
          >
        </el-button> -->
        <el-button
          type="info"
          class="px-4 mx-2 py-2 m-auto d-block h5 login-button"
        >
          <a class="text-white" href="https://justtolearn.com/api/public/neomi"
            >NEOMI Web</a
          >
        </el-button>

        <el-button
          type="info"
          class="px-4 mx-2 py-2 m-auto d-block h5 login-button"
        >
          <router-link class="text-white" to="/order-neomi-kit"
            >Order NEOMI KIT</router-link
          >
        </el-button>
        <el-button
          @click="$router.push('login')"
          type="info"
          class="px-4 mx-2 py-2 m-auto d-block h5 login-button"
        >
          Login
        </el-button>

        <div class="mx-2"></div>
      </form>
    </nav>

    <div class="block banner" style="background-image: url('header.jpg')"></div>

    <!-- introduction text -->
    <div class="section my-5" style="background: white">
      <div class="container py-5">
        <div class="row text-right avatar">
          <div class="col-6 pt-5">
            <h2 class="text-dark mt-5"><strong> من نحن؟ </strong></h2>
            <p class="text-muted h5 m-0">
              مرحبا بكم في دورات تنمية مهارات التفكير الإبداعي أكاديمية مرحبا
              بكم في دورات تنمية مهارات التفكير الإبداعي أكاديمية مرحبا بكم في
              دورات تنمية مهارات التفكير الإبداعي أكاديمية
            </p>
          </div>
          <div class="col-6">
            <img alt="" class="img-fluid" src="vue-logo.png" />
          </div>
        </div>
      </div>
    </div>

    <!-- description text -->
    <section>
      <div class="container py-5">
        <div class="row align-items-center">
          <div class="col-md-6">
            <div class="overflow-hidden mb-2">
              <h2 class="text-right text-dark">
                <strong> شرح توضيحي </strong>
              </h2>
            </div>
            <p class="text-right text-muted pt-5">
              <i
                class="fa fa-angle-left fw-bold"
                style="
                  color: rgb(193 136 163);
                  font-size: 21px;
                  font-weight: bold;
                "
              ></i>
              تُعد Just to Learn أول منصة عربية تعليمية تفاعلية تهتم بتنمية
              مهارات التفكير الإبداعي ومهارات التفكير العليا للأطفال (التفكير
              الابتكاري وحل المشكلات والتفكير الناقد) من خلال استراتيجيات تعتمد
              على الفنون البصرية والبرمجة واللغات، وتقدم محتوى وأنشطة إلكترونية
              فريدة مبنية على بحث علمي تجريبي، تدار من خلال تقنيات حديثة كالذكاء
              الاصطناعي لتقييم الأطفال، وتقدم ملاحظات وتوصيات فاعلة لولي الأمر
              حول أداء طفله.
            </p>
            <p class="text-right text-muted">
              <i
                class="fa fa-angle-left fw-bold"
                style="
                  color: rgb(193 136 163);
                  font-size: 21px;
                  font-weight: bold;
                "
              ></i>

              هل الإبداع مهم؟ تشير الأبحاث إلا أن الإبداع من أهم المهارات التي
              يجب أن يكتسبها الأطفال حيث أثبتت الدراسات الحديثة وجود علاقة قوية
              بين تنمية الإبداع وتنمية مستوى الذكاء والتحصيل الدراسي.
            </p>
            <p class="text-right text-muted">
              <i
                class="fa fa-angle-left fw-bold"
                style="
                  color: rgb(193 136 163);
                  font-size: 21px;
                  font-weight: bold;
                "
              ></i>

              على جانب آخر، تشير الأبحاث إلى أن الأطفال في سن مبكر يتمتعون
              بقدرات عالية من الإبداع والتخيل، ومع مرور الوقت والتحاق الأطفال
              (بالتعليم النظامي) تهبط نسب الإبداع تدريجيا، في دراسة طويلة الأمد
              لوكالة ناسا أجراها العالم George Band تم اختبار قدرة 16000 طفل على
              مهارات التفكير الإبداعي وكانت النتائج كالتالي (نسبة الأطفال
              المبدعين من سن 4 إلى 5 سنوات 98%، تم اختبار الأطفال مرة أخرة في
              عمر 10 سنوات وصلت نسبة الأطفال المبدعين إلى 30%، ثم أعيد الأختبار
              في عمر 15 عام لتصل النسبة إلى 12%، وأخير تم اختبار مليون شخص في
              عمر 31 عام وكان عدد من يتحلى بمهارات الإبداع 2% فقط!
            </p>
          </div>
          <div class="col-md-6 text-right mb-5">
            <p class="text-muted">
              <i
                class="fa fa-angle-left fw-bold"
                style="
                  color: rgb(193 136 163);
                  font-size: 21px;
                  font-weight: bold;
                "
              ></i>

              كما أكدت منظمة التنمية والتعاون الاقتصادي OECD على أن قدرة الدول
              على قياس الإبداع ستسهم في تغيير سياسات التعليم على مستوى العالم،
              بينما أكدت اليونيسف UNICEF التابعة للأمم المتحدة على أن أكثر من
              نصف الأطفال في المنطقة العربية لا يتلقون الحد الأدنى من المهارات
              الأساسية.
            </p>
            <p class="text-right text-muted">
              <i
                class="fa fa-angle-left fw-bold"
                style="
                  color: rgb(193 136 163);
                  font-size: 21px;
                  font-weight: bold;
                "
              ></i>

              ما الذي تقدمه المنصة؟ تقدم المنصة مجموعة كبيرة من الأنشطة
              التقليدية والإلكترونية المبتكرة التي ثبت علميًا فعاليتها في تنمية
              مهارات التفكير الإبداعي، كما تتيح منهج كامل لتنمية الإبداع قائم
              على بحث علمي تجريبي، وتوفر أيضًا نظام مبتكر لتقييم الأطفال، وأخيرا
              تعتبر بيئة تعلم يشترك فيها المعلمين من كافة البلدان العربية لتقديم
              ورش عمل وفق معايير محددة وتساعد على تحقيق أعلى قدر من التواصل بين
              الميسر والطفل.
            </p>
            <div></div>
          </div>
        </div>
      </div>
    </section>

    <!-- Introduction video  -->
    <section class="section">
      <div class="container py-5 text-center">
        <div class="row">
          <div class="col-12">
            <iframe
              src="music.mp4"
              allowfullscreen
              width="640"
              height="360"
            ></iframe>
          </div>
        </div>
      </div>
    </section>

    <!-- activities -->
    <section>
      <div class="container py-5">
        <div class="row">
          <div class="col-12">
            <!-- <p >{{$store.getters['courses/gallery'].gallery}}</p> -->
            <el-carousel :interval="4000" type="card" height="400px">
              <!-- <p >{{$store.getters['courses/gallery'].likes}}</p> -->

              <el-carousel-item v-for="item in gallery.gallery" :key="item.id">
                <el-card :body-style="{ padding: '0px' }">
                  <img
                    :src="item.path"
                    class="image"
                    style="max-height: 300px"
                  />
                  <div style="padding: 14px" class="text-right">
                    <span class="title">{{ item.title }}</span>
                    <div class="bottom clearfix float-left">
                      <div
                        class="d-flex justify-content-start align-items-center"
                      >
                        <div v-for="ev in gallery.likes" :key="ev.id">
                          <span
                            class="counter ml-2 mt-1"
                            v-if="ev.evaluation_id == item.id"
                            >{{ ev.likes }}</span
                          >
                        </div>
                        <el-button
                          type="text"
                          class="button like float-left"
                          @click="likes(item.id)"
                        >
                          <i class="fa fa-thumbs-up"></i
                        ></el-button>
                      </div>
                    </div>
                  </div>
                </el-card>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
    </section>

    <footer id="footer" class="pb-4">
      <div class="container">
        <div class="row py-5 my-4">
          <div class="col-md-6 mb-4">
            <div class="contact-details">
              <h3 class="text-right mb-3">تواصل معنا</h3>
              <ul class="list list-icons list-icons-lg">
                <li class="mb-1">
                  <i class="fa fa-map"></i>
                  <p class="mr-2">
                    86 Abbas Alaqqad St., 4th floor, Nasr City, Cairo, Egypt
                  </p>
                </li>
                <li class="mb-1">
                  <i class="fa fa-whatsapp"></i>
                  <p class="mr-2">
                    <a href="tel:+201222838416">+201222838416</a>
                  </p>
                </li>
                <li class="mb-1">
                  <i class="fa fa-envelope"></i>
                  <p class="mr-2">
                    <a href="mailto:admin@justtolearn.com"
                      >admin@justtolearn.com</a
                    >
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-6 text-right">
            <h3 class="text-3 mb-3">تابعنا</h3>
            <ul class="social-icons">
              <li class="social-icons-facebook mb-1">
                <a
                  href="http://www.facebook.com/"
                  target="_blank"
                  title="Facebook"
                  ><i class="fa fa-facebook-f"></i> facebook</a
                >
              </li>
              <li class="social-icons-twitter mb-1">
                <a
                  href="http://www.twitter.com/"
                  target="_blank"
                  title="Twitter"
                  ><i class="fa fa-twitter"></i> twitter</a
                >
              </li>
              <li class="social-icons-linkedin">
                <a
                  href="http://www.linkedin.com/"
                  target="_blank"
                  title="Linkedin"
                  ><i class="fa fa-linkedin-in"></i
                ></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data1: {},
      carouselImages: ["placeholder.jpg", "placeholder.jpg"],
    };
  },
  mounted() {
    this.$store.dispatch("courses/gallery").then((response) => {
      console.log(response);
    });
  },
  methods: {
    likes(id) {
      this.data1 = {
        id: id,
      };
      this.$store.dispatch("courses/likes", this.data1).then((response) => {
        this.$store.dispatch("courses/gallery");
      });
    },
  },

  computed: {
    gallery() {
      let gallery = this.$store.getters["courses/gallery"].gallery;
      let likes = this.$store.getters["courses/gallery"].likes;

      console.log(gallery);
      console.log(likes);
      return {
        gallery,
        likes,
      };
    },
  },
};
</script>

<style scoped>
.avatar {
  box-sizing: border-box;
  border: 5px white solid;
  border-radius: 13px;
  overflow: hidden;
  box-shadow: 0 5px 15px 0px rgba(226, 203, 220, 0.6);
  transform: translatey(0px);
  animation: float 6s ease-in-out infinite;
}
/* @keyframes float {
  0% {
    box-shadow: 0 5px 15px 0px rgba(226, 203, 220, 0.6);
    transform: translatey(0px);
  }
  50% {
    box-shadow: 4 25px 15px 0px rgba(212, 196, 211, 0.6);
    transform: translatey(-25px);
  }
  100% {
    box-shadow: 0 5px 15px 0px rgba(226, 203, 220, 0.6);
    transform: translatey(0px);
  }
} */

.login-button {
  background: #f1cadd;
  border: none;
}
.banner {
  /* background-image: url("pexels.jpg") no-repeat center; */
  background-size: 100% auto;
  background-repeat: no-repeat;
  height: 600px;
}
.navbar {
  max-height: 100px;
}

.navbar-brand {
  padding: 0px;
  margin: 0px;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
  text-align: center;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.image {
  height: 100%;
  width: 100%;
}

.h5 {
  line-height: 30px;
}

.section {
  background-color: #f7f7f7;
}

iframe {
  border: unset;
}

#footer {
  background-color: #212529;
  color: #f7f7f7;
}

.list li {
  display: flex;
}

ul {
  list-style: none;
}

a {
  color: #ecb0d5;
}
.like {
  font-size: 40px;
}

.counter {
  font-size: 20px;
  color: #4286f4;
}

.title {
  font-size: 22px;
}

@media (max-width: 768px) {
  iframe {
    width: 100% !important;
  }
}
</style>

<style lang="scss" scoped>
.link_fo_google_and_apple {
  .apps_img {
    display: block;
    width: 180px;
    height: 47px;
  }
}
</style>
