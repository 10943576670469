<template>
  <div class="col-lg-12 col-md-12 p-0 px-md-3">
    <user-card @refresh="Refresh"></user-card>
  </div>
</template>

<script>
import UserCard from "../../pages/Dashboard/Pages/UserProfile/UserCard.vue";

export default {
  name: "show",

  components: {
    UserCard,
  },
  data() {
    return {};
  },

  computed: {
    id() {
      return this.$route.params.id
        ? parseInt(this.$route.params.id)
        : this.auth().id;
    },
  },
  mounted() {
    /* comment for developer:
  - this refresh method is used to fetch data of user.
  - We have already 2 servertables firing Refresh on render, but if user
  does not have children nor children activites then, it may not fetch data user
  */
    // get user data
    this.$store.dispatch("user/user", this.id);
  },
  methods: {
    Refresh() {
      // // get user data
      this.$store.dispatch("user/user", this.id);
      // get groups of user
      this.$store.dispatch("groups/getGroups");
    },
  },
};
</script>
<style scoped></style>
