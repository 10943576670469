<template>
  <div class="col wrapper_for_printing">
    <!-- :class="{ reverse_dir: getLocale == 'en' }" -->
    <div id="while_printing_the_report" class="while_printing_the_report">
      <div class="information">
        <div class="part_one">
          <div class="parts">
            <span>{{ $t("admin_dashboard_type.name") }} :</span>
            <span class="when_name">{{ userDataForPrintTwo.name }}</span>
          </div>
          <div class="parts">
            <span>{{ $t("gender") }} :</span>
            <span class="when_name">
              {{
                userDataForPrintTwo.gender == 1
                  ? $t("admin_dashboard_type.male")
                  : $t("admin_dashboard_type.female")
              }}</span
            >
          </div>
          <div class="parts">
            <span>{{ $t("admin_dashboard_type.age") }}:</span>
            <span class="when_name">{{ userDataForPrintTwo.age }}</span>
          </div>
          <div class="parts">
            <span>{{ $t("admin_dashboard_type.class_name") }}:</span>
            <span class="when_name">{{ userDataForPrintTwo.group_name }}</span>
          </div>
          <div class="parts spans">
            <div
              class="is_span"
              v-if="
                $route.params.exam_type == 5 || $route.params.exam_type == 6
              "
            >
              <span v-if="$route.params.exam_type == 5"
                >{{ $t("admin_dashboard_type.pre") }} 1</span
              >
              <span v-if="$route.params.exam_type == 6"
                >{{ $t("admin_dashboard_type.pre") }} 2</span
              >
              <span
                class="border_span"
                :class="{
                  pre:
                    $route.params.exam_type == 5 ||
                    $route.params.exam_type == 6,
                }"
              ></span>
            </div>
            <div
              class="is_span ml-4"
              v-if="
                $route.params.exam_type == 7 || $route.params.exam_type == 8
              "
            >
              <span v-if="$route.params.exam_type == 7"
                >{{ $t("admin_dashboard_type.post") }} 1</span
              >
              <span v-if="$route.params.exam_type == 8"
                >{{ $t("admin_dashboard_type.post") }} 2</span
              >
              <span
                class="border_span"
                :class="{
                  post:
                    $route.params.exam_type == 7 ||
                    $route.params.exam_type == 8,
                }"
              ></span>
            </div>
          </div>
          <div class="parts">
            <span>{{ $t("admin_dashboard_type.Total time") }}:</span>
            <span class="when_name"
              >{{ calculateMinAndSec(userDataForPrintTwo.total_time) }}
            </span>
          </div>
          <div class="parts">
            <span>{{ $t("admin_dashboard_type.Total inputs/time") }}:</span>
            <span class="when_name">{{ userDataForPrintTwo.time_count }}</span>
          </div>
        </div>
      </div>
      <div class="is_old_wrapper">
        <!--
            bind this
          :class="{ with_english_direction: getLocale == 'en' }"
          in this >>> main_images_checks_container
         -->
        <div
          class="main_images_checks_container with_english_direction"
          :class="{ with_english_direction: getLocale == 'en' }"
        >
          <div
            class="wrapper_of_images"
            v-for="(item, index) in printPdfReportData.answers"
            :key="index"
          >
            <div class="main_main">
              <div class="wrapper_two">
                <!-- <div class="loop_here" v-for="(img, i) in item.object" :key="i"> -->
                <img
                  v-for="(img, i) in item.object"
                  :key="i"
                  :style="{ transform: `rotate(${-img.rotation}deg)` }"
                  class="item"
                  :src="img.img_path"
                  alt="draw image"
                  :class="`custom_img_en_class_${i + 1}`"
                />
                <!-- </div> -->
              </div>
              <div class="text_images">
                <span>({{ index + 1 }})</span>
                <span>{{ item.answer }}</span>
              </div>
            </div>
            <div class="is_items_with_checkbox">
              <div class="is_is_checker">
                <span>{{ item.original ? "Original" : "Original" }}</span>
                <el-checkbox v-model="item.original"></el-checkbox>
              </div>
              <div class="is_is_checker">
                <span>{{ item.flex ? "Flex" : "Flex" }}</span>
                <el-checkbox v-model="item.flex"></el-checkbox>
              </div>
              <div class="is_is_checker">
                <span>{{ item.flue ? "Flue" : "Flue" }}</span>
                <el-checkbox v-model="item.flue"></el-checkbox>
              </div>
              <div class="is_is_checker">
                <span>{{ item.details ? "Details" : "Details" }}</span>
                <el-checkbox v-model="item.details"></el-checkbox>
              </div>
              <div class="is_is_checker">
                <span>{{
                  item.imagination ? "Imagination" : "Imagination"
                }}</span>
                <el-checkbox v-model="item.imagination"></el-checkbox>
              </div>
              <div class="is_is_checker">
                <span>{{ item.title ? "Title" : "Title" }}</span>
                <el-checkbox v-model="item.title"></el-checkbox>
              </div>
              <div class="is_is_checker">
                <span>{{
                  item.detailed_title ? "Detailed Title" : "Detailed Title"
                }}</span>
                <el-checkbox v-model="item.detailed_title"></el-checkbox>
              </div>
              <div class="is_is_checker">
                <span>{{ item.meaningful ? "Meaningful" : "Meaningful" }}</span>
                <el-checkbox v-model="item.meaningful"></el-checkbox>
              </div>
              <div class="is_is_checker">
                <span>{{ item.reject ? "Reject" : "Reject" }}</span>
                <el-checkbox v-model="item.reject"></el-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      @click="handlerSubmitAnswers"
      class="sharing_wrapper for_printing_buttons"
      v-if="answersLength > 0"
    >
      <span class="">{{ $t("send") }}</span>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
export default {
  data() {
    return {
      activate: [],
      testData: {},
      answersLength: 0,
    };
  },
  mounted() {
    this.printExamReport();
  },
  computed: {
    printPdfReportData() {
      return this.$store.getters["groups/printPdfReportDataNumberTwo"];
    },
    userDataForPrintTwo() {
      return this.$store.getters["groups/userDataForPrintTwo"];
    },
    getLocale() {
      return this.$i18n.locale;
    },
  },
  methods: {
    printExamReport() {
      let exData = {
        student_id: this.$route.params.student_id,
        exam_type: this.$route.params.exam_type,
        challenge_no: this.$route.params.challenge_no,
      };

      this.$store
        .dispatch("groups/handlerPrintExamReportForMark", exData)
        .then((res) => {
          this.answersLength = res.answers.length;
          // if (this.getLocale == "en") {
          //   let allAnswersData = this.printPdfReportData.answers;
          //   allAnswersData.forEach((element) => {
          //     element.object.reverse();
          //   });
          //   console.log(allAnswersData);
          // }
        });
    },
    handlerSubmitAnswers() {
      let allData = this.printPdfReportData.answers;
      let answersCorrection = [];
      allData.forEach((element) => {
        answersCorrection.push({
          id: element.id,
          original: element.original,
          flex: element.flex,
          flue: element.flue,
          details: element.details,
          imagination: element.imagination,
          title: element.title,
          meaningful: element.meaningful,
          detailed_title: element.detailed_title,
          reject: element.reject,
        });
      });

      this.$store
        .dispatch("groups/handlerSubmitExamReportForMark", {
          answers: answersCorrection,
          user_id: this.$route.params.student_id,
          exam_type: this.$route.params.exam_type,
        })
        .then(() => {
          Swal.fire({
            text: "تم الارسال بنجاح",
            icon: "success",
            showCancelButton: false,
            confirmButtonClass: "btn btn-success btn-fill",
            confirmButtonText: "موافق",
            buttonsStyling: false,
          });
          this.printExamReport();
        });
    },
    calculateMinAndSec(seconds) {
      if (seconds) {
        let minutes = Math.floor(seconds / 60);
        let remainingSeconds = seconds % 60;
        return `${remainingSeconds} : ${minutes}`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin drawImg($left, $top) {
  left: $left;
  top: $top;
  z-index: 99;
}

// test-correct-print-neomi-exam/1670/1/5

.sharing_wrapper {
  width: fit-content;
  cursor: pointer;
  color: #eee;
  display: flex;
  margin: 20px auto 0;
  background: #409eff;
  padding: 8px 10px;
  border-radius: 8px;
  gap: 5px;
  transition: 0.3s;
  &:hover {
    opacity: 0.8;
  }
  .print_now {
    font-size: 22px;
  }
}
.while_printing_the_report {
  background: #fff;
  padding: 20px;
}
// Start Main Style For Images And Checkboxes
.is_old_wrapper {
  .main_images_checks_container {
    width: 800px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    background: #fff;
    margin: 30px auto;
    flex-direction: row-reverse;
    justify-content: center;
    // changes
    //direction: rtl; // will make everything is okay

    // >>>>>>>>>> main container for imgs and checkboxes
    .wrapper_of_images {
      display: flex;
      gap: 15px;
      margin-bottom: 10px;
      // flex-direction: row-reverse;
      // start container for images
      .main_main {
        .wrapper_two {
          width: 200px;
          height: 200px;
          background: #fff;
          margin: auto;
          display: flex;
          flex-wrap: wrap;
          .item {
            display: block;
            width: 40px;
            height: 40px;
          }
          // }
        }
        .text_images {
          display: flex;
          justify-content: center;
          gap: 6px;
        }
      }
      // end container for images
      .is_items_with_checkbox {
        .is_is_checker {
          display: flex;
          justify-content: start;
          gap: 8px;
          flex-direction: row-reverse;
        }
      }
    }

    // Start When English - Direction == Ltr
    &.with_english_direction {
      //direction: rtl; // here the problem
      .main_main {
        .wrapper_two {
          width: 200px;
          background: #ddd;
          height: 200px;
          // margin: auto;
          display: flex;
          flex-wrap: wrap;
          position: relative;
          .item {
            display: block;
            width: 40px;
            height: 40px;
            position: absolute;

            &.custom_img_en_class_1 {
              @include drawImg(0, 0);
            }
            &.custom_img_en_class_2 {
              @include drawImg(40px, 0);
            }
            &.custom_img_en_class_3 {
              @include drawImg(80px, 0);
            }
            &.custom_img_en_class_4 {
              @include drawImg(120px, 0);
            }
            &.custom_img_en_class_5 {
              @include drawImg(160px, 0);
            }
            &.custom_img_en_class_6 {
              @include drawImg(0, 40px);
            }
            &.custom_img_en_class_7 {
              @include drawImg(40px, 40px);
            }
            &.custom_img_en_class_8 {
              @include drawImg(80px, 40px);
            }
            &.custom_img_en_class_9 {
              @include drawImg(120px, 40px);
            }
            &.custom_img_en_class_10 {
              @include drawImg(160px, 40px);
            }
            &.custom_img_en_class_11 {
              @include drawImg(0, 80px);
            }
            &.custom_img_en_class_12 {
              @include drawImg(40px, 80px);
            }
            &.custom_img_en_class_13 {
              @include drawImg(80px, 80px);
            }
            &.custom_img_en_class_14 {
              @include drawImg(120px, 80px);
            }
            &.custom_img_en_class_15 {
              @include drawImg(160px, 80px);
            }
            &.custom_img_en_class_16 {
              @include drawImg(0, 120px);
            }
            &.custom_img_en_class_17 {
              @include drawImg(40px, 120px);
            }
            &.custom_img_en_class_18 {
              @include drawImg(80px, 120px);
            }
            &.custom_img_en_class_19 {
              @include drawImg(120px, 120px);
            }
            &.custom_img_en_class_20 {
              @include drawImg(160px, 120px);
            }
            &.custom_img_en_class_21 {
              @include drawImg(0, 160px);
            }
            &.custom_img_en_class_22 {
              @include drawImg(40px, 160px);
            }
            &.custom_img_en_class_23 {
              @include drawImg(80px, 160px);
            }
            &.custom_img_en_class_24 {
              @include drawImg(120px, 160px);
            }
            &.custom_img_en_class_25 {
              @include drawImg(160px, 160px);
            }
          }
          // }
        }
      }
    }
  }
}
// End Main Style For Images And Checkboxes
.information {
  display: flex;
  background: #fff;
  justify-content: space-between;
  font-weight: bold;
  margin-bottom: 30px;
  .when_name {
    margin: 0 5px;
    color: #555;
  }
  .part_one {
    display: flex;
    flex-wrap: wrap;

    .parts {
      display: flex;
      width: 50%;
      margin-bottom: 15px;
      &.spans {
        // display: flex;
        // gap: 20px;
        .is_span {
          display: flex;
          gap: 5px;
          .border_span {
            width: 25px;
            height: 25px;
            border: 1px solid #000;
            &.pre {
              background: #87cb16;
              border-color: #87cb16;
            }
            &.post {
              background: #87cb16;
              border-color: #87cb16;
            }
          }
        }
      }
    }
  }
}
@media print {
  * {
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
    color-adjust: exact !important; /*Firefox*/
  }

  .while_printing_the_report {
    height: 100%;
    width: 100%;
    page-break-after: always !important;
  }
  .for_printing_buttons {
    display: none;
  }
  .wrapper_for_printing:last-child {
    page-break-after: avoid;
    page-break-inside: avoid;

    margin-bottom: 0px;
  }
  .while_printing_the_report:last-child {
    page-break-after: avoid;
    page-break-inside: avoid;

    margin-bottom: 0px;
  }
}
::v-deep .el-checkbox {
  margin-bottom: 0;
}
</style>
