<template>
  <div
    class="col region_school_dashboard_page_wrapper"
    v-loading="loadingValue"
  >
    <card>
      <div class="some_details">
        <div class="every_part shadow-sm">
          <span class="d-block w-100 title">
            {{ $t("admin_dashboard_type.schools") }}
          </span>
          <span class="d-block w-100">{{
            mainAdminReportCardsData.schools_count
          }}</span>
        </div>
        <div class="every_part shadow-sm">
          <span class="d-block w-100 title">
            {{ $t("admin_dashboard_type.classes") }}
          </span>
          <span class="d-block w-100">{{
            mainAdminReportCardsData.count_of_active_group
          }}</span>
        </div>
        <div class="every_part shadow-sm">
          <span class="d-block w-100 title">
            {{ $t("admin_dashboard_type.count_of_teachers") }}
          </span>
          <span class="d-block w-100">{{
            mainAdminReportCardsData.count_of_active_teachers
          }}</span>
        </div>
        <div class="every_part shadow-sm">
          <span class="d-block w-100 title">
            {{ $t("admin_dashboard_type.count_of_students") }}
          </span>
          <span class="d-block w-100">{{
            mainAdminReportCardsData.count_of_active_students
          }}</span>
        </div>
      </div>
    </card>
    <!-- Start Charts  -->
    <card>
      <div class="row">
        <div class="col-md-6 mb-4 mt-4">
          <span class="d-block chart_title">{{
            $t("admin_dashboard_type.levels")
          }}</span>
          <div class="chart_container">
            <apexchart
              height="350"
              type="pie"
              width="450"
              :options="chartOneForLevels.chartOptions"
              :series="chartOneForLevels.series"
            ></apexchart>
          </div>
        </div>
        <div class="col-md-6 mb-4 mt-4">
          <span class="d-block chart_title">
            {{ $t("admin_dashboard_type.count_of_students") }}</span
          >
          <div class="chart_container">
            <apexchart
              height="350"
              type="pie"
              width="400"
              :options="chartTwoForGenders.chartOptions"
              :series="chartTwoForGenders.series"
            ></apexchart>
          </div>
        </div>
      </div>
    </card>
    <!-- Start Charts  -->

    <!-- Start Details  -->
    <server-table
      :DataItems="mainAdminReport"
      :count="mainAdminReportCount"
      @Refresh="Refresh"
      :useFilter="false"
      :enable_export="false"
      :action="false"
    >
      <template #columns>
        <el-table-column
          prop="school_name"
          :label="$t('admin_dashboard_type.school')"
          min-width="110"
        >
        </el-table-column>
        <el-table-column
          prop="region_name"
          :label="$t('admin_dashboard_type.admin_school')"
          min-width="100"
        >
        </el-table-column>
        <el-table-column
          prop="count_of_teachers"
          :label="$t('admin_dashboard_type.teachers_number')"
        >
        </el-table-column>
        <el-table-column
          prop="students_count"
          :label="$t('admin_dashboard_type.students_number')"
        >
        </el-table-column>

        <el-table-column
          prop="max_users"
          :label="$t('admin_dashboard_type.avg_completed_lessons')"
        >
        </el-table-column>

        <el-table-column
          prop="avg_completed_activities"
          :label="$t('admin_dashboard_type.completed_lessons')"
        >
        </el-table-column>
        <el-table-column
          prop="avg_completed_lessons"
          :label="$t('admin_dashboard_type.max_seats')"
        >
        </el-table-column>
        <el-table-column
          prop="active_groups_count"
          :label="$t('admin_dashboard_type.active_classes')"
        >
        </el-table-column>
        <el-table-column
          prop="all_groups_count"
          :label="$t('admin_dashboard_type.classess_number')"
        >
        </el-table-column>
        <el-table-column
          prop="country"
          :label="$t('admin_dashboard_type.country')"
        >
        </el-table-column>
        <el-table-column prop="city" :label="$t('admin_dashboard_type.city')">
        </el-table-column>
      </template>
    </server-table>
    <!-- End Details  -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ServerTable from "../../components/DataTablePagination.vue";
export default {
  components: { ServerTable },
  mounted() {
    this.Refresh();
  },
  computed: {
    ...mapGetters({
      loadingValue: ["schools/load"],
      mainAdminReport: ["schools/mainAdminReport"],
      mainAdminReportCount: ["schools/mainAdminReportCount"],
      mainAdminReportLevelsChart: ["schools/mainAdminReportLevelsChart"],
      mainAdminReportGendersChart: ["schools/mainAdminReportGendersChart"],
      mainAdminReportCardsData: ["schools/mainAdminReportCardsData"],
    }),
    offset() {
      if (this.$route.query.currentPage && this.$route.query.perPage) {
        return (this.$route.query.currentPage - 1) * this.$route.query.perPage;
      } else {
        return 0;
      }
    },
    limit() {
      if (this.$route.query.perPage) {
        return this.$route.query.perPage - 1;
      } else {
        return 4;
      }
    },
    chartTwoForGenders() {
      let series = [];
      if (this.mainAdminReportGendersChart.length > 0) {
        this.mainAdminReportGendersChart.forEach((element) => {
          series.push(element.user_count);
        });
      }
      return {
        series: series,
        chartOptions: {
          chart: {
            width: 380,
            type: "pie",
          },
          legend: {
            position: "bottom",
          },
          dataLabels: {
            enabled: true,
          },
          labels: ["Male", "Female"],
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        },
      };
    },
    chartOneForLevels() {
      let series = [];
      let labels = [];
      if (this.mainAdminReportLevelsChart.length > 0) {
        this.mainAdminReportLevelsChart.forEach((element) => {
          series.push(element.user_count);
          labels.push(`Grade ${element.grade}`);
        });
      }
      return {
        series: series,
        chartOptions: {
          chart: {
            width: 380,
            type: "pie",
          },
          legend: {
            position: "bottom",
          },
          dataLabels: {
            enabled: true,
          },
          labels: labels,
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        },
      };
    },
  },
  methods: {
    Refresh(query = { offset: this.offset, limit: this.limit }) {
      this.$store.dispatch("schools/handlerMainAdminReport", { query: query });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .cell {
  text-align: center;
  word-break: break-word;
}
.chart_title {
  font-weight: bold;
  color: #555;
  font-size: 18px;
  text-align: center;
  margin-bottom: 15px;
}
.chart_container {
  display: flex;
  justify-content: center;
}
.region_school_dashboard_page_wrapper {
  .some_details {
    display: flex;
    gap: 1%;
    flex-wrap: wrap;
    padding: 15px;
    .every_part {
      width: calc(96% / 4);
      position: relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 10px;
      min-height: 150px;
      transition: 0.5s;
      border-radius: 8px;
      margin-bottom: 25px;
      &:hover {
        background-color: #eee;
      }
      .title {
        font-weight: bold;
        color: #555;
        font-size: 17px;
        text-transform: capitalize;
      }
      .icon {
        position: absolute;
        top: 10px;
        background-color: #67c23a;
        background-color: #fb404b;
        color: #fff;
        border-radius: 50%;
        right: 10px;
        width: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 25px;
        font-size: 14px;
        padding: 4px;
        &.not_active {
          background-color: #fb404b;
        }
        &.active {
          background-color: #67c23a;
        }
      }
    }
  }
}
</style>
