<template>
  <div class="col school_region_page_wrapper" v-loading="loadingValue">
    <div class="every_table_action_button">
      <button class="our_btn" @click="showAddAdminSchoolDialog = true">
        <el-icon class="el-icon-plus"></el-icon>
        {{ $t("admin_dashboard_type.add_school") }}
      </button>
    </div>

    <!-- Start Add Admin School  -->
    <server-table
      :count="schoolsAdminListCount"
      :DataItems="schoolsAdminList"
      :enable_export="false"
      :useFilter="false"
      :action="false"
      @Refresh="Refresh"
    >
      <template #columns>
        <el-table-column
          prop="name"
          :label="$t('admin_dashboard_type.school_name')"
        >
          <template slot-scope="scope">
            <span>
              {{ scope.row.name }}
            </span>
          </template>
        </el-table-column>

        <el-table-column
          prop="school_admin"
          :label="$t('admin_dashboard_type.admin_school')"
        >
          <template slot-scope="scope">
            <span>
              {{ scope.row.school_admin }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="students_count"
          :label="$t('admin_dashboard_type.students_number')"
        >
          <template slot-scope="scope">
            <span>
              {{ scope.row.students_count ? scope.row.students_count : "__" }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="email" :label="$t('admin_dashboard_type.email')">
          <template slot-scope="scope">
            <span>
              {{ scope.row.admin_email ? scope.row.admin_email : "__" }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="country"
          :label="$t('admin_dashboard_type.country')"
        >
          <template slot-scope="scope">
            <span>
              {{ scope.row.country ? scope.row.country : "__" }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="city" :label="$t('admin_dashboard_type.city')">
          <template slot-scope="scope">
            <span>
              {{ scope.row.city ? scope.row.city : "__" }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="_">
          <template slot-scope="scope">
            <div class="icons_table_wrapper">
              <i
                @click="removeSchool(scope.row.id)"
                class="fa fa-trash shadow-sm icon remove"
                v-tooltip.top-center="$t('admin_dashboard_type.delete_school')"
              ></i>
              <i
                @click="openEditDialog(scope.row)"
                class="fa fa-edit shadow-sm icon edit"
                v-tooltip.top-center="$t('admin_dashboard_type.edit_school')"
              ></i>
            </div>
          </template>
        </el-table-column>
      </template>
    </server-table>
    <!-- Start Add Admin School  -->
    <!-- Start Add Admin School  -->
    <el-dialog
      :visible.sync="showAddAdminSchoolDialog"
      :title="
        isEdit == false
          ? $t('admin_dashboard_type.add_school')
          : $t('admin_dashboard_type.edit_school_details')
      "
      transition="dialog-bottom-transition"
      width="70%"
      top="4vh"
      class="text-right"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <!-- Switch Tabs  -->
      <div class="tabs_heading mb-3">
        <button
          :class="{ is_active: activeTabStudent == 1 }"
          class="our_buttons"
          @click="whichTabIsActive(1)"
        >
          {{
            isEdit == false
              ? $t("admin_dashboard_type.add_school")
              : $t("admin_dashboard_type.edit_school_details")
          }}
        </button>
        <button
          :class="{ is_active: activeTabStudent == 2 }"
          class="our_buttons"
          @click="whichTabIsActive(2)"
          v-if="isEdit == true"
        >
          {{ $t("admin_dashboard_type.change_password") }}
        </button>
      </div>
      <!-- add school -->
      <div v-if="activeTabStudent == 1">
        <el-form
          :model="adminSchoolData"
          :rules="addAdminSchoolRules"
          ref="addAdminSchoolForm"
          class="demo-ruleForm"
          label-position="top"
        >
          <div class="row">
            <div class="col-md-6">
              <el-form-item
                :label="$t('admin_dashboard_type.admin_name')"
                prop="name"
              >
                <el-input
                  :label="$t('admin_dashboard_type.admin_name')"
                  v-model="adminSchoolData.name"
                  class="w-100"
                ></el-input>
              </el-form-item>
            </div>
            <div class="col-md-6">
              <el-form-item
                :label="$t('admin_dashboard_type.email')"
                prop="email"
                :rules="[
                  {
                    required: true,
                    type: 'email',
                    message: `${this.$t('correct_email_required')}`,
                    trigger: ['blur', 'change'],
                  },
                ]"
              >
                <el-input
                  :label="$t('admin_dashboard_type.email')"
                  v-model="adminSchoolData.email"
                  class="w-100"
                ></el-input>
              </el-form-item>
            </div>
            <div class="col-md-6" :class="{ 'd-none': isEdit == true }">
              <el-form-item
                :label="$t('admin_dashboard_type.password')"
                prop="password"
              >
                <el-input
                  :label="$t('admin_dashboard_type.password')"
                  v-model="adminSchoolData.password"
                  class="w-100"
                ></el-input>
              </el-form-item>
            </div>
            <div class="col-md-6">
              <el-form-item
                :label="$t('admin_dashboard_type.phone')"
                prop="mobile_no"
              >
                <el-input
                  type="text"
                  :label="$t('admin_dashboard_type.phone')"
                  v-model="adminSchoolData.mobile_no"
                  class="w-100"
                ></el-input>
              </el-form-item>
            </div>
            <div class="col-md-6">
              <el-form-item
                :label="$t('admin_dashboard_type.country')"
                prop="country"
              >
                <el-input
                  :label="$t('admin_dashboard_type.country')"
                  v-model="adminSchoolData.country"
                  class="w-100"
                ></el-input>
              </el-form-item>
            </div>
            <div class="col-md-6">
              <el-form-item
                :label="$t('admin_dashboard_type.city')"
                prop="city"
              >
                <el-input
                  :label="$t('admin_dashboard_type.city')"
                  v-model="adminSchoolData.city"
                  class="w-100"
                ></el-input>
              </el-form-item>
            </div>
            <div class="col-md-6">
              <el-form-item
                :label="$t('admin_dashboard_type.school_name')"
                prop="school_name"
              >
                <el-input
                  :label="$t('admin_dashboard_type.school_name')"
                  v-model="adminSchoolData.school_name"
                  class="w-100"
                ></el-input>
              </el-form-item>
            </div>

            <div class="col-md-6" :class="{ 'd-none': isEdit == true }">
              <el-form-item
                :label="$t('admin_dashboard_type.gender')"
                prop="gender"
              >
                <el-radio-group v-model="adminSchoolData.gender">
                  <el-radio :label="1" @click="adminSchoolData.gender = 1">{{
                    $t("admin_dashboard_type.male")
                  }}</el-radio>
                  <el-radio :label="2" @click="adminSchoolData.gender = 2">{{
                    $t("admin_dashboard_type.female")
                  }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
            <div class="col-md-6" :class="{ 'd-none': isEdit == true }">
              <label>{{ $t("admin_dashboard_type.photo") }}</label>
              <label
                class="custom-file-upload d-block text-white text-center pt-3 px-2"
                style="height: 50px"
              >
                <input
                  type="file"
                  @change="upload_add_course_file"
                  ref="new_course_file"
                  accept="image/*"
                />
                {{ $t("admin_dashboard_type.choose_photo") }}
              </label>
            </div>
          </div>
        </el-form>

        <div class="creative_app_footer_dialog">
          <el-button
            @click="handlerFunction"
            class="when_submit_form save_from"
          >
            {{ $t("admin_dashboard_type.save") }}</el-button
          >
          <el-button
            class="when_submit_form cancel_from"
            @click="handlerCloseFunction"
          >
            {{ $t("cancel") }}
          </el-button>
        </div>
      </div>

      <!-- change password -->
      <div v-if="activeTabStudent == 2">
        <el-form
          :model="passData"
          ref="editedPasswordRef"
          :rules="editedPasswordRules"
          label-position="top"
        >
          <div class="row">
            <div class="col-md-6">
              <el-form-item
                :label="$t('admin_dashboard_type.password')"
                prop="password"
              >
                <el-input
                  v-model="passData.password"
                  style="width: 100%"
                  :placeholder="$t('admin_dashboard_type.password')"
                ></el-input>
              </el-form-item>
            </div>
          </div>
        </el-form>
        <div class="creative_app_footer_dialog">
          <el-button @click="savePassword" class="when_submit_form save_from">
            {{ $t("admin_dashboard_type.save") }}</el-button
          >
          <el-button
            class="when_submit_form cancel_from"
            @click="handlerCloseFunction"
          >
            {{ $t("cancel") }}
          </el-button>
        </div>
      </div>
    </el-dialog>
    <!-- End Add Admin School  -->
  </div>
</template>

<script>
import ServerTable from "@/components/DataTablePagination.vue";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
export default {
  components: { ServerTable },
  data() {
    return {
      activeTabStudent: 1,
      add_course_file_name: null,
      showAddAdminSchoolDialog: false,
      adminSchoolData: {
        name: null,
        email: null,
        country: null,
        city: null,
        password: null,
        confirm_password: null,
        mobile_no: null,
        gender: null,
        school_name: null,
        // image: null,
      },
      addAdminSchoolRules: {
        name: [
          {
            required: true,
            message: `${this.$t("field_required")}`,
            trigger: ["blur", "change"],
          },
        ],
        email: [
          {
            required: true,
            message: `${this.$t("field_required")}`,
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: `${this.$t("field_required")}`,
            trigger: "blur",
          },
        ],
        gender: [
          {
            required: true,
            message: `${this.$t("field_required")}`,
            trigger: "blur",
          },
        ],
        mobile_no: [
          {
            required: true,
            message: `${this.$t("field_required")}`,
            trigger: "blur",
          },
        ],
        country: [
          {
            required: true,
            message: `${this.$t("field_required")}`,
            trigger: "blur",
          },
        ],
        city: [
          {
            required: true,
            message: `${this.$t("field_required")}`,
            trigger: "blur",
          },
        ],
        school_name: [
          {
            required: true,
            message: `${this.$t("field_required")}`,
            trigger: "blur",
          },
        ],
      },
      isEdit: false,
      collectDataWhenEdit: {},
      passData: {
        password: null,
        confirm_password: null,
      },
      editedPasswordRules: {
        password: [
          {
            required: true,
            message: `${this.$t("field_required")}`,
            trigger: "blur",
          },
        ],
        confirm_password: [
          {
            required: true,
            message: `${this.$t("field_required")}`,
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    this.Refresh();
  },
  computed: {
    ...mapGetters({
      loadingValue: ["schools/load"],
      schoolsAdminList: ["schools/schoolsAdminList"],
      schoolsAdminListCount: ["schools/schoolsAdminListCount"],
    }),
    offset() {
      if (this.$route.query.currentPage && this.$route.query.perPage) {
        return (this.$route.query.currentPage - 1) * this.$route.query.perPage;
      } else {
        return 0;
      }
    },
    limit() {
      if (this.$route.query.perPage) {
        return this.$route.query.perPage - 1;
      } else {
        return 4;
      }
    },
    handlerFunction() {
      return this.isEdit == false ? this.addAdminSchool : this.editAdminSchool;
    },
    handlerCloseFunction() {
      return this.isEdit == false ? this.closeAddDialog : this.closeEditDialog;
    },
  },
  methods: {
    Refresh(query = { offset: this.offset, limit: this.limit }) {
      this.$store.dispatch("schools/getListOfSchoolsAdmin", { query: query });
    },
    addAdminSchool() {
      this.$refs["addAdminSchoolForm"].validate((valid) => {
        if (valid) {
          this.adminSchoolData.confirm_password = this.adminSchoolData.password;
          let formData = new FormData();
          for (let course in this.adminSchoolData) {
            formData.append(`${course}`, this.adminSchoolData[course]);
          }
          if (this.$refs["new_course_file"].files[0] != undefined) {
            formData.append("image", this.$refs["new_course_file"].files[0]);
          } else {
            formData.append("image", null);
          }
          this.$store
            .dispatch("schools/handlerAddSchoolAdmin", formData)
            .then(() => {
              this.showAddAdminSchoolDialog = false;
              this.Refresh();
              Swal.fire({
                text: `${this.$t("success_added")}`,
                icon: "success",
                showCancelButton: false,
                confirmButtonClass: "btn btn-success btn-fill",
                confirmButtonText: `${this.$t("confirm")}`,
                buttonsStyling: false,
              });
              this.adminSchoolData.name = null;
              this.adminSchoolData.email = null;
              this.adminSchoolData.password = null;
              this.adminSchoolData.confirm_password = null;
              this.adminSchoolData.country = null;
              this.adminSchoolData.city = null;
              this.adminSchoolData.gender = null;
              this.adminSchoolData.mobile_no = null;
              this.adminSchoolData.image = null;
              this.adminSchoolData.school_name = null;
            });
        } else {
          return false;
        }
      });
    },
    upload_add_course_file() {
      this.add_course_file_name = this.$refs["new_course_file"].files[0].name;
    },
    editAdminSchool() {
      let afterUpdateTeacherData = {
        name: null,
        email: null,
        country: null,
        city: null,
        password: null,
        confirm_password: null,
        mobile_no: null,
        gender: null,
        school_name: null,
      };
      let editData = {
        name: null,
        email: null,
        country: null,
        city: null,
        mobile_no: null,
        school_name: null,
      };

      editData.school_name = this.adminSchoolData.school_name;
      editData.email = this.adminSchoolData.email;
      editData.country = this.adminSchoolData.country;
      editData.city = this.adminSchoolData.city;
      editData.mobile_no = this.adminSchoolData.mobile_no;
      editData.name = this.adminSchoolData.name;

      this.$store
        .dispatch("schools/handlerUpdateRegionAdminSchool", {
          id: this.collectDataWhenEdit.id,
          query: editData,
        })
        .then(() => {
          this.showAddAdminSchoolDialog = false;
          this.isEdit = false;
          this.adminSchoolData = afterUpdateTeacherData;
          this.Refresh();
          Swal.fire({
            text: `${this.$t("success_edited")}`,
            icon: "success",
            showCancelButton: false,
            confirmButtonClass: "btn btn-success btn-fill",
            confirmButtonText: `${this.$t("confirm")}`,
            buttonsStyling: false,
          });
        });
    },
    removeSchool(id) {
      Swal.fire({
        text: `${this.$t("you_want_delete")}`,
        icon: "error",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success ",
        cancelButtonClass: "btn btn-danger ",
        confirmButtonText: `${this.$t("confirm")}`,
        cancelButtonText: `${this.$t("cancel")}`,
        buttonsStyling: false,
      }).then((date) => {
        if (date.isConfirmed) {
          this.$store
            .dispatch("schools/deleteSchoolFromRegionAdmin", id)
            .then((_) => {
              this.Refresh();
              Swal.fire({
                text: `${this.$t("success_deleted")}`,
                icon: "success",
                confirmButtonClass: "btn btn-success ",
                buttonsStyling: false,
              });
            });
        }
      });
    },
    openEditDialog(item) {
      this.showAddAdminSchoolDialog = true;
      this.isEdit = true;
      this.collectDataWhenEdit = item;
      this.adminSchoolData.name = item.school_admin;
      this.adminSchoolData.email = item.admin_email;
      this.adminSchoolData.country = item.country;
      this.adminSchoolData.city = item.city;
      this.adminSchoolData.mobile_no = item.mobile_no;
      this.adminSchoolData.school_name = item.name;
    },
    whichTabIsActive(num) {
      this.activeTabStudent = num;
    },
    closeAddDialog() {
      this.showAddAdminSchoolDialog = false;
    },
    closeEditDialog() {
      let beforeUpdateTeacherData = {
        name: null,
        email: null,
        country: null,
        city: null,
        password: null,
        confirm_password: null,
        mobile_no: null,
        gender: null,
        school_name: null,
      };
      this.collectDataWhenEdit = {};
      this.showAddAdminSchoolDialog = false;
      this.isEdit = false;
      this.adminSchoolData = beforeUpdateTeacherData;
      this.Refresh();
    },
    savePassword() {
      this.$refs["editedPasswordRef"].validate((valid) => {
        if (valid) {
          this.passData.confirm_password = this.passData.password;

          this.$store
            .dispatch("user/handler_update_student_password", {
              id: this.collectDataWhenEdit.admin_id,
              query: this.passData,
            })
            .then(() => {
              this.showAddAdminSchoolDialog = false;
              this.isEdit = false;
              this.activeTabStudent = 1;
              (this.passData.password = null),
                (this.passData.confirm_password = null),
                Swal.fire({
                  text: `${this.$t("success_edited")}`,
                  confirmButtonText: `${this.$t("confirm")}`,
                  icon: "success",
                  confirmButtonClass: "btn btn-success btn-fill",
                  buttonsStyling: false,
                });
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.tabs_heading {
  display: flex;
  gap: 15px;
  padding: 10px 0;
  border-bottom: 3px solid #e4e4e4;
  .our_buttons {
    border: none;
    background: none;
    color: #777;
    position: relative;
    &::before {
      position: absolute;
      right: 0;
      content: "";
      background: #409eff;
      width: 100%;
      height: 3px;
      bottom: -12px;
      display: none;
    }
    &.is_active {
      &::before {
        display: block;
      }
    }
  }
}
.icons_table_wrapper {
  display: flex;
  gap: 8px;
  justify-content: end;
  padding: 5px 0;
  .icon {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    cursor: pointer;
    &.edit {
      background-color: #409eff;
      color: #fff;
    }
    &.remove {
      background-color: #fb404b;
      color: #fff;
    }
  }
}
input[type="file"] {
  display: none;
}
.custom-file-upload {
  min-width: 120px;
  cursor: pointer;
  max-width: 100px;
  background-color: #008080;
  border-radius: 7px;
}
.custom-file-upload:hover {
  background-color: #03b1b1;
}
::v-deep .el-form--label-top .el-form-item__label {
  width: 100%;
  text-align: right;
}
::v-deep .el-form-item__error {
  right: 0;
  left: unset;
}
::v-deep .el-form-item {
  text-align: right;
}
::v-deep .cell {
  text-align: center;
  word-break: break-word;
}
.creative_app_footer_dialog {
  display: flex;
  margin-top: 15px;
  justify-content: end;
  padding: 10px;
  gap: 10px;
  .when_submit_form {
    min-width: 150px;
    text-align: center;
    transition: 0.3s;
    &.save_from {
      border: 1px solid #409eff;
      background-color: #409eff;
      color: #fff;
    }
    &.cancel_from {
      border: 1px solid #fb404b;
      background-color: #fb404b;
      color: #fff;
    }
    &:hover {
      opacity: 0.7;
    }
  }
}
.school_region_page_wrapper {
  .every_table_action_button {
    padding: 10px 0;
    .our_btn {
      background-color: #409eff;
      color: #fff;
      padding: 8px 16px;
      border-radius: 5px;
      transition: 0.5;
      border: 1px solid #409eff;
      &:hover {
        opacity: 0.8;
      }
    }
  }
}
</style>

<style lang="scss">
.school_region_page_wrapper {
  .el-dialog__header {
    text-align: initial !important;
    text-transform: capitalize;
  }
  .el-form-item__label {
    text-transform: capitalize;
    width: 100%;
    text-align: initial !important;
  }
  .el-form-item__error {
    width: 100%;
    text-align: initial;
  }
  .el-form-item__content {
    text-align: initial;
    text-transform: capitalize;
  }
}
</style>
